import baseStyled from 'styled-components';

export const colors = {
  graphite: {
    lightest: '#f4f6fc',
    light: '#d1d8e2',
    main: '#8d96a8',
    dark: '#545964',
    darkest: '#181e2d'
  },
  lightestOrange: '#ffeecc',
  green: '#87da66',
  lightGreen: '#f0fbeb',
  blue: '#277bb9',
  lightBlue: '#e8f2fe',
  orange: '#ffad01',
  lightOrange: '#fef7e5',
  pink: '#e85dd2',
  lightPink: '#fbe9f7',
  weekends2: '#e8f4ff',
  weekends: '#fff2cc',
  isSold: '#d1d8e2',
  background: '#f0f2f5',
  snow: '#fff',
  black: '#000',
  bluePrimary: '#277bb9',
  successPrimary: '#7aa557',
  warningPrimary: '#fabd19',
  warning100: '#fab600',
  errorPrimary: '#db4b47',
  noAvailableTimeSlots: '#ff9966',
  today: '#e8f4ff',
  reservationContainer: '#fafafa',
  palette: [
    '#E4F6D4',
    '#C3EDAB',
    '#A2E482',
    '#81DC59',
    '#60D430',
    '#4AB927',
    '#33971D',
    '#1C7214',
    '#054F0A',
    '#054F0A',
    '#054F0A',
    '#003802'
  ]
};

const typography = {
  xxs: '10px',
  xs: '12px',
  sm: '14px',
  base: '16px',
  lg: '18px',
  xl: '20px',
  xxl: '24px',
  xxxl: '30px',
  xxxxl: '36px'
};

export const theme = {
  main: {
    colors,
    typography
  }
};

export type Theme = typeof theme;
