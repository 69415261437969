import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import { TypeCellProps, TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types';
import StringFilter from '@inovua/reactdatagrid-community/StringFilter';
import dayjs, { Dayjs } from 'dayjs';
import { openNotificationWithIcon } from 'components/Notification/Notification';
import { useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  FloatButton,
  Input,
  Progress,
  Row,
  Select,
  Skeleton,
  Space,
  TimeRangePickerProps,
  Timeline,
  Tooltip
} from 'antd';
import {
  HistoryOutlined,
  RightOutlined,
  LeftOutlined,
  CustomerServiceOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  LoadingOutlined,
  PlusOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  PaperClipOutlined,
  FileTextTwoTone
} from '@ant-design/icons';
import {
  useGetMakeQuery,
  useGetReservationTableQuery,
  useGetReservationsQuery,
  useSaveReservationsMutation
} from '../../../../services/autobookerApi';
import {
  FuelTypeEnum,
  IDay,
  IMake,
  IReservation,
  IReservationRow,
  IVehicle,
  RegistrationPeriodTypeEnum,
  TransmissionTypeEnum,
  VehicleStatusEnum
} from '../../../../Models/Models';
import { theme } from '../../../../styles/theme';
import {
  ContainerRange,
  ElementRange,
  Logo,
  LogoAndTextWrapper,
  Text,
  TextLicensePlate,
  TransmissionTextWrapper
} from './ReservationTable.style';
import {
  DATE_FORMAT,
  DB_DATE_FORMAT,
  LogoRenderer,
  REGISTRATION_WARNING_HIGH,
  REGISTRATION_WARNING_LOW,
  TimelineItemTypeEnum,
  TimelineSummeryItem,
  calculateDaysLeftForRegistrationRenewal,
  getColorForRegistration,
  getFormattedDate,
  getFormattedDateLong,
  groups,
  rangePresets
} from './Tools';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { addNewReservation, setAvailableVehicles, setMakeList } from './reservationsSlice';
import ReservationDay from './ReservationDay/ReservationDay';
import OpenAISupport from './OpenAISupport/OpenAISupport';
import ReservationForm from './ReservationForm/ReservationForm';
import ReservationHoverDetails from './components/ReservationHoverDetails';
import AvailableVehicles from './AvailableVehicles/AvailableVehicles';
import VehicleDetailsHorizontal from './VehicleDetails/VehicleDetailsHorizontal';

const filterValue = [
  { name: 'makeName', operator: 'startsWith', type: 'string', value: '' },
  { name: 'licensePlate', operator: 'contains', type: 'string', value: '' },
  { name: 'vehicleName', operator: 'contains', type: 'string', value: '' },
  { name: 'vehicleTransmission', operator: 'contains', type: 'string', value: '' }
];

type CellSelectionType = { [key: string]: boolean };
type RowPropsType = { rowIndex: number; data: any };
const { RangePicker } = DatePicker;

const ReservationTable = () => {
  const availableVehiclesToday = useAppSelector((state) => state.reservation.availableVehicles);
  const { t } = useTranslation();
  const company = useAppSelector((state) => state.auth.company);
  const dispatch = useAppDispatch();
  const formatDateRange = (startDate: Dayjs | null, endDate: Dayjs | null, filterBySold: VehicleStatusEnum): string => {
    if (!startDate || !endDate) {
      return '';
    }
    const fromDate = startDate.format(DB_DATE_FORMAT);
    const toDate = endDate.format(DB_DATE_FORMAT);
    return `FromDate=${fromDate}&ToDate=${toDate}&filterBySold=${filterBySold}`;
  };
  const [startDate, endDate] = rangePresets[3].value as [Dayjs, Dayjs];
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [filterByVehicleStatus, setFilterByVehicleStatus] = useState(VehicleStatusEnum.Available);
  const [selectedDateQueryParams, setSelectedDateQueryParams] = useState(
    formatDateRange(dateRange[0], dateRange[1], filterByVehicleStatus)
  );
  const [cellSelection, setCellSelection] = useState<CellSelectionType>({});
  const { data: reservationTable, isLoading: isLoadingReservationTable } =
    useGetReservationTableQuery(selectedDateQueryParams);
  const { data: makeList } = useGetMakeQuery(`companyId=${company?.id}`, {
    skip: !company || !company.id === undefined
  });
  const [combinedTable, setCombinedTable] = useState<IReservationRow[]>();
  const [open, setOpen] = useState(false);
  const [openMultiDayReservation, setOpenMultiDayReservation] = useState(false);
  const [reservationHistoryDrawer, setReservationHistoryDrawer] = useState(false);
  const [lastCellSelection, setLastCellSelection] = useState<CellSelectionType>({});
  const [selectedVehicleMake, setSelectedVehicleMake] = useState<IMake>();
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();
  const [daysLeftForRegistrationRenewal, setDaysLeftForRegistrationRenewal] = useState<number>(0);
  const [registrationPeriodInDays, setRegistrationPeriodInDays] = useState<number>(365);
  const [selectedDayReservation, setSelectedDayReservation] = useState<IDay>();
  const [reservationDayProps, setReservationDayProps] = useState<{ vehicle: IVehicle; reservationDay: IDay }>();
  const [multiReservationDayProps, setMultiReservationDayProps] = useState<{
    vehicle: IVehicle;
    reservationDays: IDay[];
  }>();
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [historyPeriod, setHistoryPeriod] = useState({
    fromDate: dayjs().add(-1, 'month').format(DB_DATE_FORMAT),
    toDate: dayjs().format(DB_DATE_FORMAT),
    licensePlate: selectedVehicle?.licensePlate,
    vehicleType: selectedVehicle?.vehicleType
  });
  const { data: reservationHistory, isLoading: isloadingReservationHistory } = useGetReservationsQuery(historyPeriod);
  const { Search } = Input;
  const [timelineData, setTimelineData] = useState<TimelineSummeryItem[]>([]);
  const [isLoadingTimeline, setIsLoadingTimeline] = useState(false);
  const topBarSelectedVehicle = useSelector((state: any) => state.reservation.selectedVehicle);

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      const [startDateT, endDateT] = dates;
      if (startDateT) {
        const maxEndDate = startDateT.clone().add(1, 'month');
        if (endDateT && endDateT.isAfter(maxEndDate)) {
          openNotificationWithIcon('warning', t('common.warning'), t('reservation.maxDateRange'));
          setDateRange([startDateT, startDate.clone().add(1, 'month')]);
          return;
        }
      }
      setDateRange(dates as [Dayjs, Dayjs]);
    } else {
      setDateRange([]);
    }
  };

  useEffect(() => {
    if (!multiReservationDayProps) {
      return;
    }
    setOpenMultiDayReservation(true);
  }, [multiReservationDayProps]);

  useEffect(() => {
    if (!reservationDayProps) {
      return;
    }
    setOpen(true);
  }, [reservationDayProps]);

  useEffect(() => {
    dispatch(setMakeList(makeList));
  }, [makeList]);

  useEffect(() => {
    if (Object.keys(cellSelection).length === 1) {
      setLastCellSelection(cellSelection);
    }
    if (selectedVehicle) {
      const calcResult = calculateDaysLeftForRegistrationRenewal(selectedVehicle);
      setRegistrationPeriodInDays(calcResult.regPeriodInDays);
      setDaysLeftForRegistrationRenewal(calcResult.regPeriodInDays - calcResult.daysLeftForRegistrationRenewal);
    }
  }, [selectedVehicle]);

  useEffect(() => {
    if (!cellSelection) {
      return;
    }
    if (Object.keys(cellSelection).length === 1) {
      const id = Object.keys(cellSelection)[0].split(',')[0];
      const vehicle = reservationTable.find((reservation: IReservationRow) => reservation.vehicle.id === id)?.vehicle;
      setSelectedVehicle(vehicle);
      setSelectedVehicleMake(makeList?.find((make: IMake) => make.id === vehicle?.makeId));
      setSelectedDayReservation(
        reservationTable
          .find((reservation: IReservationRow) => reservation.vehicle.id === id)
          ?.days.find((day: IDay) => day.date === Object.keys(cellSelection)[0].split(',')[1])
      );
    }
  }, [cellSelection]);

  useEffect(() => {
    setSelectedVehicleMake(makeList?.find((make: IMake) => make.id === topBarSelectedVehicle?.makeId));
    setSelectedVehicle(topBarSelectedVehicle);
  }, [topBarSelectedVehicle]);

  const newMultiDayReservation = () => {
    if (Object.keys(cellSelection).length === 0) {
      openNotificationWithIcon('warning', t('common.warning'), t('reservation.selectVehicle'));
      return;
    }
    const vehicleId = Object.keys(cellSelection)[0].split(',')[0];
    const vehicle = reservationTable.find(
      (reservation: IReservationRow) => reservation.vehicle.id === vehicleId
    )?.vehicle;
    if (!vehicle) {
      openNotificationWithIcon('warning', t('common.warning'), t('reservation.selectVehicle'));
      return;
    }
    const reservationDays = reservationTable
      .find((reservation: IReservationRow) => reservation.vehicle.id === vehicleId)
      ?.days.filter((day: IDay) => {
        return Object.keys(cellSelection).some((key: string) => key.split(',')[1] === day.date);
      });
    if (!reservationDays) {
      return;
    }
    setMultiReservationDayProps({ vehicle, reservationDays });
  };

  const getDescriptionForTransmissionType = (transmission: TransmissionTypeEnum) => {
    switch (transmission) {
      case TransmissionTypeEnum.Automatic:
        return t('reservation.automatic');
      case TransmissionTypeEnum.Manual:
        return t('reservation.manual');
      default:
        return '';
    }
  };

  const getDescriptionForFuelType = (fuelType: FuelTypeEnum) => {
    switch (fuelType) {
      case FuelTypeEnum.Diesel:
        return t('reservation.diesel');
      case FuelTypeEnum.Electric:
        return t('reservation.electric');
      case FuelTypeEnum.Petrol:
        return t('reservation.petrol');
      case FuelTypeEnum.Hybrid:
        return t('reservation.hybrid');
      default:
        return '';
    }
  };

  useEffect(() => {
    if (!reservationTable || !makeList) {
      return;
    }
    const makeMap: { [key: string]: IMake } = {};
    makeList.forEach((make: IMake) => {
      makeMap[make.id] = make;
    });
    const combinedTableTemp = reservationTable.map((reservation: IReservationRow) => {
      if (reservation && reservation.vehicle && reservation.vehicle.makeId) {
        const make = makeMap[reservation.vehicle.makeId];
        if (make) {
          return {
            ...reservation,
            makeName: make.name,
            logo: make.logo,
            vehicle: reservation.vehicle,
            licensePlate: reservation.vehicle.licensePlate,
            vehicleColor: reservation.vehicle.color,
            vehicleName: reservation.vehicle.name,
            vehicleModel: reservation.vehicle.modelName,
            vehicleTransmission: getDescriptionForTransmissionType(reservation.vehicle.transmission),
            vehicleFuelType: getDescriptionForFuelType(reservation.vehicle.fuelType)
          };
        }
      }
      return {
        ...reservation,
        vehicle: reservation.vehicle,
        licensePlate: reservation.vehicle.licensePlate,
        vehicleColor: reservation.vehicle.color,
        vehicleName: reservation.vehicle.name,
        vehicleTransmission: getDescriptionForTransmissionType(reservation.vehicle.transmission),
        vehicleFuelType: getDescriptionForFuelType(reservation.vehicle.fuelType)
      };
    });
    setCombinedTable(combinedTableTemp);

    const availableVehicles = combinedTableTemp.filter((reservation: IReservationRow) => {
      return reservation.days.some((day: IDay) => {
        return (
          dayjs(day.date).toDate().toDateString() === dayjs().toDate().toDateString() &&
          day.reservations &&
          day.reservations.length === 0
        );
      });
    });

    dispatch(setAvailableVehicles(availableVehicles.map((day: any) => day.vehicle)));
  }, [reservationTable, makeList]);

  useEffect(() => {
    setSelectedDateQueryParams(formatDateRange(dateRange[0], dateRange[1], filterByVehicleStatus));
  }, [dateRange]);

  useEffect(() => {
    const fetchReservationTable = async () => {
      const queryParams = formatDateRange(dateRange[0], dateRange[1], filterByVehicleStatus);
      setSelectedDateQueryParams(queryParams);
    };

    fetchReservationTable();
  }, [filterByVehicleStatus]);

  const handleRightClick = (rowProps: RowPropsType, selectedColumn: CellSelectionType) => {
    const selectedDays = rowProps.data.days.filter((day: IDay) => {
      return selectedColumn[`${rowProps.data.id},${day.date}`] === true;
    });

    if (selectedDays.length === 0) {
      return;
    }

    if (selectedDays.some((day: IDay) => day.reservations && day.reservations.length > 0)) {
      openNotificationWithIcon('warning', t('common.warning'), t('reservation.alreadyReserved'));
      return;
    }

    setMultiReservationDayProps({
      vehicle: rowProps.data.vehicle,
      reservationDays: selectedDays
    });
  };

  const renderRowContextMenu = (
    menuProps: { autoDismiss: boolean; items: Array<{ label: string; onClick: () => void }> },
    { rowProps }: any
  ) => {
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: `${t('reservation.makeReservation')} ${rowProps.data.makeName} ${rowProps.data.vehicle.name}. 
          ${rowProps.data.vehicle.licensePlate}`,
        onClick: () => handleRightClick(rowProps, rowProps.computedCellSelection)
      }
    ];
  };

  const currentDate = dayjs();
  const getColor = (registeredDate: string | Dayjs) => {
    const parsedDate = dayjs(registeredDate);

    const daysUntilExpiration = parsedDate.diff(currentDate, 'day');

    if (daysUntilExpiration <= 20) {
      return 'red';
    }
    return 'green';
  };

  const getStatus = (registeredDate: string | Dayjs) => {
    const parsedDate = dayjs(registeredDate);

    const daysUntilExpiration = parsedDate.diff(currentDate, 'day');

    if (daysUntilExpiration <= 10) {
      return 'processing';
    }
    return 'default';
  };

  const calculateTotalTime = (reservations: IReservation[]): number => {
    let totalSeconds = 0;
    reservations.forEach((reservation) => {
      if (reservation.startTime && reservation.endTime) {
        const startTime = dayjs(reservation.startTime, 'HH:mm:ss');
        const endTime = dayjs(reservation.endTime, 'HH:mm:ss');
        const durationInSeconds = endTime.diff(startTime, 'second');
        totalSeconds += durationInSeconds;
      }
    });
    const hours = Math.floor(totalSeconds / 3600);
    return hours;
  };

  const getColumns = () => {
    const firstRow = reservationTable[0];
    if (!firstRow) {
      return [];
    }

    const vehicleColumns = [
      {
        name: 'id',
        group: 'vehicleInfo',
        header: 'id',
        defaultVisible: false
      },
      {
        name: 'makeName',
        group: 'vehicleInfo',
        header: (
          <div>
            <div>{t(`reservation.makeName`)}</div>
            <div />
          </div>
        ),
        render: ({ cellProps, data }: any) => {
          if (data.vehicle.isSold) {
            cellProps.style.background = theme.main.colors.isSold;
          }
          return (
            <LogoAndTextWrapper>
              <Badge
                style={{ marginRight: 5 }}
                status={getStatus(data.vehicle.registered)}
                color={getColor(data.vehicle.registered)}
              />
              {/* <Badge status={getStatus()} color={getColor()} /> */}
              <Logo>{data.logo && <LogoRenderer svgString={data.logo} width="20px" height="20px" />}</Logo>
              {/* <Text>{data.makeName}</Text> */}
              <Text>
                <p>{data.vehicleModel}</p>
              </Text>
            </LogoAndTextWrapper>
          );
        }
      },
      {
        name: 'vehicleName',
        group: 'vehicleInfo',
        width: 100,
        filterEditor: StringFilter,
        header: t(`reservation.vehicleName`),
        render: ({ cellProps, data }: any) => {
          if (data.vehicle.isSold) {
            cellProps.style.background = theme.main.colors.isSold;
          }
          return (
            <LogoAndTextWrapper>
              <Text>
                <p>{data.vehicleName}</p>
              </Text>
            </LogoAndTextWrapper>
          );
        }
      },
      {
        name: 'licensePlate',
        group: 'vehicleInfo',
        width: 100,
        filterEditor: StringFilter,
        header: t(`reservation.licensePlate`),
        render: ({ cellProps, data }: any) => {
          if (data.vehicle.isSold) {
            cellProps.style.background = theme.main.colors.isSold;
          }
          return (
            <LogoAndTextWrapper>
              <TextLicensePlate>
                <strong>{data.licensePlate}</strong>
              </TextLicensePlate>
            </LogoAndTextWrapper>
          );
        }
      }
    ];

    const dateColumns = firstRow.days.map((day: IDay) => ({
      key: `${day.date}`,
      name: day.date,
      header: (
        <div>
          <p>{dayjs(day.date).format('ddd')}</p>
          <p>{getFormattedDate(day.date)}</p>
        </div>
      ),
      defaultFlex: 1,
      style: { textAlign: 'center' },
      render: ({ cellProps, data }: any) => {
        const displayDay = data.days.find((d: IDay) => d.date === day.date);
        const displayValueForDate = displayDay?.displayValue || null;
        let totalTime = 0;
        let anyContractNumber = false;
        const contractNumber = displayDay?.reservations
          .filter((reservation: IReservation) => reservation.contractNumber !== undefined)
          .map((reservation: IReservation) => reservation.contractNumber)
          .join(', ');
        const attachmentInfo = displayDay?.reservations
          .filter((reservation: IReservation) => reservation.attachments !== undefined)
          .map((reservation: IReservation) => reservation.attachments?.map((attachment) => attachment.name).join(', '))
          .join(', ');
        let anyAttachment = false;
        anyContractNumber = displayDay?.reservations?.some((reservation: IReservation) => {
          return reservation.contractNumber !== null && reservation.contractNumber !== undefined;
        });
        anyAttachment = displayDay?.reservations?.some((reservation: IReservation) => {
          return reservation.attachments !== undefined && reservation.attachments?.length > 0;
        });
        if (displayDay && displayDay.reservations && displayDay.reservations !== undefined) {
          totalTime = calculateTotalTime(displayDay.reservations);
        }
        if (data.vehicle.isSold) {
          cellProps.style.background = theme.main.colors.isSold;
        }
        if (displayValueForDate === null) {
          const dayFromString = dayjs(day.date);
          if (dayFromString.day() === 6 || dayFromString.day() === 0) {
            cellProps.style.background = theme.main.colors.lightOrange;
          }
          if (dayFromString.format(DATE_FORMAT) === dayjs().format(DATE_FORMAT)) {
            cellProps.style.background = theme.main.colors.today;
          }
          return null;
        }
        if (totalTime >= 8) {
          cellProps.style.background = theme.main.colors.noAvailableTimeSlots;
        } else {
          cellProps.style.background = theme.main.colors.palette[totalTime];
          if (totalTime > 3) {
            cellProps.style.color = theme.main.colors.snow;
          }
        }
        if (
          data.vehicle.bookingColor !== null &&
          data.vehicle.bookingColor !== undefined &&
          data.vehicle.bookingColor !== ''
        ) {
          cellProps.style.background = data.vehicle.bookingColor;
        }
        return (
          <>
            <Tooltip
              color={theme.main.colors.graphite.lightest}
              overlayStyle={{
                fontSize: '11px',
                backgroundColor: '#ffffff',
                border: '1px solid #d9d9d9',
                borderRadius: '8px',
                minWidth: '400px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
              }}
              title={<ReservationHoverDetails day={displayDay} />}
            >
              <div style={{ padding: '0', margin: '0', width: '100%', fontSize: 11 }}>{displayValueForDate.value}</div>
            </Tooltip>
            {anyContractNumber ? (
              <Tooltip title={`${t('documentation.contracts')}: ${contractNumber}`}>
                <FileTextTwoTone style={{ position: 'absolute', top: 1, right: 1 }} />
              </Tooltip>
            ) : (
              ''
            )}
            {anyAttachment ? (
              <Tooltip title={attachmentInfo}>
                <PaperClipOutlined style={{ position: 'absolute', bottom: 1, right: 1 }} />
              </Tooltip>
            ) : (
              ''
            )}
          </>
        );
      }
    }));

    return [...vehicleColumns, ...dateColumns];
  };

  const onClose = async () => {
    setOpen(false);
  };

  const onCloseMultiDayReservation = () => {
    setOpenMultiDayReservation(false);
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    if (event.button === 0) {
      // console.log('cellSelection', cellSelection);
    }
  };

  const handleCellDoubleClick = (event: MouseEvent, cellProps: TypeCellProps) => {
    const vehicleId = cellProps.data.id;
    const date = cellProps.name;

    const vehicle = reservationTable.find(
      (reservation: IReservationRow) => reservation.vehicle.id === vehicleId
    )?.vehicle;
    if (!vehicle) {
      return;
    }
    const reservationDay = reservationTable
      .find((reservation: IReservationRow) => reservation.vehicle.id === vehicleId)
      ?.days.find((day: IDay) => day.date === date);
    if (!reservationDay) {
      return;
    }

    setReservationDayProps({ vehicle, reservationDay });
  };

  const filterReservationsByVehicleAndDate = (
    reservationTableTemp: IReservationRow[],
    selectedVehicleId: string
  ): IDay[] => {
    const dateStrings = Object.keys(cellSelection);

    const filteredReservations = reservationTableTemp.filter((reservation: IReservationRow) => {
      return reservation.vehicle.id === selectedVehicleId;
    });

    const filteredDays = filteredReservations.flatMap((reservation: IReservationRow) => {
      return reservation.days.filter((day: IDay) => {
        return (
          dateStrings.includes(`${selectedVehicleId},${day.date}`) && day.reservations && day.reservations.length > 0
        );
      });
    });

    return filteredDays;
  };

  const onSearch = (value: string) => {
    // getTimelineData(value);
  };

  const showReservationHistoryDrawer = () => {
    if (!selectedVehicle) {
      openNotificationWithIcon('warning', t('common.warning'), t('reservation.selectVehicle'));
      return;
    }
    const newFromDate = dayjs().add(-1, 'month').format(DB_DATE_FORMAT);
    const newToDate = dayjs().format(DB_DATE_FORMAT);
    setHistoryPeriod({
      fromDate: newFromDate,
      toDate: newToDate,
      licensePlate: selectedVehicle?.licensePlate,
      vehicleType: selectedVehicle?.vehicleType
    });
    setReservationHistoryDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    // setReservationHistory([]);
    setReservationHistoryDrawer(false);
  };

  const defaultSortInfo = [
    { name: 'makeName', dir: 1 },
    { name: 'vehicleName', dir: 1 }
  ];

  const toggleSupport = () => {
    setIsSupportOpen(!isSupportOpen);
  };

  const getTextColorForRegistration = (value: number) => {
    return `
      ${value < REGISTRATION_WARNING_HIGH ? t('vehicles.unregisteredVehicle') : ''}
      ${
        value > REGISTRATION_WARNING_HIGH && value < REGISTRATION_WARNING_LOW
          ? t('vehicles.closeToRegistrationRenewal')
          : ''
      }
      ${value >= REGISTRATION_WARNING_LOW ? '' : ''}
    `.trim();
  };

  useEffect(() => {
    if (reservationHistoryDrawer === false || !reservationHistoryDrawer) {
      setTimelineData([]);
    }
  }, [reservationHistoryDrawer]);

  const getTimelineItems = () => {
    reservationHistory?.forEach((day: IDay) => {
      if (day.reservations && day.reservations.length > 0) {
        timelineData.push({
          date: getFormattedDateLong(day.date),
          dot: <CalendarOutlined style={{ fontSize: '18px' }} />,
          type: TimelineItemTypeEnum.DATE
        });
        day.reservations.forEach((reservation: IReservation) => {
          timelineData.push({
            startTime: reservation.allDay ? t('reservation.allDay') : reservation.startTime ?? '',
            endTime: reservation.endTime ?? '',
            description: `${reservation.reservedByDescription}`,
            additionalDescription: reservation.additionalDescription,
            type: TimelineItemTypeEnum.TIME
          });
        });
      }
    });

    return (
      <Timeline
        items={timelineData.map((item: TimelineSummeryItem) => {
          if (item.type === TimelineItemTypeEnum.DATE) {
            return {
              key: uuid(),
              dot: item.dot,
              label: item.date,
              children: null,
              style: { padding: 10, paddingLeft: 20, paddingRight: 20 }
            };
          }
          return {
            key: uuid(),
            label: `${item.startTime} - ${item.endTime}`,
            children: (
              <>
                <p>{item.description}</p>
                <p>{item.additionalDescription}</p>
              </>
            )
          };
        })}
      />
    );
  };

  const [containerHeight, setContainerHeight] = useState('400px');
  useEffect(() => {
    if (combinedTable && combinedTable.length) {
      const newHeight = `${Math.min(combinedTable.length * 25 + 150, window.innerHeight)}px`;
      setContainerHeight(newHeight);
    }
  }, [combinedTable]);

  const gridStyle = { height: containerHeight, minHeight: '100%' };

  return (
    <Row gutter={0} style={{ height: '100%' }}>
      <Col span={24} style={{ height: '100%' }}>
        <Row gutter={0}>
          <Col span={14}>
            <Card bordered style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}>
              <AvailableVehicles vehicles={availableVehiclesToday || []} />
            </Card>
          </Col>
          <Col span={10}>
            <Badge.Ribbon
              style={{ marginTop: -10, marginRight: 20 }}
              text={getTextColorForRegistration(daysLeftForRegistrationRenewal)}
              color={getColorForRegistration(daysLeftForRegistrationRenewal)}
            >
              <Card bordered style={{ marginTop: 20, marginRight: 20 }}>
                <VehicleDetailsHorizontal make={selectedVehicleMake} vehicle={selectedVehicle} />
              </Card>
            </Badge.Ribbon>
          </Col>
        </Row>
        <Card
          title={
            <ContainerRange>
              <ElementRange>
                {t('navigation.dashboard')}
                <Select
                  defaultValue={VehicleStatusEnum.Available}
                  style={{ width: 250, marginLeft: 10 }}
                  onChange={(value) => setFilterByVehicleStatus(Number(value))}
                >
                  <Select.Option value={VehicleStatusEnum.Available}>{t('reservation.available')}</Select.Option>
                  <Select.Option value={VehicleStatusEnum.Sold}>{t('reservation.sold')}</Select.Option>
                  <Select.Option value={VehicleStatusEnum.All}>{t('reservation.all')}</Select.Option>
                </Select>
              </ElementRange>
              <RangePicker
                allowClear={false}
                presets={rangePresets as TimeRangePickerProps['presets']}
                defaultValue={[dayjs().startOf('week').add(1, 'd'), dayjs().startOf('week').add(14, 'd')]}
                showTime
                value={dateRange as [Dayjs, Dayjs]}
                format={DATE_FORMAT}
                onChange={onRangeChange}
              />
              <Button
                icon={<LeftOutlined />}
                type="primary"
                style={{ margin: 5 }}
                onClick={() => setDateRange([dateRange[0].add(-7, 'd'), dateRange[1].add(-7, 'd')])}
              />
              <Button
                icon={<RightOutlined />}
                type="primary"
                style={{ margin: 5 }}
                onClick={() => setDateRange([dateRange[0].add(7, 'd'), dateRange[1].add(7, 'd')])}
              />
              <Button
                icon={<PlusOutlined />}
                type="primary"
                style={{ margin: 5 }}
                onClick={() => newMultiDayReservation()}
              >
                {t('reservation.newReservation')}
              </Button>
            </ContainerRange>
          }
          bordered
          style={{
            margin: 20
          }}
        >
          <Skeleton loading={isLoadingReservationTable} active>
            {reservationTable && (
              <div onMouseUp={handleMouseUp}>
                <ReactDataGrid
                  theme="default-light"
                  idProperty="id"
                  enableColumnHover
                  rowHeight={25}
                  cellSelection={cellSelection}
                  onCellSelectionChange={setCellSelection}
                  renderRowContextMenu={renderRowContextMenu}
                  style={gridStyle}
                  defaultSortInfo={makeList && reservationTable.length > 0 && (defaultSortInfo as TypeSingleSortInfo[])}
                  columns={getColumns()}
                  onCellDoubleClick={handleCellDoubleClick}
                  groups={groups}
                  dataSource={combinedTable || []}
                  defaultFilterValue={filterValue}
                />
              </div>
            )}
          </Skeleton>
          {/* <div>
            <FloatButton
              shape="circle"
              type="primary"
              style={{ right: 94 }}
              onClick={toggleSupport}
              icon={<CustomerServiceOutlined />}
            />
            {isSupportOpen && <OpenAISupport toggleChat={toggleSupport} />}
          </div> */}
        </Card>
      </Col>
      {/* <Col span={4}>
        <Badge.Ribbon
          style={{ marginTop: -20, marginRight: 20 }}
          text={getTextColorForRegistration(daysLeftForRegistrationRenewal)}
          color={getColorForRegistration(daysLeftForRegistrationRenewal)}
        >
          <Card
            title={t('dashboard.vehicleDescription')}
            bordered
            style={{ marginTop: 20, marginBottom: 20, marginRight: 20 }}
            extra={
              <Space>
                <Button type="primary" icon={<HistoryOutlined />} onClick={showReservationHistoryDrawer}>
                  {t(`reservation.history`)}
                </Button>
              </Space>
            }
          >
            {selectedVehicle && (
              <>
                <p>
                  {t('reservation.registrationRenewal')}
                  {daysLeftForRegistrationRenewal}
                  {t('reservation.days')}
                </p>
                <Progress
                  percent={(daysLeftForRegistrationRenewal / registrationPeriodInDays) * 100}
                  showInfo={false}
                  status={daysLeftForRegistrationRenewal < REGISTRATION_WARNING_LOW ? 'exception' : 'normal'}
                />
              </>
            )}
            <VehicleDetails make={selectedVehicleMake} vehicle={selectedVehicle} />
          </Card>
        </Badge.Ribbon>
        <Card
          title={t('dashboard.reservationDetails')}
          bordered
          style={{ marginTop: 20, marginBottom: 20, marginRight: 20 }}
        >
          <ReservationDetails reservationDay={selectedDayReservation} />
        </Card>
      </Col> */}
      <Drawer
        title={`${t(`reservation.reservations`)} ${dayjs(reservationDayProps?.reservationDay.date).format(
          DATE_FORMAT
        )}`}
        placement="right"
        closable
        onClose={onClose}
        open={open}
        width={800}
        destroyOnClose
        extra={
          <Space>
            <Button type="primary" icon={<HistoryOutlined />} />
          </Space>
        }
      >
        {reservationDayProps && (
          <ReservationDay
            reservationDay={reservationDayProps.reservationDay}
            vehicle={reservationDayProps.vehicle}
            setOpen={setOpen}
          />
        )}
      </Drawer>
      <Drawer
        title={t(`reservation.reservationHistory`)}
        width={500}
        closable={false}
        destroyOnClose
        onClose={onChildrenDrawerClose}
        open={reservationHistoryDrawer}
        extra={
          <Space>
            <Search placeholder={t(`common.search`)} onSearch={onSearch} style={{ width: 200 }} />
          </Space>
        }
      >
        <Skeleton loading={isloadingReservationHistory} active>
          <Timeline mode="left">{getTimelineItems()}</Timeline>
        </Skeleton>
        <p>
          {t('reservation.loadedPeriodToDate')}
          {historyPeriod.fromDate}
        </p>
        <Button
          type="primary"
          style={{ marginTop: 16 }}
          icon={isloadingReservationHistory ? <LoadingOutlined /> : ''}
          loading={isloadingReservationHistory}
          onClick={() => {
            const newFromDate = dayjs(historyPeriod.fromDate).add(-1, 'month').format(DB_DATE_FORMAT);
            const newToDate = dayjs(historyPeriod.toDate).add(-1, 'month').format(DB_DATE_FORMAT);
            setHistoryPeriod({
              fromDate: newFromDate,
              toDate: newToDate,
              licensePlate: selectedVehicle?.licensePlate,
              vehicleType: selectedVehicle?.vehicleType
            });
          }}
        >
          {t(`common.loadMore`)}
        </Button>
      </Drawer>
      <Drawer
        title={t(`reservation.reservations`)}
        placement="right"
        closable
        onClose={onCloseMultiDayReservation}
        open={openMultiDayReservation}
        width={800}
        destroyOnClose
        getContainer={false}
        extra={
          <Space>
            <Button type="primary" icon={<HistoryOutlined />} />
          </Space>
        }
      >
        {multiReservationDayProps && (
          <ReservationForm
            vehicle={multiReservationDayProps.vehicle}
            reservationDays={multiReservationDayProps.reservationDays}
            companyId={company?.id || ''}
            allDay
            setOpenMultiDayReservation={setOpenMultiDayReservation}
          />
        )}
      </Drawer>
    </Row>
  );
};

export default ReservationTable;
