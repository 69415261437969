import { useTranslation } from 'react-i18next';
import { IMake, IVehicle, TyreTypeEnum } from 'Models/Models';
import { ColorPicker, Descriptions, DescriptionsProps } from 'antd';
import { LogoRenderer, getFormattedDateLong, getFuelType, getTransmissionType } from '../../Tools';
import { VehicleHeader } from './HoverContent.style';
import { ReactComponent as Winter } from '../../../../../../theme/icons/winter.svg';
import { ReactComponent as Summer } from '../../../../../../theme/icons/summer.svg';
import { ReactComponent as AllSeason } from '../../../../../../theme/icons/all-season.svg';

const HoverContent = ({ vehicle, make }: { vehicle: IVehicle; make: IMake | undefined }) => {
  const { t } = useTranslation();
  const makeItems: DescriptionsProps['items'] = [
    {
      key: '0.1',
      label: t('make.logo'),
      children: <LogoRenderer width="15px" height="15px" svgString={make?.logo} />
    },
    // {
    //   key: '1',
    //   label: t('make.make'),
    //   children: make.name
    // },
    {
      key: '2',
      label: t('make.country'),
      children: make?.country
    }
  ];
  const vehicleItems: DescriptionsProps['items'] = [
    {
      key: '1',
      label: t('vehicle.licensePlate'),
      children: vehicle.licensePlate
    },
    {
      key: '1.2',
      label: t('vehicle.registered'),
      children: getFormattedDateLong(vehicle.registered)
    },
    {
      key: '2',
      label: t('vehicle.vin'),
      children: vehicle.vin
    },
    {
      key: '3',
      label: t('vehicle.mileage'),
      children: vehicle.mileage
    },
    {
      key: '4',
      label: t('vehicle.color'),
      children: <ColorPicker size="small" value={vehicle.color} disabled />
    },
    {
      key: '5',
      label: t('vehicle.transmission'),
      children: getTransmissionType(vehicle.transmission)
    },
    {
      key: '6',
      label: t('vehicle.fuelType'),
      children: getFuelType(vehicle.fuelType)
    },
    {
      key: '7',
      label: t('vehicle.year'),
      children: vehicle.year
    },
    {
      key: '8',
      label: t('vehicle.engineSize'),
      children: vehicle.engineSize
    },
    {
      key: '9',
      label: t('vehicle.doors'),
      children: vehicle.doors
    },
    {
      key: '10',
      label: t('vehicle.seats'),
      children: vehicle.seats
    },
    {
      key: '11',
      label: t('vehicle.power'),
      children: vehicle.power
    },
    {
      key: '12',
      label: t('vehicle.description'),
      children: vehicle.description
    },
    {
      key: '13',
      label: t('vehicle.price'),
      children: vehicle.price
    },
    {
      key: '14',
      label: t('vehicle.isSold'),
      children: vehicle.isSold ? (
        <p style={{ color: 'red' }}>{t('vehicle.yes')}</p>
      ) : (
        <p style={{ color: 'green' }}>{t('vehicle.no')}</p>
      )
    },
    {
      key: '15',
      label: t('vehicle.sold'),
      children: vehicle.sold && getFormattedDateLong(vehicle.sold)
    },
    {
      key: '16',
      label: t('vehicle.tyreType'),
      children: (
        <div style={{ textAlign: 'center' }}>
          {vehicle.tyreType === TyreTypeEnum.AllSeason && <AllSeason style={{ width: 30, height: 30 }} />}
          {vehicle.tyreType === TyreTypeEnum.Summer && <Summer style={{ width: 30, height: 30 }} />}
          {vehicle.tyreType === TyreTypeEnum.Winter && <Winter style={{ width: 30, height: 30 }} />}
        </div>
      )
    }
  ];

  return (
    <Descriptions
      key={`${vehicle.id}-vehicle-model-desc`}
      style={{ width: 600 }}
      title={
        <VehicleHeader>
          <LogoRenderer width="25px" height="25px" svgString={make?.logo} />
          {`${make?.name} ${vehicle.modelName} - ${vehicle.name}`}
        </VehicleHeader>
      }
      items={vehicleItems}
    />
  );
};

export default HoverContent;
