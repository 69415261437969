import styled from 'styled-components';
import { theme } from '../../../../../styles/theme';

export const NotificationItem = styled.div<{ isNew: string }>`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 6px;
  font-weight: ${({ isNew }) => (isNew === 'true' ? 'bold' : 'normal')};
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const TitleAndIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NotificationTitle = styled.div`
  font-size: 16px;
  color: #333;
`;

export const NotificationText = styled.div`
  font-size: 14px;
  color: #333;
`;

export const NotificationTime = styled.div`
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  align-self: flex-end;
`;

export const NotificationIcon = styled.div`
  width: 50px;
  height: 50px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 24px;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
  }
`;
