import React, { useEffect, useState } from 'react';
import { Button, Form, Divider, Row, Col, Space, Modal, message } from 'antd';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { DisabledTimes, EventValue } from 'rc-picker/lib/interface';
import { LoadingOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { RangeType } from 'rc-picker/lib/RangePicker';
import { Header, Label, Wrapper, ButtonContainer } from './ReservationDay.style';
import { IDay, IVehicle, IReservation, ReservationTypeEnum } from '../../../../../Models/Models';
import { useAppSelector } from '../../../../../hooks';
import { useSaveReservationsMutation } from '../../../../../services/autobookerApi';
import Reservation from './components/Reservation';
import { closingTime, convertToTimeString, findFirstAvailableTime, openingTime } from '../Tools';
import { openNotificationWithIcon } from '../../../../Notification/Notification';
import { colors } from '../../../../../styles/theme';
import VehicleDetailsHorizontal from '../VehicleDetails/VehicleDetailsHorizontal';

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
}

interface IProps {
  vehicle: IVehicle;
  reservationDay: IDay;
  setOpen: (value: boolean) => void;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <Wrapper>
    <Label>{title}:</Label>
    {content}
  </Wrapper>
);

const ReservationDay = ({ vehicle, reservationDay, setOpen }: IProps) => {
  const { t } = useTranslation();
  const [saveReservations, { isLoading }] = useSaveReservationsMutation();
  const company = useAppSelector((state) => state.auth.company);
  const user = useAppSelector((state) => state.auth.user);
  const vehicleMake = useAppSelector((state) => state.reservation.makeList.find((make) => make.id === vehicle.makeId));
  const [day, setDay] = useState<IDay>(reservationDay);
  const [reservationChanged, setReservationChanged] = useState(false);

  useEffect(() => {
    setDay(reservationDay);
  }, [reservationDay]);

  // useEffect(() => {
  //   const intervalId = setInterval(async () => {
  //     if (!reservationChanged) {
  //       return;
  //     }
  //     if (!day.reservations || day.reservations.some((reservation) => reservation.hasError)) {
  //       return;
  //     }
  //     const res = await saveReservations(day);
  //     if ('error' in res) {
  //       message.error(t('reservation.reservationSavedError'));
  //       return;
  //     }
  //     setReservationChanged(false);
  //     message.success(t('reservation.reservationSaved'));
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, [day, reservationChanged]);

  const handleSaveButton = async () => {
    if (!day.reservations) {
      return;
    }
    if (day.reservations.some((reservation) => reservation.hasError)) {
      message.error(t('reservation.timeOverlap'));
      return;
    }
    await saveReservations(day).then((res) => {
      if ('error' in res) {
        openNotificationWithIcon('error', t('common.error'), t('reservation.reservationSavedError'));
        return;
      }
      setReservationChanged(false);
      openNotificationWithIcon('success', t('common.success'), t('reservation.reservationSaved'));
      setOpen(false);
    });
  };

  const handleDisabledTime = (date: EventValue<Dayjs>, type: RangeType): DisabledTimes => {
    const disabledHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 20, 21, 22, 23];
    const disabledMinutes = [15, 45];
    return {
      disabledHours: () => disabledHours,
      disabledMinutes: () => disabledMinutes
    };
  };

  const handleAddReservation = () => {
    const values = findFirstAvailableTime(day.reservations);

    if (values === null) {
      openNotificationWithIcon('warning', t('common.warning'), t('reservation.noAvailableTime'));
      return;
    }

    if (values !== null && (values.startTime === -1 || values.endTime === -1)) {
      openNotificationWithIcon('warning', t('common.warning'), t('reservation.noAvailableTime'));
      return;
    }

    const newReservation: IReservation = {
      id: uuid(),
      connectorId: uuid(),
      startTime: values !== null ? convertToTimeString(values.startTime) : '',
      endTime: values !== null ? convertToTimeString(values.endTime) : '',
      additionalDescription: '',
      reservedByDescription: '',
      reservedByUserId: user?.id || '',
      reservationType: ReservationTypeEnum.TestDrive,
      allDay: false
    };

    if (day.reservations === null || day.reservations === undefined) {
      day.reservations = [] as IReservation[];
    }

    const newReservationDay: IDay = {
      ...day,
      reservations: [...(day.reservations || []), newReservation]
    };

    setDay(newReservationDay);
  };

  const subtractTimes = (startTime: string, endTime: string): number => {
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);

    let diff = end.getTime() - start.getTime();

    if (diff < 0) {
      diff += 24 * 60 * 60 * 1000;
    }
    const hours = diff / (1000 * 60 * 60);

    return parseFloat(hours.toFixed(2));
  };

  const totalWorkingHours = subtractTimes(convertToTimeString(closingTime), convertToTimeString(openingTime));

  const handleItemChange = (index: any, updatedItem: any) => {
    const updatedItems = [...day.reservations];
    updatedItem = {
      ...updatedItem,
      allDay: subtractTimes(updatedItem.startTime, updatedItem.endTime) === totalWorkingHours
    };

    const updatedItemsWithErrors = updatedItems.map((reservation, i) => {
      if (i === index) return updatedItem;
      const startTimeA = reservation.startTime ?? Number.MIN_SAFE_INTEGER;
      const endTimeA = reservation.endTime ?? Number.MAX_SAFE_INTEGER;
      const startTimeB = updatedItem.startTime ?? Number.MIN_SAFE_INTEGER;
      const endTimeB = updatedItem.endTime ?? Number.MAX_SAFE_INTEGER;

      const hasError = startTimeB < endTimeA && endTimeB > startTimeA;

      return { ...reservation, hasError };
    });

    const isOverlap = updatedItemsWithErrors.some((reservation, i) => {
      return i !== index && reservation.hasError;
    });

    if (isOverlap) {
      message.error(t('reservation.timeOverlap'));
      // openNotificationWithIcon('warning', t('common.warning'), t('reservation.timeOverlap'));
    }

    setDay((dayt) => ({ ...dayt, reservations: updatedItemsWithErrors }));
    setReservationChanged(true);
  };

  const deleteReservation = (dayt: IDay, id: string): IDay => {
    const updatedReservations = dayt.reservations.filter((reservation) => reservation.id !== id);

    return {
      ...dayt,
      reservations: updatedReservations
    };
  };

  const handleItemDelete = (id: string): void => {
    Modal.confirm({
      title: t('reservation.deleteReservation'),
      onOk: () => {
        const updatedDay = deleteReservation(day, id);
        setDay(updatedDay);
      }
    });
  };

  const onFinish = async (values: any) => {
    await handleSaveButton();
  };

  useEffect(() => {
    if (!reservationDay.reservations || reservationDay.reservations.length === 0) {
      handleAddReservation();
    }
  }, []);

  return (
    <Form
      name="reservationForm"
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      style={{ maxWidth: 800 }}
    >
      <VehicleDetailsHorizontal vehicle={vehicle} make={vehicleMake} />
      <Divider />
      {day.reservations &&
        day.reservations.map((reservation: IReservation, index) => (
          <Form.Item key={reservation.id} wrapperCol={{ span: 24 }}>
            <Reservation
              key={reservation.id}
              companyId={company?.id || ''}
              date={dayjs(reservationDay.date).toDate()}
              reservation={{ ...reservation }}
              vehicle={vehicle}
              trackStyle={reservation.hasError && reservation.hasError === true ? colors.errorPrimary : null}
              onChange={(item: IReservation) => handleItemChange(index, item)}
              onDeleteReservation={() => handleItemDelete(reservation.id)}
            />
          </Form.Item>
        ))}
      <ButtonContainer>
        <Space>
          <Button type="default" icon={<PlusOutlined />} title="Add" onClick={handleAddReservation}>
            {t(`reservation.new`)}
          </Button>
        </Space>
      </ButtonContainer>
      <Divider />
      <Form.Item label={t('reservationForm.reservation')}>
        <Button block type="primary" icon={isLoading ? <LoadingOutlined /> : <SaveOutlined />} htmlType="submit">
          {t('reservationForm.save')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ReservationDay;
