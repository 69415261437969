import styled from 'styled-components';
// import bgImage from '../../../theme/v2/authbg.svg';
import { theme } from '../../../styles/theme';

export const DashboardContainer = styled.div`
  height: 100vh;
  flex-direction: column;
  display: flex;
`;

export const DashboardContent = styled.div`
  flex: 1;
  background-color: ${theme.main.colors.snow};
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div`
  width: 100%;
  background-color: #f0f2f5;
  flex: 1;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
`;
