export enum VehicleTypeEnum {
  Car = 0,
  Motorcycle = 1,
  Van = 2,
  Truck = 3,
  Bus = 4,
  Other = 5
}

export enum CarTypeEnum {
  Limousine = 0,
  Sedan = 1,
  Hatchback = 2,
  StationWagon = 3,
  CompactSUV = 4,
  LuxurySUV = 5,
  CrossoverSUV = 6,
  Coupe = 7,
  Convertible = 8,
  ElectricCar = 9,
  HybridCar = 10,
  Minivan = 11,
  PickupTruck = 12,
  OffRoadVehicle = 13,
  AutonomousCar = 14,
  SportsCar = 15
}

export enum VehicleStatusEnum {
  Available = 0,
  Sold = 1,
  All = 2
}

export enum TransmissionTypeEnum {
  Manual = 0,
  Automatic = 1
}

export enum FuelTypeEnum {
  Petrol = 0,
  Diesel = 1,
  Electric = 2,
  Hybrid = 3,
  Other = 4
}

export enum RegistrationPeriodTypeEnum {
  Year = 0,
  Month = 1,
  Day = 2
}

export enum TyreTypeEnum {
  Summer = 0,
  Winter = 1,
  AllSeason = 2
}

export enum FileTypeEnum {
  ReservationAttachment = 0,
  VehicleImage = 1
}

export enum DocumentTypeEnum {
  CONSENT_FOR_TEST_DRIVE = 'CONSENT_FOR_TEST_DRIVE',
  VEHICLE_USE_AGREEMENT = 'VEHICLE_USE_AGREEMENT',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  HANDOVER_PROTOCOL_TAKEOVER = 'HANDOVER_PROTOCOL_TAKEOVER',
  HANDOVER_PROTOCOL_RETURN = 'HANDOVER_PROTOCOL_RETURN',
  AUTHORIZATION = 'AUTHORIZATION',
  ATTACHMENT_TO_THE_CONTRACT = 'ATTACHMENT_TO_THE_CONTRACT'
}

export enum HttpVerbEnum {
  GET = 0,
  HEAD = 1,
  PUT = 2,
  DELETE = 3
}

export enum ReservationTypeEnum {
  TestDrive = 0,
  SubstituteVehicle = 1,
  InternalUse = 2,
  Rental = 3,
  Other = 9
}

export interface IAttachment {
  uid: string;
  name: string;
  status?: string;
  url: string;
  percent?: number;
}

export interface IReservation {
  id: string;
  connectorId: string;
  startTime: string | null;
  endTime: string | null;
  allDay: boolean;
  reservedByUserId: string;
  reservationType: ReservationTypeEnum;
  reservedByDescription: string;
  additionalDescription: string;
  contractNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  documentNumber?: string;
  personalNumber?: string;
  companyName?: string;
  companyAddress?: string;
  companyCity?: string;
  companyVat?: string;
  hasError?: boolean;
  attachments?: IAttachment[];
}

export interface IDisplayValue {
  id: string;
  value: string;
}

export interface IDay {
  companyId: string;
  date: string;
  reservations: IReservation[];
  displayValue?: IDisplayValue;
}

export interface IVehicle {
  key: string;
  id: string;
  vehicleType: VehicleTypeEnum;
  name: string;
  makeId: string | null;
  seriesId: string | null;
  modelId: string | null;
  modelName: string;
  year: number;
  month: number;
  mileage: number;
  color: string;
  licensePlate: string;
  registered: string;
  registrationPeriod: number;
  registrationPeriodType: RegistrationPeriodTypeEnum;
  transmission: TransmissionTypeEnum;
  fuelType: FuelTypeEnum;
  tyreType: TyreTypeEnum;
  vin: string;
  engineSize: number;
  doors: number;
  seats: number;
  power: number;
  description: string;
  price: number;
  isSold: boolean;
  sold: string | null;
  imageUrl: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  hasCascoInsurance: boolean;
  startDemoPeriod: string | null;
  endDemoPeriod: string | null;
  ownership: string | null;
}

export interface IReservationRow {
  id: string;
  vehicle: IVehicle;
  days: IDay[];
}

export interface IModel {
  id: string;
  seriesId: string;
  name: string;
  productCode: string;
  code: string;
}

export interface ISeries {
  id: string;
  makeId: string;
  name: string;
  vehicleType: VehicleTypeEnum;
  carType: CarTypeEnum;
  models?: IModel[] | null;
}

export interface IMake {
  id: string;
  name?: string;
  companyId?: string;
  logo?: string;
  country?: string;
  created: Date;
  updated?: Date | null;
  createdBy: string;
  series?: ISeries[] | null;
}

export interface IOpenAIReservation {
  sentence: string;
}

export interface IOpenAIResponse {
  startDate?: Date;
  endDate?: Date;
  startTime?: number;
  endTime?: number;
  isReservation?: boolean;
  licensePlate?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleColor?: string;
}

export interface IMessage {
  role: string;
  name: string;
  content: string;
  function_call: string | null;
}

export interface INotification {
  id: string;
  title: string;
  message: string;
  type: string;
  timestamp: Date;
  isNew: boolean;
}

export interface IGetPresignedS3UrlRequest {
  fileName: string;
  companyId: string;
  vehicleId: string;
  reservationId: string;
  date: Date;
  fileType: FileTypeEnum;
  httpVerb: HttpVerbEnum;
}

export interface IGetPresignedS3UrlDownloadRequest {
  filePath: string;
  fileType: FileTypeEnum;
}

export interface IGetPresignedS3UrlResponse {
  url: string;
  fields: { [key: string]: string };
  fullUrl: string;
}

export type GetPresignedS3UrlResponse = {
  url: string;
  fields: Record<string, string>;
  fullUrl: string;
};

export interface IGetQRCodeUrlRequest {
  reservation: IReservation;
}

export interface IGetQRCodeUrlResponse {
  qrIdentifier: string;
}

export interface CheckQRIdentifierRequest {
  qrIdentifier: string;
}

export interface CheckQRIdentifierResponse {
  isValid: boolean;
}
