export function g(n: string, d?: string) {
  const k = `REACT_APP_${n.toUpperCase()}`;
  const v = process.env[k];
  if (v) {
    return v;
  }
  if (d) {
    return d;
  }
  throw Error(`The environment variable ${k} needs to be set`);
}

export default {
  Auth: {
    mandatorySignIn: true,
    userPoolId: g('USER_POOL_ID'),
    userPoolWebClientId: g('USER_POOL_WEB_CLIENT_ID'),
    region: g('AWS_REGION', 'eu-west-1'),
    oauth: {
      domain: g('AWS_COGNITO_DOMAIN'),
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: g('REDIRECT_SIGN_IN'),
      redirectSignOut: g('REDIRECT_SIGN_OUT')
    }
  },
  backend: g('BACKEND_URI', '/api')
};
