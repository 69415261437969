import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, CollapseProps, Drawer, Input, Select, Slider, Space, Tooltip } from 'antd';
import { IReservation, IVehicle, ReservationTypeEnum } from '../../../../../../Models/Models';
import {
  AdditionalDescriptionInput,
  DeleteIcon,
  DescriptionInput,
  EmployeeInput,
  InputContainer,
  ItemDate,
  ItemHeader,
  ItemListContainer,
  PrintDocumentationIcon,
  ReservationTypeInput,
  SliderWrapper
} from './Reservation.style';
import { useGetCompanyEmployeesQuery } from '../../../../../../services/autobookerApi';
import { closingTime, convertToSliderValue, convertToTimeString, marks, openingTime } from '../../Tools';
import ReservationUserDetails from './ReservationUserDetails/ReservationUserDetails';
import Documentation from './ReservationUserDetails/Documentation';

interface IProps {
  reservation: IReservation;
  vehicle: IVehicle;
  companyId: string;
  date: Date;
  onChange(item: IReservation): void;
  trackStyle?: string | null;
  onDeleteReservation(id: string): void;
}

const Reservation = ({ reservation, vehicle, companyId, date, onChange, trackStyle, onDeleteReservation }: IProps) => {
  const { t } = useTranslation();
  const [openDocumentations, setOpenDocumentations] = useState(false);
  const { data: companyEmployee } = useGetCompanyEmployeesQuery(null);

  const showDrawerDocumentation = () => {
    setOpenDocumentations(true);
  };

  const onCloseDocumentation = () => {
    setOpenDocumentations(false);
  };

  const getTotalTime = (reserv: IReservation): string => {
    if (!reserv.startTime || !reserv.endTime) {
      return '';
    }
    const time1Parts = reserv.startTime.split(':');
    const time2Parts = reserv.endTime.split(':');
    const date1 = new Date(0, 0, 0, parseInt(time1Parts[0], 10), parseInt(time1Parts[1], 10), parseInt('0', 10));
    const date2 = new Date(0, 0, 0, parseInt(time2Parts[0], 10), parseInt(time2Parts[1], 10), parseInt('0', 10));
    const timeDifferenceInMilliseconds = date2.getTime() - date1.getTime();

    return `${Math.floor(timeDifferenceInMilliseconds / 1000 / 60 / 60)}h ${
      Math.floor(timeDifferenceInMilliseconds / 1000 / 60) % 60
    }m`;
  };

  const [totalTime, setTotalTime] = useState(getTotalTime(reservation));
  const formatTooltipText = (value: any) => {
    return `${convertToTimeString(value)}`;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const updatedItem = { ...reservation, [name]: value };
    onChange(updatedItem);
  };

  const handleSelectChange = (value: any) => {
    const updatedItem = { ...reservation, reservedByUserId: value };
    onChange(updatedItem);
  };

  const handleSelectReservationTypeChange = (value: any) => {
    const updatedItem = { ...reservation, reservationType: value };
    onChange(updatedItem);
  };

  const handleRangeChange = (value: any) => {
    const updatedItem = {
      ...reservation,
      startTime: convertToTimeString(value[0]),
      endTime: convertToTimeString(value[1])
    };
    onChange(updatedItem);
    setTotalTime(getTotalTime(updatedItem));

    if (value[0] === value[1]) {
      updatedItem.hasError = true;
    }
  };

  const getEnumOptions = (enumType: any) => {
    return Object.keys(enumType)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => ({
        value: enumType[key],
        label: t(`reservation.${key}`)
      }))
      .sort((a, b) => a.value - b.value);
  };

  const onPrintDocumentationReservation = (id: string) => {
    window.open(`/api/reservations/${id}/pdf`);
  };

  const useritem: CollapseProps['items'] = [
    {
      key: '1',
      label: t('reservation.moreDetails'),
      children: (
        <ReservationUserDetails reservation={reservation} onChange={onChange} companyId={companyId} date={date} />
      )
    }
  ];

  return (
    <ItemListContainer key={reservation.id}>
      <ItemHeader>
        <ItemDate>{totalTime}</ItemDate>
        <Space direction="horizontal" onClick={() => showDrawerDocumentation()}>
          <strong style={{ cursor: 'pointer' }}>
            {reservation.contractNumber ? `${t('documentation.num')} ${reservation.contractNumber}` : ''}
          </strong>
          <Tooltip title={t('reservation.documentation')}>
            <PrintDocumentationIcon />
          </Tooltip>
        </Space>
        <Drawer
          width={500}
          title={t('reservation.documentation')}
          placement="right"
          onClose={onCloseDocumentation}
          open={openDocumentations}
        >
          <Documentation reservation={reservation} vehicle={vehicle} date={date} onChange={onChange} />
        </Drawer>
        <Space>
          <DeleteIcon onClick={() => onDeleteReservation(reservation.id)} />
        </Space>
      </ItemHeader>
      <InputContainer>
        <DescriptionInput
          rules={[
            {
              required: true,
              message: t('reservation.reservedByDescriptionRequired')
            }
          ]}
          hasFeedback
        >
          <Tooltip title={t('reservationForm.description')}>
            <Input
              name="reservedByDescription"
              placeholder={t('reservationForm.description')}
              required
              onChange={handleChange}
              defaultValue={reservation.reservedByDescription}
              allowClear
            />
          </Tooltip>
        </DescriptionInput>
        <AdditionalDescriptionInput>
          <Tooltip title={t('reservationForm.additionalDescription')}>
            <Input
              name="additionalDescription"
              placeholder={t('reservationForm.additionalDescription')}
              onChange={handleChange}
              defaultValue={reservation.additionalDescription}
              allowClear
            />
          </Tooltip>
        </AdditionalDescriptionInput>
        <EmployeeInput>
          <Tooltip title={t('reservationForm.employee')}>
            <Select
              showSearch
              placeholder="Select"
              optionFilterProp="label"
              onChange={handleSelectChange}
              defaultValue={reservation.reservedByUserId}
              filterSort={(optionA, optionB) =>
                String(optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare(String(optionB?.label ?? '').toLowerCase())
              }
              options={companyEmployee?.map((employee: any) => ({
                value: employee.id,
                label: `${employee.firstName} ${employee.lastName}`
              }))}
            />
          </Tooltip>
        </EmployeeInput>
        <ReservationTypeInput>
          <Select
            showSearch
            placeholder="Reservation Type"
            optionFilterProp="label"
            onChange={handleSelectReservationTypeChange}
            defaultValue={reservation.reservationType}
            filterSort={(optionA, optionB) =>
              String(optionA?.label ?? '')
                .toLowerCase()
                .localeCompare(String(optionB?.label ?? '').toLowerCase())
            }
            options={getEnumOptions(ReservationTypeEnum)}
          />
        </ReservationTypeInput>
      </InputContainer>
      <SliderWrapper>
        <Slider
          trackStyle={[trackStyle ? { backgroundColor: trackStyle } : {}]}
          tooltip={{ formatter: formatTooltipText }}
          key={reservation.id}
          range={{ draggableTrack: true }}
          min={openingTime}
          max={closingTime}
          step={50}
          marks={marks}
          onChange={handleRangeChange}
          defaultValue={[convertToSliderValue(reservation.startTime), convertToSliderValue(reservation.endTime)]}
        />
      </SliderWrapper>
      <Collapse ghost items={useritem} />
    </ItemListContainer>
  );
};

export default Reservation;
