import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { WebSocketProvider } from 'WebSocketContext';
import store from './store/store';
import App from './App';
import './index.css';

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    react: { useSuspense: false }
  });

const domNode = document.getElementById('root');
if (domNode) {
  const root = createRoot(domNode);

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  console.error("Element with ID 'root' not found in the document.");
}
