import { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Svg, Image, Font } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';
import dayjs from 'dayjs';
import { IDocProps } from './Utils';
import { SideFooter } from './SideFooter';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const logoImage = require('theme/v2/memorandum.png');
const vehicleSketch = require('theme/v2/vehicle-sketch.png');

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontSize: 12,
    fontFamily: 'Roboto'
  },
  header: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 20,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  article: {
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  logo: {
    width: '100%',
    height: '100%'
  },
  image: {
    alignSelf: 'center',
    width: '60%'
  },
  footer: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 12
  },
  content: {
    paddingLeft: 90,
    paddingRight: 30,
    paddingBottom: 20,
    paddingTop: 10,
    flexDirection: 'column',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  section: {
    flexDirection: 'column',
    marginBottom: 10
  },
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
    textDecoration: 'underline'
  },
  heading2: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center'
  },
  headingInfo: {
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: 'right'
  },
  text: {
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  boldText: {
    fontWeight: 'bold'
  },
  underlinedText: {
    textDecoration: 'underline'
  },
  signature: {
    marginTop: 20
  },
  signatureBottom: {
    bottom: 30,
    width: 120,
    flexWrap: 'wrap',
    right: 15,
    fontSize: 8,
    position: 'absolute'
  },
  signatureUserBottom: {
    bottom: 30,
    width: 120,
    flexWrap: 'wrap',
    left: 100,
    fontSize: 8,
    position: 'absolute'
  }
});

const Signature = () => (
  <View style={styles.signatureBottom}>
    <Text style={styles.text}>Delta Motors d.o.o.</Text>
    <Text> </Text>
    <Text> </Text>
    <Text style={styles.text}>____________________</Text>
  </View>
);

const SignatureUser = () => (
  <View style={styles.signatureUserBottom}>
    <Text style={styles.text}>KORISNIK (ovlašćeno lice)</Text>
    <Text> </Text>
    <Text> </Text>
    <Text style={styles.text}>____________________</Text>
  </View>
);

const toUpperCase = (str: string | undefined) => {
  if (!str) {
    return '';
  }
  return str.toUpperCase();
};

export const HandoverReportPages = ({ reservation, vehicle, title, make, model, connectedData }: IDocProps) => (
  <Page style={styles.page}>
    <View style={styles.header}>
      <Image style={styles.logo} src={logoImage} />
    </View>
    <View style={styles.content}>
      <View style={styles.section}>
        <Text style={styles.heading}>Z A P I S N I K</Text>
        <Text style={styles.heading2}>o primopredaji vozila</Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}>
          Dana {dayjs(connectedData.startDate).format('DD.MM.YYYY')} godine, u{' '}
          {dayjs(connectedData.startDate).format('HH:mm')} časova, KORISNIK
          <Text style={styles.boldText}>
            {reservation.firstName && reservation.lastName
              ? ` ${toUpperCase(reservation.firstName)} ${toUpperCase(reservation.lastName)} `
              : ' ____________ '}
          </Text>
          <Text style={styles.text}>iz</Text>
          <Text style={styles.boldText}>
            {reservation.address && reservation.city
              ? ` ${toUpperCase(reservation.address)}, ${toUpperCase(reservation.city)} `
              : ' ____________ '}
          </Text>
          <Text style={styles.text}>, JMBG/matični broj</Text>
          <Text style={styles.boldText}>
            {reservation.personalNumber ? ` ${toUpperCase(reservation.personalNumber)} ` : ' ____________ '}
          </Text>
          <Text style={styles.text}>, po osnovu Ugovora o najmu vozila br.</Text>
          <Text style={styles.boldText}>
            {reservation.contractNumber ? ` ${toUpperCase(reservation.contractNumber)} ` : ' ____________ '}
          </Text>
          <Text style={styles.text}>
            , <Text style={styles.boldText}>preuzima</Text> u viđenom stanju na korišćenje motorno vozilo:
          </Text>
        </Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}>
          - marka i tip:
          {vehicle.makeId || vehicle.modelId ? (
            <Text style={styles.boldText}>{` ${make && make.name} ${model && model.name} ${vehicle.name}`}</Text>
          ) : (
            ' ______________________ '
          )}
          ,
        </Text>
        <Text style={styles.text}>
          - registarski broj:
          {vehicle.licensePlate ? <Text style={styles.boldText}>{` ${vehicle.licensePlate}`}</Text> : ' ____________ '},
        </Text>
        <Text style={styles.text}>
          - broj šasije:
          {vehicle.vin ? <Text style={styles.boldText}>{` ${vehicle.vin}`}</Text> : ' ____________ '},
        </Text>
        <Text style={styles.text}>- broj motora: ____________,</Text>
        <Text style={styles.text}>- stanje kilometraže na brojilu: _______ km,</Text>
        <Text style={styles.text}>- količina goriva u rezervoaru: _________ litara,</Text>
        <Text style={styles.text}>- postojeća oštećenja na vozilu prilikom preuzimanja:</Text>
        <Text style={styles.text}>
          ___________________________________________________________________________________________
        </Text>
        <Text style={styles.text}>
          ___________________________________________________________________________________________
        </Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}> </Text>
        <View>
          <Image style={styles.image} src={vehicleSketch} />
        </View>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}>
          - oprema: a) presvlake, patosnice b) sigurnosni trougao c) priručna literatura d) rezervni točak e) prva pomoć
        </Text>
        <Text style={styles.text}>f) ratkapne g) ostalo __________________________ (napomena: zaokružiti).</Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}>Uz motorno vozilo KORISNIKU se predaju:</Text>
        <Text style={styles.text}>
          - Saobraćajna dozvola br. _______, izdata od strane MUP RS, dana ________ godine,
        </Text>
        <Text style={styles.text}>- Ključevi 1(jedan) kom.</Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}>Prilog:</Text>
        <Text style={styles.text}>- Ovlašćenje licu za preuzimanje vozila u ime KORISNIKA (za pravna lica)</Text>
        <Text style={styles.text}>
          - KORISNIK potvrđuje da je pre potpisivanja ovog zapisnika pročitao ugovor ________ od dana ___ zaključen
          između DELTA MOTORS i KORISNIKA po osnovu kog preuzima vozilo, da mu je na uvid stavljena dokumentacija na
          koju ugovor rešefiše, i da je u potpunosti saglasan sa svim preuzetim obavezama i dejstvom ugovora.
        </Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}>Zapisnik je urađen u 2 (dva) primerka, po 1 (jedan) za svaku stranu.</Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}>Beograd, {dayjs().format('DD.MM.YYYY')} godine</Text>
        <Text style={styles.text}> </Text>
      </View>
    </View>
    <SideFooter />
    <SignatureUser />
    <Signature />
  </Page>
);

const HandoverReport = ({ reservation, vehicle, title, make, model, connectedData }: IDocProps) => {
  return (
    <Document title={title}>
      <HandoverReportPages
        reservation={reservation}
        vehicle={vehicle}
        title={title}
        make={make}
        model={model}
        connectedData={connectedData}
      />
    </Document>
  );
};

export default HandoverReport;
