import { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Svg, Image, Font } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';
import dayjs from 'dayjs';
import { IDocProps } from './Utils';
import { SideFooter } from './SideFooter';
// import logoImage from '../../theme/v2/memorandum.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const logoImage = require('theme/v2/memorandum.png');

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontSize: 8,
    fontFamily: 'Roboto'
  },
  header: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 18,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  article: {
    textAlign: 'center',
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  logo: {
    width: '100%',
    height: '100%'
  },
  footer: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 12
  },
  content: {
    paddingLeft: 90,
    paddingRight: 30,
    paddingBottom: 20,
    paddingTop: 10,
    flexDirection: 'column',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  section: {
    flexDirection: 'column',
    marginBottom: 10
  },
  heading: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center'
  },
  text: {
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  boldText: {
    fontWeight: 'bold'
  },
  underlinedText: {
    textDecoration: 'underline'
  },
  signature: {
    marginTop: 20
  }
});

const ConsentForTestDrive = ({ reservation, vehicle, title, make, model, connectedData }: IDocProps) => {
  return (
    <Document title={title}>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={logoImage} />
        </View>
        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.heading}>IZJAVA ZA KORIŠĆENJE TEST VOZILA</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Davalac Izjave:</Text>
            <Text style={styles.text}>
              Ime i prezime:
              {reservation.firstName || reservation.lastName ? (
                <Text style={styles.boldText}>
                  {reservation.firstName} {reservation.lastName}
                </Text>
              ) : (
                '______________________'
              )}
            </Text>
            <Text style={styles.text}>
              JMBG:
              {reservation.personalNumber ? (
                <Text style={styles.boldText}>{reservation.personalNumber}</Text>
              ) : (
                '______________________'
              )}
            </Text>
            <Text style={styles.text}>
              Adresa prebivališta:
              {reservation.address ? (
                <Text style={styles.boldText}>{reservation.address}</Text>
              ) : (
                '______________________'
              )}
            </Text>
            <Text style={styles.text}>
              Kontakt telefon:
              {reservation.phoneNumber ? (
                <Text style={styles.boldText}>{reservation.phoneNumber}</Text>
              ) : (
                '______________________'
              )}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>
              dalje u tekstu
              <Text style={styles.boldText}> KORISNIK</Text> ili <Text style={styles.boldText}>Vozač</Text>
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Ovom Izjavom (dalje u tekstu: Izjava) KORISNIK izjavljuje i garantuje DELTA MOTORSU d.o.o. Beograd (dalje
              u tekstu: DELTA MOTORS) da će se povodom korišćenja vozila koje mu je dato na test vožnju u svemu
              pridržavati uslova kako dalje sledi:
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 1.</Text>
            <Text style={styles.boldText}> PODACI O VOZILU</Text>
            <Text style={styles.text}>
              KORISNIKU je od strane DELTA MOTORS dato na korišćenje za test vožnju sledeće vozilo u viđenom stanju i
              bez prisustva tragova o duvanskom dimu u vozilu:
            </Text>
            <Text style={styles.text}>
              Marka i tip:
              {vehicle.makeId || vehicle.modelId ? (
                <Text style={styles.boldText}>
                  {make && make.name} {model && model.name} {vehicle.name}
                </Text>
              ) : (
                '______________________'
              )}
            </Text>
            <Text style={styles.text}>
              Registarskih oznaka:
              {vehicle.licensePlate ? (
                <Text style={styles.boldText}>{vehicle.licensePlate}</Text>
              ) : (
                '______________________'
              )}
            </Text>
            <Text style={styles.text}>(dalje u tekstu: Vozilo).</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>
              KORISNIK izjavljuje da su mu od strane DELTA MOTORS u potpunosti unapred objašnjeni sva uputstva i uslovi
              test/probne vožnje koju će vršiti u Vozilu, te da će se tokom vožnje i upotrebe Vozila pridržavati svih
              pravila saobraćaja i važećih propisa, kao i datih uputstava.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 2.</Text>
            <Text style={styles.boldText}> PODACI O VOZAČU</Text>
            <Text style={styles.text}>
              KORISNIK izjavljuje da poseduje uredno izdatu i važeću vozačku dozvolu za upravljanje Vozilom.
            </Text>
            <Text style={styles.text}>
              Ovlašćenje za upravljanje Vozilom od strane KORISNIKA prestaje danom isteka perioda na koji je dato Vozilo
              na test vožnju, i po isteku tog perioda ima se smatrati da KORISNIK neovlašćeno koristi tuđe motorno
              vozilo, te DELTA MOTORS ima pravo da iskoristi sve pozitivnim propisima predviđene mogućnosti da predmetno
              Vozilo vrati u svoj posed.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 3.</Text>
            <Text style={styles.boldText}> TRAJANJE TEST VOŽNJE /PREUZIMANJE I VRAĆANJE VOZILA</Text>
            <Text style={styles.text}>Vozilo je dato na korišćenje KORISNIKU na sledeći period:</Text>
            <Text style={[styles.text, { marginLeft: 10 }]}>
              Korišćenje Vozila počinje dana:
              {connectedData.startDate ? (
                <Text style={styles.boldText}> {dayjs(connectedData.startDate).format('DD.MM.YYYY')} </Text>
              ) : (
                '____________'
              )}
              godine u
              {connectedData.startDate ? (
                <Text style={styles.boldText}> {dayjs(connectedData.startDate).format('HH:mm')} </Text>
              ) : (
                '____________'
              )}
              časova,
            </Text>
            <Text style={[styles.text, { marginLeft: 10 }]}>
              Korišćenje Vozila završava se dana:
              {connectedData.endDate ? (
                <Text style={styles.boldText}> {dayjs(connectedData.endDate).format('DD.MM.YYYY')} </Text>
              ) : (
                '____________'
              )}
              godine u
              {connectedData.endDate ? (
                <Text style={styles.boldText}> {dayjs(connectedData.endDate).format('HH:mm')} </Text>
              ) : (
                '____________'
              )}
              časova.
            </Text>
            <Text style={styles.text}>
              KORISNIK je obavezan da preuzme, odnosno vrati Vozilo u skladu sa odredbom iz stava 1. ovog člana, kojom
              prilikom sa ovlašćenim predstavnikom DELTA MOTORS potpisuje Zapisnik o primopredaji prilikom preuzimanja
              Vozila na korišćenje, odnosno Zapisnik o primopredaji prilikom vraćanja Vozila.
            </Text>
            <Text style={styles.text}>
              Preuzimanje Vozila na korišćenje i vraćanje Vozila obavlja se isključivo u servisu DELTA MOTORS, Radnička
              8, Beograd, u okviru radnog vremena servisa. DELTA MOTORS.
            </Text>
            <Text style={styles.text}>
              Vremensko prekoračenje dogovorenog trajanja korišćenja Vozila prilikom povraćaja Vozila za više od 60
              minuta obračunava se kao naredni puni dan korišćenja Vozila prema važećem Cenovniku DELTA MOTORSA.
            </Text>
            <Text style={styles.text}>
              Ukoliko prilikom vraćanja Vozila uzetog na korišćenje KORISNIK odbije i/ili ne potpiše Zapisnik o
              primopredaji prilikom vraćanja predmetnog vozila, predstavnik DELTA MOTORS je ovlašćen da samostalno
              utvrdi stanje Vozila koje se vraća, unese potrebne podatke i overi ovaj Zapisnik.
            </Text>
            <Text style={styles.text}>
              U slučaju da KORISNIK predhodno nije produžio korišćenje predmetnog Vozila, niti je Vozilo vratio u
              dogovorenom roku, DELTA MOTORS ima pravo da sam dođe u posed svog Vozila i prateće dokumentacije, a
              KORISNIK se odriče prava na državinsku zaštitu.
            </Text>
          </View>
        </View>
        <SideFooter />
      </Page>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={logoImage} />
        </View>
        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.article}>Član 4.</Text>
            <Text style={styles.boldText}> KORIŠĆENJE VOZILA</Text>
            <Text style={styles.text}>KORISNIK preuzima Vozilo na korišćenje zbog: TEST VOZILO.</Text>
            <Text style={styles.text}>
              Predmetno Vozilo KORISNIK može koristiti samo na teritoriji Republike Srbije (bez teritorije AP Kosovo i
              Metohija).
            </Text>
            <Text style={styles.text}>
              Korišćenje predmetnog Vozila van teritorije Republike Srbije je moguće isključivo uz predhodno dobijenu
              pismenu saglasnost DELTA MOTORS, u protivnom se ima smatrati da KORISNIK neovlašćeno koristi tuđe motorno
              vozilo, te DELTA MOTORS ima pravo da iskoristi sve pozitivnim propisima predviđene mogućnosti da Vozilo
              vrati u svoj posed.
            </Text>
            <Text style={styles.text}>KORISNIKU nije dozvoljeno korišćenje Vozila na sledeće načine:</Text>
            <Text style={styles.text}>
              Da Vozilo uzeto na korišćenje koristi pod uticajem alkohola, narkotika, ostalih opijata ili farmaceutskih
              sredstava koji mogu imati uticaja na vozačku sposobnost
            </Text>
            <Text style={styles.text}>
              Iznajmljivanje ili posuđivanje Vozila trećim licima, kao i neovlašćeno davanje u posed predmetnog Vozila
              trećim licima,
            </Text>
            <Text style={styles.text}>
              Korišćenje i odvoženje predmetnog Vozila van teritorije Republike Srbije bez predhodne pismene saglasnosti
              DELTA MOTORS,
            </Text>
            <Text style={styles.text}>
              Prevoz lako zapaljivih, otrovnih ili drugih opasnih materija ili obavljanje prevoza osoba, te korišćenje
              predmetnog Vozila za obuku,
            </Text>
            <Text style={styles.text}> Učestvovanje u trkama, kao i vuča drugih vozila i sl.,</Text>
            <Text style={styles.text}>
              Korišćenje otvorenog plamena ili pušenje u predmetnom Vozilu, tj. KORISNIK se obavezuje da se u Vozilu
              neće koristiti otvoreni plamen niti pušiti, u protivnom je obavezan da DELTA MOTORSU plati ugovornu kaznu
              u visini od 5.000,00 dinara po svakom pojedinačnom kršenju ove obaveze, a što ne ograničava pravo DELTA
              MOTORSA da u ovakvim slučajevima zahteva od KORISNIKA i punu naknadu štete,
            </Text>
            <Text style={styles.text}>
              Druga upotreba predmetnog Vozila protivno odredbama ove Izjave, odnosno protivno važećim propisima.
            </Text>
            <Text style={styles.text}>
              Kada predmetno Vozilo nije u upotrebi prozori vrata moraju biti zatvoreni i zaključani.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 5.</Text>
            <Text style={styles.boldText}> NAKNADA</Text>
            <Text style={styles.text}>
              KORISNIK je obavezan da na ime korišćenja Vozila plati u korist DELTA MOTORS dnevnu naknadu u iznosu:
              ____/______ EUR za ugovoreni period korišćenja Vozila iz člana 3. (nije uračunat PDV) do pređenih 100
              kilometara po danu, uvećano za iznos: ____/____ EUR (nije uračunat PDV) po svakom više pređenom kilometru.
              Naknada će se obračunati u dinarskoj protivvrednosti po prodajnom kursu NBS na dan izdavanja
              računa/fakture.
            </Text>
            <Text style={styles.text}>
              Ugovorena naknada za korišćenje predmetnog Vozila se odnosi na korišćenje vozila, njegovo održavanje,
              osiguranje i registraciju, kao i sve pripadajuće obaveze. Troškovi u vezi upotrebe Vozila kao što su
              benzin/dizel, ulje, antifriz i druga sredstva koje je tokom korišćenja Vozila potrebno dopuniti, kao i
              naknada putarina, parkinga i garaža, nisu uključeni u naknadu i u celosti ih snosi KORISNIK. KORISNIK
              snosi i sve troškove koji nastanu usled neadekvatnog korišćenja Vozila, oštećenja na Vozilu, nepoštovanja
              saobraćajnih propisa i propisa o parkiranju Vozila i sl..
            </Text>
            <Text style={styles.text}>
              Dnevna naknada za
              <Text style={styles.boldText}> korišćenje Vozila</Text>
              se odnosi na period od 24 časa. KORISNIK je obavezan platiti ukupan iznos neizmirene naknade i sve ostale
              eventualne troškove najkasnije prilikom vraćanja predmetnog Vozila prema cenama iz važećeg cenovnika DELTA
              MOTORS na dan plaćanja.
            </Text>
            <Text style={styles.text}>
              KORISNIK je obavezan, na zahtev DELTA MOTORS, izvršiti avansnu uplatu naknade za korišćenje predmetnog
              Vozila po cenama važećim na dan plaćanja.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 6.</Text>
            <Text style={styles.boldText}> POPRAVKE VOZILA</Text>
            <Text style={styles.text}>
              Popravke predmetnog Vozila vezane za održavanje urednog rada i saobraćajnu sigurnost tokom perioda
              korišćenja vozila od strane KORISNIKA vrše se isključivo u servisu DELTA MOTORS.
            </Text>
            <Text style={styles.text}>
              KORISNIK je obavezan da kada se ukaže potreba za popravkom Vozila bez odlaganja obavesti DELTA MOTORS u
              cilju dobijanja instrukcija za dalje postupanje, te da nakon toga postupi po datim instrukcijama i na taj
              način omogući blagovremenu i optimalnu popravku Vozila u servisu DELTA MOTORS.
            </Text>
            <Text style={styles.text}>
              Ukoliko KORISNIK nije skrivio, niti je odgovoran za kvar na Vozilu, troškove popravke snosi DELTA MOTORS,
              a naknada za korišćenje Vozila za vreme popravke se neće obračunavati.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 7.</Text>
            <Text style={styles.boldText}> POSTUPAK U SLUČAJU NEZGODE/ŠTETE</Text>
            <Text style={styles.text}>
              U slučaju saobraćajne nesreće/nezgode, krađe ili neovlašćenog korišćenja Vozila, nastanka štete na Vozilu
              usled požara ili elementarnih nepogoda, naleta divljači i sl., KORISNIK je obavezan da odmah o tome
              telefonom obavesti DELTA MOTORS i policiju, te da preuzme sve što je u njegovoj moći da bi se sprečila
              dalja oštećenja i osiguralo mesto nesreće i predmetno Vozilo u cilju adekvatnog utvrđivanja činjeničnog
              stanja.
            </Text>
            <Text style={styles.text}>
              KORISNIK je obavezan da DELTA MOTORSU dostavi potpun izveštaj o svim relevantnim okolnostima sa skicom
              nesreće/nezgode. Izveštaj o nesreći/nezgodi mora da sadrži naročito imena, adrese i ostale lične podatke
              lica koja su učestvovali u nesreći/nezgodi, podatke o eventualnim svedocima, registarske podatke o
              vozilima koja su učestvovala u nesreći/nezgodi i dr..
            </Text>
          </View>
        </View>
        <SideFooter />
      </Page>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={logoImage} />
        </View>
        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.article}>Član 8.</Text>
            <Text style={styles.boldText}> OSIGURANJE</Text>
            <Text style={styles.text}>
              Vozilo koje se daje na korišćenje je kasko osigurano.Učešće KORISNIKA u šteti je 10%, minimalno 200 EUR uz
              policijski zapisnik.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 9.</Text>
            <Text style={styles.boldText}> ODGOVORNOST KORISNIKA</Text>
            <Text style={styles.text}>
              KORISNIK je neograničeno odgovoran za svu štetu, troškove, obaveze i potraživanja, kao i sve druge izdatke
              prema DELTA MOTORS ili trećim licima, koji nastanu tokom i u vezi sa korišćenjem predmetnog Vozila,
              uključujući krivicu KORISNIKA, krivicu trećih lica, slučaj ili višu silu, te u tom smislu DELTA MOTORS
              nije odgovoran za štetu koja je ili bi mogla nastati KORISNIKU, putnicima u Vozilu ili trećim licima usled
              korišćenja predmetnog Vozila. KORISNIK snosi i sve troškove koji nastanu usled neadekvatnog korišćenja
              predmetnog Vozila i korišćenja Vozila koje ne pokriva kasko osiguranje.
            </Text>
            <Text style={styles.text}>
              U slučaju pokretanja sudskih i/ili upravnih postupaka ili isticanja bilo kakvih zahteva ili prigovora
              trećih lica vezano za korišćenje i upotrebu predmetnog Vozila od strane KORISNIKA, KORISNIK je u obavezi
              da preuzme svu odgovornost i snosi sve troškove, nadoknade i druge izdatke koji su nastali vođenjem
              predmetnih postupaka i na osnovu odluka donetih u tim postupcima, odnosno da ih nadoknadi strani DELTA
              MOTORS.
            </Text>
            <Text style={styles.text}>
              KORISNIK je obavezan da predmetno Vozilo vrati DELTA MOTORS u istom stanju u kom ga je i preuzeo, u
              protivnom je dužan da nadoknadi sve troškove i štetu u vezi sa predmetnim Vozilom.
            </Text>
            <Text style={styles.text}>
              KORISNIK je odgovoran DELTA MOTORS-u za neprijavljivanje postojanja Zapisnika u slučaju saobraćajne
              nesreće/nezgode i drugih bitnih podataka o saobraćajnoj nesreći/nezgodi, a DELTA MOTORS je ovlašćen da
              KORISNIKU obračuna i naplati svu štetu koja je nastala u slučaju ovakvog postupanja KORISNIKA.
            </Text>
            <Text style={styles.text}>
              KORISNIK nema pravo da zadrži Vozilo, bez obzira iz kog razloga i po kom osnovu može proizilaziti neko
              njegovo potraživanje, kao ni da svoja eventualna potraživanja prema DELTA MOTORS prebija potraživanjima
              DELTA MOTORS.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 10.</Text>
            <Text style={styles.boldText}> ODRICANJE OD ODGOVORNOSTI</Text>
            <Text style={styles.text}>
              U slučaju da KORISNIK prilikom preuzimanja Vozila radi test vožnje ostavi na parkingu objekta DELTA
              MOTORSA svoje motorno vozilo ili motorno vozili trećeg lica, DELTA MOTORS ni u kom slučaju neće biti
              odgovoran za bilo kakvu direktnu, indirektnu, posledičnu, slučajnu i bilo koju drugu vrstu štete, kao i
              štetu usled više sile koja eventualno nastane na vozilu KORISNIKA ili trećeg lica koje je KORISNIK ostavio
              na parkingu objekta DELTA MOTORSA i to za sve vreme dok se vozilo KORISNIKA ili trećeg lica nalazi na
              parkingu objekta DELTA MOTORS, kako za vreme trajanjaa korišćenja Vozila, tako i za vreme posle isteka
              ugovorenog trajanja korišćenja Vozila.
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 11.</Text>
            <Text style={styles.text}>
              “Mi prikupljamo i obrađujemo Vaše lične podatke shodno Obaveštenju o obradi podataka o ličnosti, a koji
              dokument predstavlja prilog 1 ovog dokumenta. Obaveštenje o obradi podataka o ličnosti je sastavni deo
              ovog dokumenta i smatraće se da ste upoznati i informisani o prikupljanju i obradi Vaših ličnih podataka.”
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Ova Izjava je sačinjena u 2 (dva) istovetna primerka, od kojih je 1 (jedan) primerak predat DELTA MOTORSU,
              a 1 (jedan) primerak zadržava KORISNIK.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>U Beogradu, dana {dayjs().format('DD.MM.YYYY')} godine.</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text}>Za KORISNIKA</Text>
            <Text style={styles.text}>
              Ime i prezime:
              {reservation.firstName || reservation.lastName
                ? ` ${reservation.firstName} ${reservation.lastName}`
                : '______________________'}
            </Text>
            <View style={styles.signature}>
              <Text>Svojeručni potpis: _________________</Text>
            </View>
          </View>
        </View>
        <SideFooter />
      </Page>
    </Document>
  );
};

export default ConsentForTestDrive;
