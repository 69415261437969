import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  padding: 20px;
`;

export const Button = styled.button`
  font-size: 18px;
  padding: 10px;
  margin: 10px;
`;

export const TokenText = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
`;

export const WebcamContainer = styled.div`
  max-width: 500px;
  margin: auto;
`;
