import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { useGetNotificationsQuery } from 'services/autobookerApi';
import { MAKES_PATH, VEHICLES_STATS_PATH, VEHICLES_PATH } from 'App';
import { Alert, Avatar, Badge, Empty, Menu, MenuProps } from 'antd';
import {
  UserOutlined,
  DashboardOutlined,
  CalendarOutlined,
  BarChartOutlined,
  ProfileOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { INotification } from 'Models/Models';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import ClickOutsideWrapper from '../../common/ClickOutsideWrapper';
import {
  TopBarWrapper,
  TopBarContent,
  TopBarSection,
  Separator,
  TopBarSectionLabel,
  UserPopup,
  UserPopupItem,
  LanguagePopup,
  LanguagePopupItem,
  NotificationContainer,
  PersonalInfoWrapper,
  CompanyInfoLabel,
  MenuWrapper,
  NotificationsPopup
} from './TopBar.style';
import BellIcon from '../../../../theme/v2/bell.svg';
import UserProfile from '../../../../theme/v2/user-profile.svg';
import Logout from '../../../../theme/v2/logout.svg';
import NotificationMessage from './components/NotificationMessage';

const TopBar = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const company = useAppSelector((state) => state.auth.company);
  const [isUserPopupVisible, setIsUserPopupVisible] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isLanguagePopupVisible, setIsLanguagePopupVisible] = useState(false);
  const [isNotificationPopupVisible, setIsNotificationPopupVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [show, setShow] = useState(true);
  const { isLoading, data: notifications } = useGetNotificationsQuery(`userId=${user?.id}`, {
    skip: !user || user?.id === ''
  });

  type MenuItem = Required<MenuProps>['items'][number];

  const getItem = (label: any, key: any, icon?: any, children?: MenuItem[]): MenuItem => {
    return {
      key,
      icon,
      children,
      label
    } as MenuItem;
  };

  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   dispatch(setSearchValue(e.target.value));
  // };
  const toggleUserPopup = () => {
    setIsUserPopupVisible(!isUserPopupVisible);
  };
  const handleProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };
  const handleLogout = () => {
    Auth.signOut()
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang: string) => {
    sessionStorage.setItem('ares_language', lang);
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    setIsLanguagePopupVisible(false);
  };
  const toggleLanguagePopup = () => {
    setIsLanguagePopupVisible(!isLanguagePopupVisible);
  };
  const toggleNotificationPopup = () => {
    setIsNotificationPopupVisible(!isNotificationPopupVisible);
  };
  useEffect(() => {
    const lang = sessionStorage.getItem('ares_language');
    if (lang) {
      setSelectedLanguage(lang);
    }
  }, []);

  const items: MenuItem[] = [
    getItem(<Link to="/">{t('navigation.dashboard')}</Link>, '1', <CalendarOutlined />),
    getItem(<Link to={MAKES_PATH}>{t('navigation.makes')}</Link>, '2', <ProfileOutlined />),
    getItem(<Link to={VEHICLES_PATH}>{t('navigation.vehicle')}</Link>, '3', <DashboardOutlined />),
    getItem(<Link to={VEHICLES_STATS_PATH}>{t('navigation.vehicleStats')}</Link>, '4', <BarChartOutlined />)
  ];

  return (
    <TopBarWrapper>
      {/* {showProfileModal && <ProfileModal imageData={imageData} closeModal={handleProfileModal} />} */}
      {/* <SearchWrapper>
        <Search type="text" />
        <IconContainer src={SearchIcon} />
      </SearchWrapper> */}
      <MenuWrapper>
        <Menu theme="light" defaultSelectedKeys={['1']} mode="horizontal" items={items} />
      </MenuWrapper>
      <TopBarContent>
        <ClickOutsideWrapper handleClickOutside={() => setIsLanguagePopupVisible(false)}>
          <TopBarSection onClick={toggleLanguagePopup}>
            <TopBarSectionLabel>{t(`topBar.${selectedLanguage}`)}</TopBarSectionLabel>
            {/* <img src={LanguageIcon} alt="language" /> */}
            {isLanguagePopupVisible && (
              <LanguagePopup>
                <LanguagePopupItem shouldHaveBorder onClick={() => changeLanguageHandler('de')}>
                  <div>{t('topBar.de')}</div>
                </LanguagePopupItem>
                <LanguagePopupItem shouldHaveBorder onClick={() => changeLanguageHandler('en')}>
                  <div>{t('topBar.en')}</div>
                </LanguagePopupItem>
                <LanguagePopupItem onClick={() => changeLanguageHandler('sr')}>
                  <div>{t('topBar.sr')}</div>
                </LanguagePopupItem>
              </LanguagePopup>
            )}
          </TopBarSection>
        </ClickOutsideWrapper>
        <Separator />
        <ClickOutsideWrapper handleClickOutside={() => setIsNotificationPopupVisible(false)}>
          <TopBarSection onClick={toggleNotificationPopup}>
            <Badge
              count={notifications && notifications.filter((notification: INotification) => notification.isNew).length}
            >
              <NotificationContainer>
                <img src={BellIcon} alt="notification" />
              </NotificationContainer>
            </Badge>
            {isNotificationPopupVisible && (
              <NotificationsPopup>
                {isLoading && <LoadingOutlined />}
                {!isLoading && notifications?.length
                  ? notifications
                      .slice()
                      .sort(
                        (a: INotification, b: INotification) =>
                          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
                      )
                      .map((notification: INotification, index: number) => (
                        <NotificationMessage
                          key={notification.id}
                          notification={notification}
                          userId={user?.id || ''}
                          companyId={company?.id || ''}
                          index={index}
                        />
                      ))
                  : !isLoading && <Empty />}
              </NotificationsPopup>
            )}
          </TopBarSection>
        </ClickOutsideWrapper>
        <Separator />
        <ClickOutsideWrapper handleClickOutside={() => setIsUserPopupVisible(false)}>
          <TopBarSection onClick={toggleUserPopup}>
            <PersonalInfoWrapper>
              <TopBarSectionLabel>
                {user?.given_name} {user?.family_name}
              </TopBarSectionLabel>
              <CompanyInfoLabel>{company?.displayName}</CompanyInfoLabel>
            </PersonalInfoWrapper>
            <Avatar size="large" icon={<UserOutlined />} />
            {isUserPopupVisible && (
              <UserPopup>
                <UserPopupItem shouldHaveBorder onClick={handleProfileModal}>
                  <div>{t('topBar.profile')}</div>
                  <img src={UserProfile} alt="profile-icon" />
                </UserPopupItem>
                <UserPopupItem onClick={handleLogout}>
                  <div>{t('topBar.logout')}</div>
                  <img src={Logout} alt="logout-icon" />
                </UserPopupItem>
              </UserPopup>
            )}
          </TopBarSection>
        </ClickOutsideWrapper>
      </TopBarContent>
    </TopBarWrapper>
  );
};

export default TopBar;
