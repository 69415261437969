import { createSlice } from '@reduxjs/toolkit';
import { IReservation, IReservationRow, IMake, IVehicle } from '../../../../Models/Models';

const reservationList: IReservation[] = [];
const reservationTable: IReservationRow[] = [];
const makeList: IMake[] = [];
const availableVehicles: IVehicle[] = [];
const selectedVehicle: IVehicle = {} as IVehicle;

export const reservationsSlice = createSlice({
  name: 'reservationList',
  initialState: {
    reservationList,
    reservationTable,
    makeList,
    availableVehicles,
    selectedVehicle
  },
  reducers: {
    addNewReservation: (state, action) => {
      state.reservationList.push(action.payload);
    },
    setReservationTable: (state, action) => {
      state.reservationTable = action.payload;
    },
    setMakeList: (state, action) => {
      state.makeList = action.payload;
    },
    setAvailableVehicles: (state, action) => {
      state.availableVehicles = action.payload;
    },
    setSelectedVehicle: (state, action) => {
      state.selectedVehicle = action.payload;
    }
  }
});

export const { addNewReservation, setReservationTable, setMakeList, setAvailableVehicles, setSelectedVehicle } =
  reservationsSlice.actions;

export default reservationsSlice.reducer;
