import React, { useEffect, useState } from 'react';
import { openNotificationWithIcon } from 'components/Notification/Notification';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Divider,
  Row,
  Col,
  Slider,
  Collapse,
  CollapseProps,
  Select
} from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { DisabledTimes, EventValue } from 'rc-picker/lib/interface';
import { RangeType } from 'rc-picker/lib/RangePicker';
import uuid from 'react-uuid';
import { useAppSelector } from '../../../../../hooks';
import { IDay, IVehicle, IReservation, ReservationTypeEnum } from '../../../../../Models/Models';
import {
  useGenerateS3PresignedUrlDownloadMutation,
  useGenerateS3PresignedUrlMutation,
  useGetCompanyEmployeesQuery,
  useSaveMultiDayReservationsMutation
} from '../../../../../services/autobookerApi';
import { Header, Label, Wrapper } from './ReservationForm.style';
import { DB_DATE_FORMAT, closingTime, convertToTimeString, marks, openingTime } from '../Tools';
import ReservationUserDetails from '../ReservationDay/components/ReservationUserDetails/ReservationUserDetails';
import VehicleDetailsHorizontal from '../VehicleDetails/VehicleDetailsHorizontal';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface IProps {
  vehicle: IVehicle;
  reservationDays: IDay[];
  companyId: string;
  allDay: boolean;
  setOpenMultiDayReservation: (value: boolean) => void;
}

const cancelTokenMap = new Map();

const ReservationForm = ({ vehicle, allDay, reservationDays, companyId, setOpenMultiDayReservation }: IProps) => {
  const { t } = useTranslation();
  const [isAllDay, setAllDay] = useState(allDay);
  const [saveMultiDayReservations, { isLoading: isMultiDayLoading }] = useSaveMultiDayReservationsMutation();
  const { data: companyEmployee } = useGetCompanyEmployeesQuery(null);
  const vehicleMake = useAppSelector((state) => state.reservation.makeList.find((make) => make.id === vehicle.makeId));
  const user = useAppSelector((state) => state.auth.user);
  const [desc, setDesc] = useState('');
  const [additionalDesc, setAdditionalDesc] = useState('');
  const [employeeId, setEmployeeId] = useState(user?.id || '');
  const [reservationType, setReservationType] = useState(ReservationTypeEnum.TestDrive);
  const [reservationPeriod, setReservationPeriod] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [sliderValues, setSliderValues] = useState([openingTime, closingTime]);
  const [generateS3Url, { isLoading: isLoadingS3Presign }] = useGenerateS3PresignedUrlMutation();
  const [generateS3UrlDownload, { isLoading: isLoadingS3PresignDownload }] =
    useGenerateS3PresignedUrlDownloadMutation();
  const [saveDisabled, setSaveDisabled] = useState(false);

  interface DescriptionItemProps {
    title: string;
    content: React.ReactNode;
  }

  const [reservation, setReservation] = useState({
    id: '',
    startTime: isAllDay ? convertToTimeString(openingTime) : convertToTimeString(sliderValues[0]),
    endTime: isAllDay ? convertToTimeString(closingTime) : convertToTimeString(sliderValues[1]),
    allDay: isAllDay,
    reservedByUserId: employeeId || '',
    reservationType: ReservationTypeEnum.TestDrive,
    reservedByDescription: desc,
    additionalDescription: additionalDesc
  });

  const handleSaveButton = async () => {
    let days: IDay[] = [];
    if (!reservationDays) {
      return;
    }

    if (!isAllDay && sliderValues[0] === sliderValues[1]) {
      openNotificationWithIcon('warning', t('common.warning'), t('reservation.noTimeRange'));
      return;
    }

    const connectorId = uuid();

    const overrides = {
      id: '',
      startTime: isAllDay ? convertToTimeString(openingTime) : convertToTimeString(sliderValues[0]),
      endTime: isAllDay ? convertToTimeString(closingTime) : convertToTimeString(sliderValues[1]),
      allDay: isAllDay,
      reservedByUserId: employeeId || '',
      reservedByDescription: desc,
      additionalDescription: additionalDesc,
      reservationType
    };

    if (reservationPeriod && reservationPeriod[0] && reservationPeriod[1]) {
      const startDate = reservationPeriod[0];
      const endDate = reservationPeriod[1];
      const allDays: IDay[] = [];

      let currentDate = startDate;
      while (currentDate.isSame(endDate, 'day') || currentDate.isBefore(endDate, 'day')) {
        const dayTemp: IDay = {
          date: currentDate.format(DB_DATE_FORMAT),
          companyId,
          reservations: []
        };

        allDays.push(dayTemp);
        currentDate = currentDate.add(1, 'day');
      }

      days = allDays.map((day) => {
        const formData = {
          date: day.date,
          companyId,
          vehicleId: vehicle.id,
          vehicleType: vehicle.vehicleType,
          licensePlate: vehicle.licensePlate,
          reservations: [
            {
              ...reservation,
              ...overrides,
              connectorId
            }
          ]
        };
        return formData;
      });
    } else {
      days = reservationDays.map((day) => {
        const formData = {
          date: day.date,
          companyId,
          vehicleId: vehicle.id,
          vehicleType: vehicle.vehicleType,
          licensePlate: vehicle.licensePlate,
          reservations: [
            {
              ...reservation,
              ...overrides,
              connectorId
            }
          ]
        };
        return formData;
      });
    }

    await saveMultiDayReservations(days)
      .then(() => {
        openNotificationWithIcon('success', t('common.success'), t('reservation.reservationSaved'));
        setOpenMultiDayReservation(false);
      })
      .catch(() => {
        openNotificationWithIcon('error', t('common.warning'), t('reservation.timeOverlap'));
      });
  };

  const handleDateRangeChange = (values: any, formatString: [string, string]) => {
    setReservationPeriod(values);
  };

  const handleAdditionalDescChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAdditionalDesc(event.target.value);
  };

  const handleDescChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDesc(event.target.value);
  };

  const formatTooltipText = (value: any) => {
    return `${convertToTimeString(value)}`;
  };

  const handleDisabledTime = (date: EventValue<Dayjs>, type: RangeType): DisabledTimes => {
    const disabledHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21, 22, 23];
    const disabledMinutes = [15, 45];
    return {
      disabledHours: () => disabledHours,
      disabledMinutes: () => disabledMinutes
    };
  };

  const getFromDate = (): dayjs.Dayjs => {
    const dates = reservationDays.map((day) => dayjs(day.date));
    return dates.reduce((minDate, currentDate) => {
      return currentDate.isBefore(minDate) ? currentDate : minDate;
    }, dates[0]);
  };

  const getToDate = (): dayjs.Dayjs => {
    const dates = reservationDays.map((day) => dayjs(day.date));
    return dates.reduce((minDate, currentDate) => {
      return currentDate.isAfter(minDate) ? currentDate : minDate;
    }, dates[0]);
  };

  const handleRangeChange = (value: any) => {
    setSliderValues(value);
  };

  const onFinish = async (values: any) => {
    await handleSaveButton();
  };

  useEffect(() => {
    if (isAllDay) {
      setSliderValues([openingTime, closingTime]);
    }
  }, [isAllDay]);

  // const getS3PresignUrl = async (fileName: string, date: Date): Promise<string> => {
  //   const fileParts = fileName.split('.');
  //   const fileType = fileParts[1];
  //   const res = await generateS3Url({
  //     fileName,
  //     companyId,
  //     date,
  //     fileType: FileTypeEnum.ReservationAttachment,
  //     httpVerb: HttpVerbEnum.PUT
  //   });
  //   if ('data' in res) {
  //     const signedS3Url = res.data as GetPresignedS3UrlResponse;
  //     return signedS3Url.url;
  //   }
  //   throw new Error('Failed to generate S3 presigned URL');
  // };

  // const customRequest = async ({ file, onSuccess, onError, onProgress }: any) => {
  //   setSaveDisabled(true);
  //   try {
  //     const cancelTokenSource = axios.CancelToken.source();
  //     cancelTokenMap.set(file.uid, cancelTokenSource);

  //     let url = '';
  //     let filePath = '';
  //     const res = await generateS3Url({
  //       fileName: file.name,
  //       companyId,
  //       date: getFromDate().toDate(),
  //       fileType: FileTypeEnum.ReservationAttachment,
  //       httpVerb: HttpVerbEnum.PUT
  //     });
  //     if ('data' in res) {
  //       const signedS3Url = res.data as GetPresignedS3UrlResponse;
  //       url = signedS3Url.fullUrl;
  //       filePath = signedS3Url.fields.Key;
  //     }
  //     if (!url) {
  //       throw new Error('Presigned URL is null or undefined');
  //     }
  //     const response = await axios.put(url, file, {
  //       headers: {
  //         'Content-Type': file.type
  //       },
  //       onUploadProgress: (progressEvent) => {
  //         const percentCompleted = progressEvent.total
  //           ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //           : 0;
  //         onProgress({ percent: percentCompleted, url });
  //         file.url = filePath;
  //       },
  //       cancelToken: cancelTokenSource.token
  //     });
  //     onSuccess(response.data, file);
  //   } catch (error) {
  //     onError(error);
  //   }
  //   setSaveDisabled(false);
  // };

  // const onPreview = async (file: any) => {
  //   const res = await generateS3UrlDownload(file.url);
  //   if ('data' in res) {
  //     const signedS3Url = res.data as GetPresignedS3UrlResponse;
  //     if (!signedS3Url.url) {
  //       openNotificationWithIcon('error', t('common.downloadError'), t('reservation.downloadErrorDesc'));
  //     }
  //     window.open(signedS3Url.url);
  //   }
  // };

  // const props: UploadProps = {
  //   customRequest,
  //   onRemove(file) {
  //     const cancelTokenSource = cancelTokenMap.get(file.uid);
  //     if (cancelTokenSource) {
  //       cancelTokenSource.cancel('Upload canceled by user');
  //     }
  //   },
  //   onChange(info) {
  //     if (info.file.status === 'done') {
  //       openNotificationWithIcon('success', t('common.success'), t('reservation.uploadSuccess'));
  //     } else if (info.file.status === 'error') {
  //       openNotificationWithIcon('error', t('common.error'), t('reservation.uploadError'));
  //     }
  //     setReservationAttachments(
  //       info.fileList.map((file) => {
  //         return {
  //           uid: file.uid,
  //           name: file.name,
  //           url: file.url || '',
  //           percent: file.percent,
  //           status: file.status
  //         };
  //       })
  //     );
  //   },
  //   onPreview,
  //   defaultFileList: reservationAttachments.map((attachment) => ({
  //     uid: attachment.uid,
  //     name: attachment.name,
  //     url: attachment.url,
  //     status: attachment.status as UploadFileStatus,
  //     percent: attachment.percent
  //   }))
  // };

  const handleEmployeeChange = (value: any) => {
    setEmployeeId(value);
  };

  const handleSelectReservationTypeChange = (value: ReservationTypeEnum) => {
    setReservationType(value);
  };

  const handleReservationChange = (updatedReservation: IReservation) => {
    setReservation((prevReservation) => ({
      ...prevReservation,
      ...updatedReservation,
      startTime: updatedReservation.startTime || '',
      endTime: updatedReservation.endTime || ''
    }));
  };

  const getEnumOptions = (enumType: any) => {
    return Object.keys(enumType)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => ({
        value: enumType[key],
        label: t(`reservation.${key}`)
      }))
      .sort((a, b) => a.value - b.value);
  };

  const useritem: CollapseProps['items'] = [
    {
      key: '1',
      label: t('reservation.moreDetails'),
      children: (
        <ReservationUserDetails
          reservation={{ ...reservation, connectorId: '' }}
          onChange={handleReservationChange}
          companyId={companyId}
          date={dayjs().toDate()}
        />
      )
    }
  ];

  return (
    <Form
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      style={{ maxWidth: 800 }}
    >
      <VehicleDetailsHorizontal vehicle={vehicle} make={vehicleMake} />
      <Divider />
      <Form.Item label={t('reservationForm.allDay')}>
        <Checkbox checked={isAllDay} defaultChecked={allDay} onChange={(e) => setAllDay(e.target.checked)} />
      </Form.Item>
      <Form.Item label={t('reservationForm.dateRange')}>
        <RangePicker defaultValue={[getFromDate(), getToDate()]} onChange={handleDateRangeChange} />
      </Form.Item>
      {/* <Form.Item label={t('reservationForm.timeRange')}>
        <TimePicker.RangePicker
          disabled={isAllDay}
          format="HH:mm"
          onChange={handleTimeRangeChange}
          minuteStep={30}
          disabledTime={handleDisabledTime}
          defaultValue={[selectedTimeRange[0], selectedTimeRange[1]]}
        />
      </Form.Item> */}
      <Form.Item label={t('reservationForm.timeRange')}>
        <Slider
          disabled={isAllDay}
          tooltip={{ formatter: formatTooltipText }}
          key="time-range-slider"
          range={{ draggableTrack: true }}
          min={openingTime}
          max={closingTime}
          step={50}
          marks={marks}
          onChange={handleRangeChange}
          value={[sliderValues[0], sliderValues[1]]}
          // defaultValue={[openingTime, closingTime]}
        />
      </Form.Item>
      <Form.Item
        label={t('reservationForm.description')}
        rules={[
          {
            required: true,
            message: t('reservation.reservedByDescriptionRequired')
          }
        ]}
        hasFeedback
      >
        <TextArea rows={1} value={desc} onChange={handleDescChange} required />
      </Form.Item>
      <Form.Item label={t('reservationForm.additionalDescription')}>
        <TextArea rows={4} value={additionalDesc} onChange={handleAdditionalDescChange} />
      </Form.Item>
      <Form.Item label={t('reservationForm.employee')}>
        <Select
          showSearch
          placeholder="Select"
          optionFilterProp="label"
          onChange={handleEmployeeChange}
          defaultValue={reservation.reservedByUserId}
          filterSort={(optionA, optionB) =>
            String(optionA?.label ?? '')
              .toLowerCase()
              .localeCompare(String(optionB?.label ?? '').toLowerCase())
          }
          options={companyEmployee?.map((employee: any) => ({
            value: employee.id,
            label: `${employee.firstName} ${employee.lastName}`
          }))}
        />
      </Form.Item>
      <Form.Item label={t('reservationForm.reservationType')}>
        <Select
          showSearch
          placeholder="Reservation Type"
          optionFilterProp="label"
          onChange={handleSelectReservationTypeChange}
          defaultValue={reservation.reservationType}
          filterSort={(optionA, optionB) =>
            String(optionA?.label ?? '')
              .toLowerCase()
              .localeCompare(String(optionB?.label ?? '').toLowerCase())
          }
          options={getEnumOptions(ReservationTypeEnum)}
        />
      </Form.Item>
      <Form.Item label=" ">
        <Collapse ghost items={useritem} />
      </Form.Item>
      {/* <Form.Item label={t('reservation.attachment')}>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>{t('reservation.upload')}</Button>
        </Upload>
      </Form.Item> */}
      <Form.Item label={t('reservationForm.reservation')}>
        <Button
          block
          disabled={saveDisabled}
          icon={isMultiDayLoading ? <LoadingOutlined /> : <SaveOutlined />}
          type="primary"
          htmlType="submit"
        >
          {t('reservationForm.save')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ReservationForm;
