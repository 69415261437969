import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Switch,
  Table,
  Upload,
  Image,
  Progress,
  Row,
  Col,
  Spin
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  FuelTypeEnum,
  IMake,
  IModel,
  IVehicle,
  TransmissionTypeEnum,
  RegistrationPeriodTypeEnum,
  TyreTypeEnum,
  FileTypeEnum,
  GetPresignedS3UrlResponse,
  HttpVerbEnum,
  ISeries
} from 'Models/Models';
import { Color } from 'antd/es/color-picker';
import {
  useDeleteVehicleMutation,
  useGenerateS3PresignedUrlDownloadMutation,
  useGenerateS3PresignedUrlMutation,
  useGetAllModelsQuery,
  useGetAllVehiclesQuery,
  useGetMakeQuery,
  usePostVehicleMutation,
  usePutVehicleMutation
} from 'services/autobookerApi';
import { useAppSelector } from 'hooks';
import { openNotificationWithIcon } from 'components/Notification/Notification';
import dayjs from 'dayjs';
import axios from 'axios';
import { DATE_FORMAT } from '../Dashboard/Reservations/Tools';
import { ReactComponent as Winter } from '../../../theme/icons/winter.svg';
import { ReactComponent as Summer } from '../../../theme/icons/summer.svg';
import { ReactComponent as AllSeason } from '../../../theme/icons/all-season.svg';

const { Option } = Select;
const cancelTokenMap = new Map();

const VehicleTable = () => {
  const { t } = useTranslation();
  const company = useAppSelector((state) => state.auth.company);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [color, setColor] = useState<Color | string | undefined>();
  const { data: vehicleListData, isLoading: vehicleListLoading } = useGetAllVehiclesQuery(`companyId=${company?.id}`);
  const [postVehicle, { data: vehicleData, isLoading: vehicleLoading, error: vehicleError }] = usePostVehicleMutation();
  const [putVehicle, { data: putVehicleData, isLoading: putVehicleLoading }] = usePutVehicleMutation();
  const [deleteVehicle, { data: deleteVehicleData, isLoading: deleteVehicleLoading }] = useDeleteVehicleMutation();
  const [selectedSeries, setSelectedSeries] = useState<ISeries | undefined>(undefined);
  const {
    data: modelListData,
    isLoading: modelListLoading,
    refetch: refetchModels
  } = useGetAllModelsQuery(selectedSeries ? `seriesId=${selectedSeries.id}` : '', {
    refetchOnMountOrArgChange: true
  });
  const [generateS3UrlDownload, { isLoading: isLoadingS3PresignDownload }] =
    useGenerateS3PresignedUrlDownloadMutation();
  const [form] = Form.useForm();
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterVINOptions, setFilterVINOptions] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle | undefined>(undefined);
  const { data: makeListData, isLoading: makeListLoading } = useGetMakeQuery(`companyId=${company?.id}`);
  const [seriesOptions, setSeriesOptions] = useState<ISeries[] | undefined>([]);
  const [fuelTypeOptions, setFuelTypeOptions] = useState<{ label: string; value: number }[]>([]);
  const [transmissionTypeOptions, setTransmissionTypeOptions] = useState<{ label: string; value: number }[]>([]);
  const [registrationTypeOptions, setRegistrationTypeOptions] = useState<{ label: string; value: number }[]>([]);
  const [tyreTypeOptions, setTyreTypeOptions] = useState<{ label: string; value: number }[]>([]);
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);
  const [generateS3Url, { isLoading: isLoadingS3Presign }] = useGenerateS3PresignedUrlMutation();
  const [imageFilePath, setImageFilePath] = useState<string | undefined>(selectedVehicle?.imageUrl);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [percentCompleted, setPercentCompleted] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleEdit = (record: IVehicle) => {
    if (!record) {
      return;
    }
    setSelectedVehicle(record);
    setIsModalVisible(true);
  };

  const handleDelete = (record: IVehicle) => {
    Modal.confirm({
      title: t('reservation.deleteVehicle'),
      onOk: () => {
        const vehicle = { ...record, companyId: company?.id } as IVehicle;
        deleteVehicle(vehicle).then((result) => {
          if ('data' in result) {
            if (result.data) {
              openNotificationWithIcon('success', t('vehicles.vehicleDeleted'), t('vehicles.vehicleDeletedDesc'));
            } else {
              openNotificationWithIcon(
                'error',
                t('vehicles.vehicleDeletedError'),
                t('vehicles.vehicleDeletedErrorDesc')
              );
            }
          }
        });
      }
    });
  };

  useEffect(() => {
    const fuelTypes = Object.keys(FuelTypeEnum)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => ({
        label: key,
        value: FuelTypeEnum[key as keyof typeof FuelTypeEnum] as number
      }));
    const transmissionTypes = Object.keys(TransmissionTypeEnum)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => ({
        label: key,
        value: TransmissionTypeEnum[key as keyof typeof TransmissionTypeEnum] as number
      }));
    const registrationTypes = Object.keys(RegistrationPeriodTypeEnum)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => ({
        label: key,
        value: RegistrationPeriodTypeEnum[key as keyof typeof RegistrationPeriodTypeEnum] as number
      }));
    const tyreTypes = Object.keys(TyreTypeEnum)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => ({
        label: key,
        value: TyreTypeEnum[key as keyof typeof TyreTypeEnum] as number
      }));
    setFuelTypeOptions(fuelTypes);
    setTransmissionTypeOptions(transmissionTypes);
    setRegistrationTypeOptions(registrationTypes);
    setTyreTypeOptions(tyreTypes);
  }, []);

  useEffect(() => {
    if (!selectedVehicle) {
      setColor(undefined);
      return;
    }
    const selectedMake = makeListData?.find((make: IMake) => make.id === selectedVehicle?.makeId);

    if (selectedMake) {
      setSeriesOptions(selectedMake.series ?? []);
    }
    setColor(selectedVehicle.color);
  }, [selectedVehicle]);

  useEffect(() => {
    const ss = seriesOptions?.find((series) => series.id === selectedVehicle?.seriesId);
    setSelectedSeries(ss);
  }, [seriesOptions]);

  useEffect(() => {
    if (selectedVehicle?.makeId !== form.getFieldValue('makeId')) {
      setSelectedSeries(makeListData?.find((make: IMake) => make.id === form.getFieldValue('makeId'))?.series);
      form.setFieldsValue({ seriesId: undefined });
      form.setFieldsValue({ modelId: undefined });
    }
  }, [form.getFieldValue('makeId')]);

  useEffect(() => {
    if (selectedVehicle?.seriesId !== form.getFieldValue('seriesId')) {
      form.setFieldsValue({ modelId: undefined });
    }
  }, [form.getFieldValue('seriesId')]);

  const fetchimageData = async () => {
    if (!imageFilePath) {
      return;
    }
    setImageUrl(undefined);
    try {
      const res = await generateS3UrlDownload({
        filePath: imageFilePath,
        fileType: FileTypeEnum.VehicleImage
      });
      if ('data' in res) {
        const signedS3Url = res.data as GetPresignedS3UrlResponse;
        if (!signedS3Url.fullUrl) {
          openNotificationWithIcon('error', t('common.downloadError'), t('reservation.downloadErrorDesc'));
        }
        setImageUrl(signedS3Url.fullUrl);
      }
    } catch (error) {
      console.error('Error fetching pre-signed URL:', error);
    }
  };

  useEffect(() => {
    if (!imageFilePath) {
      setImageUrl(undefined);
      return;
    }
    fetchimageData();
  }, [imageFilePath]);

  useEffect(() => {
    if (!vehicleListData) {
      return;
    }
    const temp = vehicleListData.map((vehicle: IVehicle) => ({
      text: vehicle.licensePlate,
      value: vehicle.licensePlate
    }));
    const temp2 = vehicleListData.map((vehicle: IVehicle) => ({
      text: vehicle.vin,
      value: vehicle.vin
    }));
    setFilterOptions(temp);
    setFilterVINOptions(temp2);
  }, [vehicleListData]);

  useEffect(() => {
    if (!isModalVisible) {
      setColor(undefined);
      setSelectedVehicle(undefined);
    }
    form.resetFields();
    setImageFilePath(selectedVehicle?.imageUrl);
  }, [isModalVisible]);

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setColor(undefined);
    setIsModalVisible(false);
  };

  const onFinish = async (values: IVehicle) => {
    const vehicle = {
      ...values,
      companyId: company?.id,
      color,
      imageUrl: imageFilePath,
      modelName: modelListData?.find((model: IModel) => model.id === values.modelId)?.name
    } as IVehicle;
    if (values.id && values.id.length > 0) {
      await putVehicle(vehicle)
        .unwrap()
        .then((result) => {
          openNotificationWithIcon('success', t('vehicles.vehicleUpdated'), t('vehicles.vehicleUpdatedDesc'));
          setIsModalVisible(false);
        });
    } else {
      await postVehicle(vehicle)
        .unwrap()
        .then((result) => {
          openNotificationWithIcon('success', t('vehicles.vehicleAdded'), t('vehicles.vehicleAddedDesc'));
          setIsModalVisible(false);
        });
    }
  };

  const columns: ColumnsType<IVehicle> = [
    {
      title: 'Make',
      dataIndex: 'makeId',
      key: 'makeId',
      fixed: 'left',
      render: (text, record) => {
        const selectedMake = makeListData?.find((make: IMake) => record.makeId === make.id);
        if (selectedMake) {
          return (
            <div>
              <p>
                <strong>{selectedMake.name}</strong>
              </p>
              <p>{record.modelName}</p>
            </div>
          );
        }
        return <div />;
      }
    },
    {
      title: 'VIN',
      dataIndex: 'vin',
      key: 'vin',
      fixed: 'left',
      filters: filterVINOptions,
      onFilter: (value: any, record) => record.vin.startsWith(value),
      filterSearch: true
    },
    {
      title: 'License Number',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      width: 100,
      fixed: 'left',
      filters: filterOptions,
      onFilter: (value: any, record) => record.licensePlate.startsWith(value),
      filterSearch: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left'
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month'
    },
    {
      title: 'Mileage',
      dataIndex: 'mileage',
      key: 'mileage'
    },
    {
      title: 'Casco Insurance',
      dataIndex: 'hasCascoInsurance',
      key: 'hasCascoInsurance',
      render: (text, record) => <Switch checked={text} />
    },
    {
      title: 'Start Demo Period',
      dataIndex: 'startDemoPeriod',
      key: 'startDemoPeriod',
      render: (text, record) => <p>{dayjs(record.startDemoPeriod).format(DATE_FORMAT)}</p>
    },
    {
      title: 'End Demo Date',
      dataIndex: 'endDemoPeriod',
      key: 'endDemoPeriod',
      render: (text, record) => <p>{dayjs(record.endDemoPeriod).format(DATE_FORMAT)}</p>
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      width: 70,
      render: (value: string) => <ColorPicker disabled value={value} />
    },
    {
      title: 'Tyre',
      dataIndex: 'tyreType',
      key: 'tyreType',
      render: (text, record) => {
        const tyre = record.tyreType;
        return (
          <div style={{ textAlign: 'center' }}>
            {tyre === TyreTypeEnum.AllSeason && <AllSeason style={{ width: 30, height: 30 }} />}
            {tyre === TyreTypeEnum.Summer && <Summer style={{ width: 30, height: 30 }} />}
            {tyre === TyreTypeEnum.Winter && <Winter style={{ width: 30, height: 30 }} />}
          </div>
        );
      }
    },
    {
      title: 'Transmission',
      dataIndex: 'transmission',
      key: 'transmission',
      render: (text, record) => {
        const transmission = record && record.transmission === TransmissionTypeEnum.Automatic ? 'A' : 'M';
        return (
          <div style={{ textAlign: 'center' }}>
            <h3>{transmission}</h3>
          </div>
        );
      }
    },
    {
      title: 'Is Sold',
      dataIndex: 'isSold',
      key: 'isSold',
      render: (text, record) => <Switch checked={text} />
    },
    {
      title: 'Sold Date',
      dataIndex: 'sold',
      key: 'sold',
      render: (text, record) => <p>{dayjs(record.sold).format(DATE_FORMAT)}</p>
    },
    {
      title: 'Registered',
      dataIndex: 'registered',
      key: 'registered',
      width: 120,
      render: (text, record) => <p>{dayjs(record.registered).format(DATE_FORMAT)}</p>
    },
    {
      title: 'Registration Period',
      dataIndex: 'registrationPeriod',
      key: 'registrationPeriod'
    },
    {
      title: 'Registration Period Type',
      dataIndex: 'registrationPeriodType',
      key: 'registrationPeriodType',
      render: (text, record) => <p>{RegistrationPeriodTypeEnum[record.registrationPeriodType]}</p>
    },
    {
      title: 'Fuel Type',
      dataIndex: 'fuelType',
      key: 'fuelType',
      render: (text, record) => <p>{FuelTypeEnum[record.fuelType]}</p>
    },
    {
      title: 'Engine Size',
      dataIndex: 'engineSize',
      key: 'engineSize',
      width: 100
    },
    {
      title: 'Doors',
      dataIndex: 'doors',
      key: 'doors'
    },
    {
      title: 'Seats',
      dataIndex: 'seats',
      key: 'seats'
    },
    {
      title: 'Power',
      dataIndex: 'power',
      key: 'power'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Ownership',
      dataIndex: 'ownership',
      key: 'ownership'
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 160,
      render: (text, record) => (
        <Space size="small">
          <Button type="primary" onClick={() => handleEdit(record)}>
            {t('common.edit')}
          </Button>
          <Button type="default" onClick={() => handleDelete(record)}>
            {t('common.delete')}
          </Button>
        </Space>
      )
    }
  ];

  const handleChangeColor = (value: Color, hex: string) => {
    setColor(hex);
  };

  const handleMakeChange = (selectedMakeId: any) => {
    const selectedMake = makeListData.find((make: IMake) => make.id === selectedMakeId);
    if (selectedMake) {
      setSeriesOptions(selectedMake.series ?? []);
    } else {
      setSeriesOptions([]);
    }
  };

  const handleSeriesChange = (selectedSeriesId: any) => {
    const selected = (seriesOptions ?? []).find((series) => series.id === selectedSeriesId);
    setSelectedSeries(selected);
  };

  const uploadButton = (
    <div>
      {loadingUploadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 1 }}>Upload</div>
    </div>
  );

  const customRequest = async ({ file, onSuccess, onError, onProgress, onRemove }: any) => {
    try {
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenMap.set(file.uid, cancelTokenSource);

      let url = '';
      let fullUrl = '';
      let filePath = '';
      const res = await generateS3Url({
        fileName: file.name,
        companyId: company?.id ?? 'Undefined',
        reservationId: 'Undefined',
        date: dayjs().toDate(),
        vehicleId: selectedVehicle?.id ?? 'Undefined',
        fileType: FileTypeEnum.VehicleImage,
        httpVerb: HttpVerbEnum.PUT
      });
      if ('data' in res) {
        const signedS3Url = res.data as GetPresignedS3UrlResponse;
        fullUrl = signedS3Url.fullUrl;
        url = signedS3Url.url;
        filePath = signedS3Url.fields.Key;
      }
      if (!url) {
        throw new Error('Presigned URL is null or undefined');
      }
      const response = await axios.put(fullUrl, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: (progressEvent) => {
          const completedUpload = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          onProgress({ percent: completedUpload, url });
          setPercentCompleted(completedUpload);
          file.url = filePath;
        },
        cancelToken: cancelTokenSource.token
      });
      setImageFilePath(filePath);
      onSuccess(response.data, file);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Row>
      <Col span={24} order={1} style={{ padding: 20 }}>
        <Card
          style={{ maxWidth: 1750, margin: '0 auto' }}
          extra={
            <Button style={{ margin: 10 }} type="primary" icon={<PlusOutlined />} onClick={showModal}>
              {t('vehicles.addNewVehicle')}
            </Button>
          }
        >
          <Table
            columns={columns}
            dataSource={
              vehicleListData && vehicleListData.map((vehicle: IVehicle) => ({ ...vehicle, key: vehicle.id }))
            }
            loading={{ spinning: vehicleListLoading || makeListLoading, indicator: <LoadingOutlined /> }}
            bordered
            scroll={{ x: 3000 }}
          />
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={onFinish}
            style={{ maxWidth: 1200 }}
            initialValues={
              selectedVehicle && selectedVehicle !== undefined
                ? {
                    ...selectedVehicle,
                    sold: selectedVehicle?.sold ? dayjs(selectedVehicle.sold) : null,
                    registered: selectedVehicle?.registered ? dayjs(selectedVehicle.registered) : null,
                    startDemoPeriod: selectedVehicle?.startDemoPeriod ? dayjs(selectedVehicle.startDemoPeriod) : null,
                    endDemoPeriod: selectedVehicle?.endDemoPeriod ? dayjs(selectedVehicle.endDemoPeriod) : null,
                    imageUrl: imageFilePath
                  }
                : {
                    registrationPeriod: 1,
                    color: undefined
                  }
            }
          >
            <Modal
              title={t('vehicles.addNewVehicle')}
              open={isModalVisible}
              confirmLoading={vehicleLoading || putVehicleLoading}
              onOk={handleOk}
              onCancel={handleCancel}
              width={800}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  {t('common.cancel')}
                </Button>,
                <Button key="submit" type="primary" loading={putVehicleLoading} onClick={handleOk}>
                  {t('common.save')}
                </Button>
              ]}
            >
              <Form.Item label="ID" name="id">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Make" name="makeId" rules={[{ required: true }]}>
                <Select onChange={handleMakeChange}>
                  {makeListData &&
                    makeListData.map((make: IMake) => (
                      <Option key={make.id} value={make.id}>
                        {make.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Series" name="seriesId" rules={[{ required: true }]}>
                <Select onChange={handleSeriesChange}>
                  {seriesOptions &&
                    seriesOptions.map((series: ISeries) => (
                      <Option key={series.id} value={series.id}>
                        {series.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Model" name="modelId" rules={[{ required: true }]}>
                <Select>
                  {modelListLoading && <LoadingOutlined />}
                  {modelListData &&
                    modelListData.map((model: IModel) => (
                      <Select.Option key={model.id} value={model.id}>
                        {`${model.code} - ${model.name}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="VIN (Vehicle Ident. No)" name="vin" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label="License Plate" name="licensePlate" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Fuel Type" name="fuelType" rules={[{ required: true }]}>
                <Select style={{ width: '30%', marginLeft: 10 }}>
                  {fuelTypeOptions &&
                    fuelTypeOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Transmission" name="transmission" rules={[{ required: true }]}>
                <Select style={{ width: '30%', marginLeft: 10 }}>
                  {transmissionTypeOptions &&
                    transmissionTypeOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Tyre Type" name="tyreType" rules={[{ required: true }]}>
                <Select style={{ width: '30%', marginLeft: 10 }}>
                  {tyreTypeOptions &&
                    tyreTypeOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Image" name="imageUrl">
                <div>
                  <div>
                    {imageUrl ? (
                      <Image
                        src={imageUrl}
                        style={{ width: '100%', height: 'auto', maxWidth: '500px' }}
                        alt="Vehicle"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div style={{ margin: 10 }}>
                    <Upload name="avatar" showUploadList={false} customRequest={customRequest}>
                      <Button icon={<UploadOutlined />}>
                        {t('common.upload')} {t('common.image')}
                      </Button>
                    </Upload>
                    <Button onClick={() => setImageFilePath(undefined)} style={{ marginLeft: 20 }}>
                      {t('common.delete')}
                    </Button>
                    {percentCompleted === 0 || percentCompleted === 100 ? '' : <Progress percent={percentCompleted} />}
                  </div>
                </div>
              </Form.Item>
              <Form.Item label="Power" name="power">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Engine Size" name="engineSize">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Year" name="year">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Month" name="month">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Mileage" name="mileage">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Color" name="color">
                <ColorPicker showText onChange={handleChangeColor} />
              </Form.Item>
              <Form.Item label="Registered" name="registered">
                <DatePicker />
              </Form.Item>
              <Form.Item label="Reg. Period Type" name="registrationPeriodType">
                <Select style={{ width: '30%', marginLeft: 10 }}>
                  {registrationTypeOptions &&
                    registrationTypeOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Reg. Period" name="registrationPeriod">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Start Demo Period" name="startDemoPeriod">
                <DatePicker />
              </Form.Item>
              <Form.Item label="End Demo Period" name="endDemoPeriod">
                <DatePicker />
              </Form.Item>
              <Form.Item label="Doors" name="doors">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Seats" name="seats">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea />
              </Form.Item>
              <Form.Item label="Casco Insurance" name="hasCascoInsurance" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item label="Price" name="price">
                <InputNumber />
              </Form.Item>
              <Form.Item label="Ownership" name="ownership">
                <Input />
              </Form.Item>
              <Form.Item label="Is Sold" name="isSold" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item label="Sold" name="sold">
                <DatePicker />
              </Form.Item>
            </Modal>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default VehicleTable;
