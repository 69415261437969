import { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Svg, Image, Font } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';
import dayjs from 'dayjs';
import { IDocProps } from './Utils';
import { SideFooter } from './SideFooter';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const logoImage = require('theme/v2/memorandum.png');

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontSize: 12,
    fontFamily: 'Roboto'
  },
  header: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 20,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  article: {
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  logo: {
    width: '100%',
    height: '100%'
  },
  footer: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 12
  },
  content: {
    paddingLeft: 90,
    paddingRight: 30,
    paddingBottom: 20,
    paddingTop: 10,
    flexDirection: 'column',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  section: {
    flexDirection: 'column',
    marginBottom: 10
  },
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
    textDecoration: 'underline'
  },
  headingInfo: {
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: 'right'
  },
  text: {
    fontSize: 12,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  boldText: {
    fontWeight: 'bold'
  },
  underlinedText: {
    textDecoration: 'underline'
  },
  signature: {
    marginTop: 20
  },
  signatureBottom: {
    bottom: 50,
    width: 120,
    flexWrap: 'wrap',
    right: 15,
    fontSize: 12,
    position: 'absolute'
  }
});

const Signature = () => (
  <View style={styles.signatureBottom}>
    <Text style={styles.text}>Delta Motors d.o.o.</Text>
    <Text> </Text>
    <Text> </Text>
    <Text> </Text>
    <Text style={styles.text}>____________________</Text>
  </View>
);

const toUpperCase = (str: string | undefined) => {
  if (!str) {
    return '';
  }
  return str.toUpperCase();
};

export const AuthorizationPages = ({ reservation, vehicle, title, make, model, connectedData }: IDocProps) => (
  <Page style={styles.page}>
    <View style={styles.header}>
      <Image style={styles.logo} src={logoImage} />
    </View>
    <View style={styles.content}>
      <View style={styles.headingInfo}>
        <Text style={styles.text}>Beograd, {dayjs().format('DD.MM.YYYY')} god.</Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}> </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>O V L A Š Ć E NJ E</Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}> </Text>
        <Text style={styles.text}>
          Kojim se ovlašćuje:
          <Text style={styles.boldText}>
            {reservation.firstName && reservation.lastName
              ? ` ${toUpperCase(reservation.firstName)} ${toUpperCase(reservation.lastName)} `
              : ' ____________ '}
          </Text>
          sa br.l.k.
          {reservation.documentNumber ? (
            <Text style={styles.boldText}>{` ${reservation.documentNumber} `}</Text>
          ) : (
            ' ____________ '
          )}
          da može
        </Text>
        <Text style={styles.text}>
          upravljati u zemlji vozilom marke:
          {vehicle.makeId || vehicle.modelId ? (
            <Text style={styles.boldText}>{` ${make && make.name} ${model && model.name} ${vehicle.name}`}</Text>
          ) : (
            ' ______________________ '
          )}
          ,
        </Text>
        <Text style={styles.text}>
          registarski broj:
          {vehicle.licensePlate ? <Text style={styles.boldText}>{` ${vehicle.licensePlate}`}</Text> : ' ____________ '},
        </Text>
        <Text style={styles.text}>
          br.šasije:
          {vehicle.vin ? <Text style={styles.boldText}>{` ${vehicle.vin}`}</Text> : ' ____________ '},
        </Text>
        <Text style={styles.text}>
          koje je vlasništvo preduzeća &quot;Delta Motors&quot; d.o.o., Radnička 8, 11030 Beograd.
        </Text>
        <Text style={styles.text}>
          Ovlašćenje je sastavni deo ugovora o korišćenju vozila br.
          {reservation.contractNumber ? (
            <Text style={styles.boldText}>{` ${reservation.contractNumber}`}</Text>
          ) : (
            ' ____________ '
          )}
          .
        </Text>
        <Text style={styles.text}>Ovlašćenje ističe {dayjs(connectedData.endDate).format('DD.MM.YYYY')}</Text>
        <Text style={styles.text}> </Text>
      </View>
    </View>
    <SideFooter />
    <Signature />
  </Page>
);

const Authorization = ({ reservation, vehicle, title, make, model, connectedData }: IDocProps) => {
  return (
    <Document title={title}>
      <AuthorizationPages
        reservation={reservation}
        vehicle={vehicle}
        title={title}
        make={make}
        model={model}
        connectedData={connectedData}
      />
    </Document>
  );
};

export default Authorization;
