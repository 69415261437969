import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { g } from 'settings';
import { Auth } from 'aws-amplify';
import { openNotificationWithIcon } from 'components/Notification/Notification';
import {
  CheckQRIdentifierRequest,
  CheckQRIdentifierResponse,
  GetPresignedS3UrlResponse,
  IDay,
  IGetPresignedS3UrlDownloadRequest,
  IGetPresignedS3UrlRequest,
  IGetQRCodeUrlRequest,
  IGetQRCodeUrlResponse,
  IMake,
  IMessage,
  IModel,
  IOpenAIReservation,
  IOpenAIResponse,
  IVehicle
} from '../Models/Models';

export const autobookerApi = createApi({
  reducerPath: 'autobookerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: g('BACKEND_API_URL'),
    responseHandler: async (response) => {
      if (response.status === 403) {
        openNotificationWithIcon('error', 'Access Denied', 'You do not have permission to perform this action.');
      }
      return response.json();
    },
    prepareHeaders: async (headers) => {
      const token = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    }
  }),
  tagTypes: ['Reservations', 'Vehicle', 'Make', 'OpenAIReservations', 'Model', 'Notifications'],
  endpoints: (builder) => ({
    getReservations: builder.query({
      query: (historyPeriod) => {
        const { fromDate, toDate, licensePlate, vehicleType } = historyPeriod;
        return `/GetReservations?FromDate=${fromDate}&ToDate=${toDate}&LicensePlate=${licensePlate}&vehicleType=${vehicleType}`;
      },
      providesTags: []
    }),
    saveReservations: builder.mutation<IDay, IDay>({
      query: (day) => ({
        url: '/PostReservations',
        method: 'POST',
        body: day
      }),
      invalidatesTags: ['Reservations']
    }),
    saveMultiDayReservations: builder.mutation<IDay[], IDay[]>({
      query: (days) => ({
        url: '/PostMultiDayReservations',
        method: 'POST',
        body: days
      }),
      invalidatesTags: ['Reservations']
    }),
    createOpenAIReservation: builder.mutation<IOpenAIResponse, IOpenAIReservation>({
      query: (request) => ({
        url: '/PostOpenAIReservation',
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['OpenAIReservations']
    }),
    chatWithOpenAI: builder.mutation<IMessage, IMessage[]>({
      query: (request) => ({
        url: '/PostChatOpenAI',
        method: 'POST',
        body: request
      }),
      invalidatesTags: ['OpenAIReservations', 'Reservations']
    }),
    getReservationTable: builder.query({
      query: (params) => `/GetReservationTable?${params}`,
      providesTags: ['Reservations']
    }),
    getAllVehicles: builder.query({
      query: (params) => `/GetAllVehicles?${params}`,
      providesTags: ['Vehicle', 'Reservations', 'Notifications']
    }),
    postVehicle: builder.mutation<IVehicle, IVehicle>({
      query: (vehicle) => ({
        url: '/PostVehicle',
        method: 'POST',
        body: vehicle
      }),
      invalidatesTags: ['Vehicle', 'Reservations']
    }),
    putVehicle: builder.mutation<IVehicle, IVehicle>({
      query: (vehicle) => ({
        url: '/PutVehicle',
        method: 'PUT',
        body: vehicle
      }),
      invalidatesTags: ['Vehicle', 'Reservations']
    }),
    deleteVehicle: builder.mutation<boolean, IVehicle>({
      query: (vehicle) => ({
        url: '/DeleteVehicle',
        method: 'DELETE',
        body: vehicle
      }),
      invalidatesTags: ['Vehicle', 'Reservations']
    }),
    getMake: builder.query({
      query: (params) => `/GetMake?${params}`,
      providesTags: ['Vehicle', 'Reservations', 'Make'],
      transformResponse: (response: any) => {
        if (response.error && response.error.status === 403) {
          console.error('Access Denied - Handle 403 response');
        }
        return response;
      }
    }),
    postMake: builder.mutation<IMake, IMake>({
      query: (make) => ({
        url: '/PostMake',
        method: 'POST',
        body: make
      }),
      invalidatesTags: ['Vehicle', 'Reservations']
    }),
    putMake: builder.mutation<IMake, IMake>({
      query: (make) => ({
        url: '/PutMake',
        method: 'PUT',
        body: make
      }),
      invalidatesTags: ['Make', 'Vehicle', 'Reservations']
    }),
    deleteMake: builder.mutation<boolean, IMake>({
      query: (make) => ({
        url: '/DeleteMake',
        method: 'DELETE',
        body: make
      }),
      invalidatesTags: ['Vehicle', 'Make', 'Reservations']
    }),
    getAllModels: builder.query({
      query: (params) => `/GetAllModels?${params}`,
      providesTags: ['Model']
    }),
    postModel: builder.mutation<IModel, IModel>({
      query: (model) => ({
        url: '/PostModel',
        method: 'POST',
        body: model
      }),
      invalidatesTags: ['Model']
    }),
    putModel: builder.mutation<IModel, IModel>({
      query: (model) => ({
        url: '/PutModel',
        method: 'PUT',
        body: model
      }),
      invalidatesTags: ['Model']
    }),
    deleteModel: builder.mutation<boolean, IModel>({
      query: (model) => ({
        url: '/DeleteModel',
        method: 'DELETE',
        body: model
      }),
      invalidatesTags: ['Model']
    }),
    generateS3PresignedUrl: builder.mutation<GetPresignedS3UrlResponse, IGetPresignedS3UrlRequest>({
      query: (params) => ({
        url: '/GetPresignS3',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['Reservations']
    }),
    generateS3PresignedUrlDownload: builder.mutation<GetPresignedS3UrlResponse, IGetPresignedS3UrlDownloadRequest>({
      query: (params) => ({
        url: '/GetPresignS3Download',
        method: 'POST',
        body: params
      })
    }),
    getCompany: builder.query({
      query: (id) => `/GetCompany?id=${id}`
    }),
    getCompanyEmployees: builder.query({
      query: () => `/GetCompanyEmployees`
    }),
    generateQRCodeUrl: builder.mutation<IGetQRCodeUrlResponse, IGetQRCodeUrlRequest>({
      query: (params) => ({
        url: '/GenerateQRCodeUrl',
        method: 'POST',
        body: params
      })
    }),
    checkQRIdentifier: builder.mutation<CheckQRIdentifierResponse, CheckQRIdentifierRequest>({
      query: (params) => ({
        url: '/CheckQRIdentifier',
        method: 'POST',
        body: params
      })
    }),
    getConnectedData: builder.query({
      query: (query) => `/GetConnectedData?${query}`
    }),
    markAsRead: builder.mutation<boolean, { id: string; userId: string; companyId: string }>({
      query: (params) => ({
        url: '/Notifications/MarkAsRead',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['Notifications']
    }),
    getNotifications: builder.query({
      query: (query) => `/GetNotifications?${query}`,
      providesTags: ['Notifications']
    })
  })
});

export const {
  useGetReservationsQuery,
  useGetReservationTableQuery,
  useSaveReservationsMutation,
  useSaveMultiDayReservationsMutation,
  useGetAllVehiclesQuery,
  usePostVehicleMutation,
  usePutVehicleMutation,
  useDeleteVehicleMutation,
  useGetMakeQuery,
  useCreateOpenAIReservationMutation,
  useChatWithOpenAIMutation,
  usePostMakeMutation,
  usePutMakeMutation,
  useDeleteMakeMutation,
  useGenerateS3PresignedUrlMutation,
  useGenerateS3PresignedUrlDownloadMutation,
  useGetCompanyQuery,
  useGenerateQRCodeUrlMutation,
  useCheckQRIdentifierMutation,
  useGetCompanyEmployeesQuery,
  useGetConnectedDataQuery,
  useGetAllModelsQuery,
  usePostModelMutation,
  usePutModelMutation,
  useDeleteModelMutation,
  useMarkAsReadMutation,
  useGetNotificationsQuery
} = autobookerApi;
