import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useGetMakeQuery } from 'services/autobookerApi';
import { Popover } from 'antd';
import { IMake, IModel, ISeries, IVehicle } from 'Models/Models';
import { AvailableVehiclesWrapper, HoverableAvatar, StyledVehicleContainer } from './AvailableVehicles.style';
import { LogoRenderer } from '../Tools';
import { Logo } from '../ReservationTable.style';
import { setSelectedVehicle } from '../reservationsSlice';
import HoverContent from './components/HoverContent';

interface IProps {
  vehicles: IVehicle[];
}

const AvailableVehicles = ({ vehicles }: IProps) => {
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.auth.company);
  const { data: makes } = useGetMakeQuery(`companyId=${company?.id}`, {
    skip: !company || !company.id === undefined
  });
  const { t } = useTranslation();
  const thumbnailUrl = process.env.REACT_APP_AUTOBOOKER_IMAGES_THUMBNAIL;
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleVehicleClick = (vehicle: IVehicle) => {
    dispatch(setSelectedVehicle(vehicle));
  };
  const GetTypeFromSeries = (ser: ISeries | undefined): number => {
    if (!ser) return 0;
    switch (ser.vehicleType) {
      case 0: // car
        return ser.carType;
      case 1: // bike
        return ser.carType + 100;
      default:
        return 0;
    }
  };

  return (
    <>
      <p>{t('topBar.todayAvailable')}</p>
      <AvailableVehiclesWrapper>
        {vehicles.map((vehicle, index) => {
          const vehicleMake = makes.find((make: IMake) => make.id === vehicle.makeId);
          const serie = vehicleMake?.series?.find((series: ISeries) => series.id === vehicle.seriesId);
          const carType = GetTypeFromSeries(serie);
          const avatarSrc = vehicle.imageUrl ? `${thumbnailUrl}/${vehicle.imageUrl}` : `assets/images/${carType}.jpg`;
          return (
            <div key={`${vehicle.id}-hov-avatar`} onClick={() => handleVehicleClick(vehicle)}>
              <Popover
                key={`${vehicle.id}-hov-popover`}
                style={{ width: 500 }}
                content={
                  <HoverContent vehicle={vehicle} make={makes.find((make: IMake) => make.id === vehicle.makeId)} />
                }
                title={vehicle.licensePlate}
                trigger="hover"
                open={hoveredIndex === index}
                onOpenChange={(visible) => setHoveredIndex(visible ? index : null)}
              >
                <div style={{ zIndex: 1 }}>
                  {makes && makes.length > 0 && (
                    <Logo>
                      {vehicle.makeId && makes.find((make: IMake) => make.id === vehicle.makeId) && (
                        <LogoRenderer
                          width="15px"
                          height="15px"
                          svgString={makes.find((make: IMake) => make.id === vehicle.makeId)?.logo}
                        />
                      )}
                    </Logo>
                  )}
                </div>
                <div className="vehicle-container">
                  <HoverableAvatar key={`${avatarSrc}-hov-avatar`} shape="square" size="large" src={avatarSrc} />
                  <StyledVehicleContainer>
                    <p>{vehicle.name}</p>
                  </StyledVehicleContainer>
                </div>
              </Popover>
            </div>
          );
        })}
      </AvailableVehiclesWrapper>
    </>
  );
};

export default AvailableVehicles;
