import React, { useTransition } from 'react';
import { IMake, IVehicle } from 'Models/Models';
import { Card, Progress, Table, Badge, Row, Col, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetAllVehiclesQuery, useGetMakeQuery } from 'services/autobookerApi';
import dayjs from 'dayjs';
import { useAppSelector } from 'hooks';
import { GlobalOutlined } from '@ant-design/icons';
import {
  DATE_FORMAT,
  REGISTRATION_WARNING_LOW,
  calculateDaysLeftForRegistrationRenewal
} from '../Dashboard/Reservations/Tools';
import VehicleMap, { IMapVehicle } from './components/VehicleMap';

type RegistrationStatusCode = 'success' | 'processing' | 'error' | 'default' | 'warning' | undefined;

const VehicleStats = () => {
  const company = useAppSelector((state) => state.auth.company);
  const { data: vehicleListData, isLoading: vehicleListLoading } = useGetAllVehiclesQuery(`companyId=${company?.id}`);
  const { data: makeListData, isLoading: makeListLoading } = useGetMakeQuery(`companyId=${company?.id}`);
  const [vehicleMapList, setVehicleMapList] = React.useState<IMapVehicle[]>([]);
  const [tempVehicleMapList, setTempVehicleMapList] = React.useState<IMapVehicle[]>([]);
  const { t } = useTranslation();

  const getUniqueValues = (data: IVehicle[] | undefined, key: keyof IVehicle) => {
    if (!data) return [];
    const uniqueValues = new Set<string>();
    data.forEach((item) => {
      if (item[key]) {
        uniqueValues.add(item[key] as unknown as string);
      }
    });
    return Array.from(uniqueValues).map((value) => ({ text: value, value }));
  };

  const columns = [
    {
      title: '',
      dataIndex: 'registrationStatus',
      key: 'registrationStatus',
      render: (status: RegistrationStatusCode) =>
        status === 'processing' ? <Badge style={{ marginRight: 5 }} status={status} color="red" /> : ''
    },
    {
      title: `${t('vehicleStats.make')}`,
      dataIndex: 'makeName',
      key: 'makeName'
    },
    {
      title: `${t('vehicleStats.vehicleName')}`,
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name)
    },
    {
      title: `${t('vehicleStats.licensePlate')}`,
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      sorter: (a: any, b: any) => a.licensePlate.localeCompare(b.licensePlate),
      filterSearch: true,
      filters: getUniqueValues(vehicleListData, 'licensePlate'),
      onFilter: (value: string | number | boolean | React.Key, record: any) => {
        return typeof value === 'string' || typeof value === 'number' ? record.licensePlate.includes(value) : false;
      }
    },
    // {
    //   title: `${t('vehicleStats.registrationPeriod')}`,
    //   dataIndex: 'registrationPeriodInDays',
    //   key: 'registrationPeriodInDays'
    // },
    {
      title: `${t('vehicleStats.daysLeft')}`,
      dataIndex: 'daysLeft',
      key: 'daysLeft'
    },
    {
      title: `${t('vehicleStats.daysLeftInRatio')}`,
      dataIndex: 'daysLeftRatio',
      key: 'daysLeftRatio',
      width: '50%',
      render: (daysLeft: number) => (
        <Progress
          percent={daysLeft}
          showInfo={false}
          status={daysLeft < REGISTRATION_WARNING_LOW ? 'exception' : 'normal'}
        />
      )
    },
    {
      title: `${t('vehicleStats.endDemoPeriod')}`,
      dataIndex: 'endDemoPeriod',
      key: 'endDemoPeriod',
      render: (endDemoPeriod: string) => <p>{endDemoPeriod ? dayjs(endDemoPeriod).format(DATE_FORMAT) : '/'}</p>
    },
    {
      title: `${t('vehicleStats.demoDaysLeft')}`,
      dataIndex: 'demoPeriodInDays',
      key: 'demoPeriodInDays'
    },
    {
      title: `${t('vehicleStats.daysLeftInRatio')}`,
      dataIndex: 'daysLeftForDemoPeriod',
      key: 'daysLeftForDemoPeriod',
      width: '80%',
      render: (daysLeftForDemoPeriod: number) => (
        <Progress
          percent={daysLeftForDemoPeriod}
          showInfo={false}
          status={daysLeftForDemoPeriod < REGISTRATION_WARNING_LOW ? 'exception' : 'normal'}
        />
      )
    }
  ];

  const getRegistrationStatusCode = (difference: number): RegistrationStatusCode => {
    if (difference < 0) {
      return 'processing';
    }
    return 'success';
  };

  const calculateDaysLeftForDemoPeriod = (
    vehicle: IVehicle
  ): { daysLeftForDemoPeriod: number; demoPeriodInDays: number } => {
    if (!vehicle.endDemoPeriod || !vehicle.startDemoPeriod) {
      return { daysLeftForDemoPeriod: 0, demoPeriodInDays: 0 };
    }
    const currentDate = new Date();
    const startDate = new Date(vehicle.startDemoPeriod);
    const endDate = new Date(vehicle.endDemoPeriod);
    const difference = endDate.getTime() - currentDate.getTime();
    const daysLeftForDemoPeriod = Math.ceil(difference / (1000 * 3600 * 24));
    const demoPeriodInDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
    return {
      daysLeftForDemoPeriod: daysLeftForDemoPeriod > 0 ? daysLeftForDemoPeriod : 0,
      demoPeriodInDays
    };
  };

  const dataSource = vehicleListData?.map((vehicle: IVehicle) => {
    const { daysLeftForRegistrationRenewal, regPeriodInDays } = calculateDaysLeftForRegistrationRenewal(vehicle);
    const { daysLeftForDemoPeriod, demoPeriodInDays } = calculateDaysLeftForDemoPeriod(vehicle);
    return {
      key: vehicle.id,
      registrationStatus: getRegistrationStatusCode(regPeriodInDays - daysLeftForRegistrationRenewal),
      name: vehicle.name,
      licensePlate: vehicle.licensePlate,
      makeName: makeListData?.find((make: IMake) => make.id === vehicle.makeId)?.name,
      // registrationPeriodInDays: regPeriodInDays,
      daysLeft: regPeriodInDays - daysLeftForRegistrationRenewal,
      daysLeftRatio: ((regPeriodInDays - daysLeftForRegistrationRenewal) / regPeriodInDays) * 100,
      endDemoPeriod: vehicle.endDemoPeriod,
      daysLeftForDemoPeriod,
      demoPeriodInDays
    };
  });

  const generateRandomLatitude = () => {
    const minLatitude = 42.2326;
    const maxLatitude = 46.1817;
    const randomLatitude = Math.random() * (maxLatitude - minLatitude) + minLatitude;

    return randomLatitude.toFixed(6);
  };

  const generateRandomLongitude = () => {
    const minLongitude = 18.8306;
    const maxLongitude = 22.9892;

    const randomLongitude = Math.random() * (maxLongitude - minLongitude) + minLongitude;
    return randomLongitude.toFixed(6);
  };

  const onSelectRow = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IVehicle[]) => {
      const vMapList: IMapVehicle[] = [];

      selectedRows.forEach((vehicle: IVehicle) => {
        vMapList.push({
          id: vehicle.id,
          vehicle,
          latitude: parseFloat(generateRandomLatitude()),
          longitude: parseFloat(generateRandomLongitude())
        });
      });

      setTempVehicleMapList(vMapList);
    }
  };

  const showOnMap = () => {
    setVehicleMapList(tempVehicleMapList.map((vehicle: IMapVehicle) => vehicle));
  };

  return (
    <Row>
      <Col span={24} order={1}>
        <Card
          title={t('vehicleStats.vehicleStats')}
          style={{ margin: 20 }}
          extra={
            <Space>
              <Button
                type="primary"
                placeholder={t('vehicleStats.showOnMap')}
                onClick={showOnMap}
                icon={<GlobalOutlined />}
              >
                {t('vehicleStats.showOnMap')}
              </Button>
            </Space>
          }
        >
          <Table
            loading={vehicleListLoading || makeListLoading}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            // rowSelection={onSelectRow}
          />
        </Card>
      </Col>
      {/* <Col span={8} order={2}>
        <Card title={t('vehicleStats.vehiclePosition')} style={{ margin: 20, height: '90%', width: '90%' }}>
          <VehicleMap vehicles={vehicleMapList} />
        </Card>
      </Col> */}
    </Row>
  );
};

export default VehicleStats;
