import { useEffect } from 'react';
import { INotification } from 'Models/Models';
import { useMarkAsReadMutation } from 'services/autobookerApi';
import { Alert, Badge, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import {
  NotificationContent,
  NotificationIcon,
  NotificationItem,
  NotificationText,
  NotificationTime,
  NotificationTitle,
  TitleAndIconContainer
} from './NotificationMessage.style';

interface NotificationMessageProps {
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
}

interface IProps {
  notification: INotification;
  userId: string;
  companyId: string;
  index: number;
}

const NotificationMessage = ({ notification, userId, companyId, index }: IProps) => {
  const { t } = useTranslation();
  const [postMarkAsRead] = useMarkAsReadMutation();

  const onMarkAsRead = async (id: string) => {
    await postMarkAsRead({ id, userId, companyId });
  };

  const getIconByType = (n: INotification) => {
    switch (n.type) {
      case 'info':
        return <InfoCircleTwoTone />;
      case 'warning':
        return <WarningTwoTone />;
      default:
        return null;
    }
  };

  return (
    <NotificationItem
      key={notification.id}
      isNew={notification.isNew.toString()}
      onClick={() => onMarkAsRead(notification.id)}
    >
      {notification.isNew && <Badge status="processing" style={{ marginRight: '20px' }} />}
      <NotificationContent>
        <TitleAndIconContainer>
          <NotificationTitle>{notification.title}</NotificationTitle>
          <NotificationIcon>{getIconByType(notification)}</NotificationIcon>
        </TitleAndIconContainer>
        <NotificationText>{notification.message}</NotificationText>
        <NotificationTime>{new Date(notification.timestamp).toLocaleDateString()}</NotificationTime>
      </NotificationContent>
    </NotificationItem>
  );
};

export default NotificationMessage;
