import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  sideFooter: {
    fontSize: 6,
    marginLeft: 0,
    bottom: 50,
    padding: 5,
    textAlign: 'right',
    position: 'absolute',
    width: 80,
    flexWrap: 'wrap'
  }
});

export const SideFooter = () => (
  <View style={styles.sideFooter}>
    <Text>Preduzeće</Text>
    <Text>Delta Motors d.o.o.</Text>
    <Text> </Text>
    <Text>Adresa</Text>
    <Text>Delta Motors d.o.o.</Text>
    <Text>Radnička 8</Text>
    <Text>11030 Beograd</Text>
    <Text>Srbija</Text>
    <Text> </Text>
    <Text>Servis</Text>
    <Text>Radnička 8</Text>
    <Text>11030 Beograd</Text>
    <Text>Tel.: +381 11 35 39 902</Text>
    <Text> </Text>
    <Text>Internet</Text>
    <Text>www.bmw.rs</Text>
    <Text>www.mini.rs</Text>
    <Text> </Text>
    <Text>Prodajni salon</Text>
    <Text>Radnicka 8</Text>
    <Text>11030 Belgrade</Text>
    <Text>Tel.:</Text>
    <Text>+381 11 36 39 901</Text>
    <Text> </Text>
    <Text>Banka</Text>
    <Text>Banca Intesa a.d.</Text>
    <Text>Beograd</Text>
    <Text> </Text>
    <Text>Broj tekućeg računa</Text>
    <Text>160-265682-79</Text>
    <Text> </Text>
    <Text>Ovlašćeno lice</Text>
    <Text>Aleksandra Đurđević</Text>
    <Text> </Text>
    <Text>Registracija</Text>
    <Text>PIB: 104646704</Text>
    <Text>Matični broj: 20204192</Text>
  </View>
);
