import React, { useEffect, useState } from 'react';
import { Modal, Button, Select, Table, Input, Form, Row, Col } from 'antd';
import { IMake, IModel, ISeries } from 'Models/Models';
import { useDeleteModelMutation, useGetAllModelsQuery, usePostModelMutation } from 'services/autobookerApi';
import { openNotificationWithIcon } from 'components/Notification/Notification';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

interface IProps {
  make?: IMake;
  onClose: () => void;
}

const SeriesModels = ({ make, onClose }: IProps) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [selectedSeries, setSelectedSeries] = useState<ISeries | undefined>(undefined);
  const { data: modelListData, isLoading: modelListLoading } = useGetAllModelsQuery(
    selectedSeries ? `seriesId=${selectedSeries.id}` : '',
    { skip: !selectedSeries }
  );
  const [postModel, { data: modelData, isLoading: modelLoading, error: modelError }] = usePostModelMutation();
  const [deleteModel] = useDeleteModelMutation();
  const [form] = Form.useForm();
  const seriesOptions = (make?.series ?? []).map((series) => ({
    id: series.id,
    name: series.name,
    makeId: series.makeId,
    vehicleType: series.vehicleType,
    carType: series.carType
  }));
  const handleAddModel = () => {
    form
      .validateFields()
      .then(async (values) => {
        const newModel: any = {
          seriesId: selectedSeries?.id || '',
          name: values.name,
          productCode: values.productCode,
          code: values.code
        };
        await postModel(newModel)
          .unwrap()
          .then((result) => {
            openNotificationWithIcon('success', t('makes.makeAdded'), t('makes.makeAddedDesc'));
            form.resetFields();
            form.setFieldsValue({ series: selectedSeries?.id });
          });
      })
      .catch((validationError) => {
        // console.error('Validation error:', validationError);
        openNotificationWithIcon('error', t('validation.failed'), 'Please check the form fields');
      });
  };

  const handleDeleteModel = async (model: IModel) => {
    const result = await deleteModel(model);
    if ('error' in result) {
      openNotificationWithIcon('error', t('makes.makeDeleteError'), t('makes.makeDeleteErrorDesc'));
    } else {
      openNotificationWithIcon('success', t('makes.makeDeleted'), t('makes.makeDeletedDesc'));
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Product Code',
      dataIndex: 'productCode',
      key: 'productCode'
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: IModel) => (
        <Button type="link" onClick={() => handleDeleteModel(record)}>
          Delete
        </Button>
      )
    }
  ];

  const onChangeSeries = (value: string) => {
    const selected = seriesOptions.find((series) => series.id === value);
    setSelectedSeries(selected);
  };

  useEffect(() => {
    if (!modelData) return;
    form.resetFields();
  }, [modelData, form]);

  useEffect(() => {
    setSelectedSeries(make?.series?.[0]);
    form.resetFields();
    form.setFieldsValue({ series: make?.series?.[0]?.id });
  }, [make]);

  return (
    <>
      <Form form={form} layout="vertical">
        <Form.Item label="Select series" name="series" rules={[{ required: true, message: 'Please select a series!' }]}>
          <Select onChange={onChangeSeries}>
            {seriesOptions.map((series) => (
              <Option key={series.id} value={series.id}>
                {series.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {selectedSeries && (
          <>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Code" name="code" rules={[{ required: true, message: 'Please input the code!' }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the name!' }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Product Code"
                  name="productCode"
                  rules={[{ required: true, message: 'Please input the product code!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" onClick={handleAddModel}>
                Add Model
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
      {modelListLoading && <LoadingOutlined />}
      {modelListData && <Table dataSource={modelListData} columns={columns} rowKey="id" pagination={false} />}
    </>
  );
};

export default SeriesModels;
