import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { CREATE_PASSWORD_PATH, FORGOT_PASSWORD_PATH } from 'App';
import { IInputFieldErrors, getEmptyFieldErrors } from 'hooks/EmptyFieldErrors';
import { Button, Input } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { CONTACT_ADMIN_PATH } from 'AutobookerRoutes';
import { openMessageWithIcon, openNotificationWithIcon } from 'components/Notification/Notification';
import { setCredentials } from '../authSlice';
import AuthWrapper from '../AuthWrapper';
import {
  Header,
  InputContainer,
  Divider,
  BigDivider,
  SmallDivider,
  LoginOptions,
  HeaderSmall,
  AuthInputLabel
} from '../Auth.style';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<IInputFieldErrors>({ email: false, password: false });
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const handleForgotPassword = () => {
    navigate(FORGOT_PASSWORD_PATH);
  };

  const signIn = () => {
    setServerErrorMessage('');
    const { fieldErrors, hasFieldErrors } = getEmptyFieldErrors({ email, password });
    setErrors({ ...fieldErrors });
    if (hasFieldErrors) {
      openNotificationWithIcon('error', t('login.error'), t('login.errorDesc'));
      return;
    }
    setLoginLoading(true);
    Auth.signIn(email, password, { scopes: ['openid', 'email', 'profile'].join(' ') })
      .then((loginResult) => {
        if (loginResult.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(
            setCredentials({
              user: {
                ...loginResult.challengeParam.userAttributes,
                company_id: loginResult.challengeParam.userAttributes['custom:company_id']
              },
              clientId: loginResult.pool.clientId
            })
          );
          navigate(CREATE_PASSWORD_PATH);
          return;
        }
        dispatch(
          setCredentials({
            user: { ...loginResult.attributes, company_id: loginResult.attributes['custom:company_id'] },
            clientId: loginResult.pool.clientId
          })
        );
        if (loginResult.attributes['custom:company_id'] === undefined) {
          navigate(CONTACT_ADMIN_PATH, { replace: true });
        }
        navigate('/');
      })
      .catch((error) => {
        setErrors({ ...errors, password: true, email: true });
        setServerErrorMessage(error.message);
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  return (
    <AuthWrapper>
      <Header>{t('login.headerText')}</Header>
      <BigDivider />
      <HeaderSmall>{t('login.title')}</HeaderSmall>
      <SmallDivider />
      {serverErrorMessage && <HeaderSmall hasError={!!serverErrorMessage}>{serverErrorMessage}</HeaderSmall>}
      <BigDivider />
      <InputContainer>
        <AuthInputLabel>{t('login.email')}</AuthInputLabel>
        <Input
          size="large"
          type="email"
          placeholder={t('login.email')}
          onChange={(e) => setEmail(e.target.value)}
          status={errors.email ? 'error' : undefined}
        />
        <Divider />
        <AuthInputLabel>{t('login.password')}</AuthInputLabel>
        <Input.Password
          size="large"
          placeholder={t('login.password')}
          onChange={(e) => setPassword(e.target.value)}
          visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
          status={errors.password ? 'error' : undefined}
        />
      </InputContainer>
      <Divider />
      <LoginOptions>
        <Button onClick={handleForgotPassword} type="link">
          {t('login.forgotPassword')}
        </Button>
      </LoginOptions>
      <BigDivider />
      <Button loading={loginLoading} size="large" block onClick={signIn} type="primary" icon={<LoginOutlined />}>
        {t('login.login')}
      </Button>
    </AuthWrapper>
  );
};

export default Login;
