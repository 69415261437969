import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { Button, Input } from 'antd';
import { IInputFieldErrors, getEmptyFieldErrors } from 'hooks/EmptyFieldErrors';
import { LOGIN_PATH } from 'App';
import AuthWrapper from '../AuthWrapper';
import {
  Header,
  InputContainer,
  AuthInput,
  BigDivider,
  HeaderSmall,
  AuthInputWrapper,
  AuthInputLabel,
  SmallDivider,
  ErrorMessage
} from '../Auth.style';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [emailIsSent, setEmailIsSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setPasswordConfirmation] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [errors, setErrors] = useState<IInputFieldErrors>({
    verificationCode: false,
    newPassword: false,
    confirmedPassword: false
  });
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [resetLoading, setResetLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  const isValidMail = () => {
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailFormat)) return true;
    return false;
  };

  const handleSendInstructions = () => {
    if (!isValidMail()) {
      setServerErrorMessage('Please enter a valid email');
      return;
    }
    Auth.forgotPassword(email)
      .then((data) => {
        setServerErrorMessage('');
        setEmailIsSent(true);
      })
      .catch((err) => {
        setServerErrorMessage(err.message);
        console.log(err);
      });
  };

  const handleChangePassword = () => {
    setServerErrorMessage('');
    const { fieldErrors, hasFieldErrors } = getEmptyFieldErrors({ verificationCode, newPassword, confirmedPassword });
    setErrors({ ...fieldErrors });
    if (hasFieldErrors) return;
    if (newPassword !== confirmedPassword) {
      // this should be done server side
      setServerErrorMessage(`Passwords don't match`);
      return;
    }

    Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
      .then((data) => {
        setPasswordChanged(true);
      })
      .catch((err) => {
        setServerErrorMessage(err.message);
      });
  };

  const goToLogin = () => {
    navigate(LOGIN_PATH);
  };

  if (emailIsSent) {
    if (passwordChanged) {
      return (
        <AuthWrapper>
          <Header>{t('forgotPassword.passwordChangedSuccessfully')}</Header>
          <BigDivider />
          <Button type="primary" onClick={goToLogin}>
            {t('forgotPassword.login')}
          </Button>
        </AuthWrapper>
      );
    }
    return (
      <AuthWrapper>
        <Header>{t('forgotPassword.checkEmail')}</Header>
        <BigDivider />
        <HeaderSmall>{t('forgotPassword.instructions')}</HeaderSmall>
        <SmallDivider />
        {serverErrorMessage && <HeaderSmall hasError={!!serverErrorMessage}>{serverErrorMessage}</HeaderSmall>}
        <BigDivider />
        <InputContainer>
          <AuthInputLabel>{t('forgotPassword.verificationCode')}</AuthInputLabel>
          <Input
            id="verificationCode"
            size="large"
            type="number"
            placeholder={t('forgotPassword.verificationCodeError')}
            onChange={(e) => setVerificationCode(e.target.value)}
            status={errors.verificationCode ? 'error' : undefined}
            autoComplete="off"
          />
        </InputContainer>
        <BigDivider />
        <InputContainer>
          <AuthInputLabel>{t('forgotPassword.newPassword')}</AuthInputLabel>
          <Input
            id="newPassword"
            size="large"
            placeholder={t('forgotPassword.newPassword')}
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            status={errors.newPassword ? 'error' : undefined}
            defaultValue={newPassword}
            autoComplete="off"
          />
        </InputContainer>
        <BigDivider />
        <InputContainer>
          <AuthInputLabel>{t('forgotPassword.confirmPassword')}</AuthInputLabel>
          <Input
            id="confirmedPassword"
            size="large"
            placeholder={t('forgotPassword.confirmPassword')}
            type="password"
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            status={errors.confirmedPassword ? 'error' : undefined}
            defaultValue={confirmedPassword}
            autoComplete="off"
          />
        </InputContainer>
        <BigDivider />
        <Button loading={changePasswordLoading} size="large" onClick={handleChangePassword} type="primary">
          {t('forgotPassword.submit')}
        </Button>
      </AuthWrapper>
    );
  }
  return (
    <AuthWrapper>
      <Header>{t('forgotPassword.headerText')}</Header>
      <BigDivider />
      <HeaderSmall>{t('forgotPassword.title')}</HeaderSmall>
      <SmallDivider />
      {serverErrorMessage && <HeaderSmall hasError={!!serverErrorMessage}>{serverErrorMessage}</HeaderSmall>}
      <BigDivider />
      <InputContainer>
        <AuthInputLabel>{t('forgotPassword.email')}</AuthInputLabel>
        <Input
          size="large"
          placeholder={t('login.email')}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          status={serverErrorMessage ? 'error' : undefined}
        />
      </InputContainer>
      <BigDivider />
      <Button loading={resetLoading} size="large" onClick={handleSendInstructions} type="primary">
        {t('forgotPassword.sendInstructions')}
      </Button>
    </AuthWrapper>
  );
};

export default ForgotPassword;
