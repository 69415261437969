import styled from 'styled-components';
import { theme } from '../../../../styles/theme';

export const LogoAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const TransmissionTextWrapper = styled.div`
  text-align: center;
`;

export const Logo = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;
`;

export const Text = styled.div`
  flex: 0;
  maargin-left: 5px;
  margin-right: 5px;
`;

export const TextLicensePlate = styled.div`
  flex: 1;
`;

export const TableWrapper = styled.div`
  background-color: ${theme.main.colors.snow};
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
`;

export const ContainerRange = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
`;

export const ElementRange = styled.div`
  flex: 1;
  text-align: left;
`;
