interface IInputFields {
  [key: string]: string;
}
export interface IInputFieldErrors {
  [key: string]: boolean;
}

export const getEmptyFieldErrors = (fields: IInputFields) => {
  const fieldKeys = Object.keys(fields);
  const fieldErrors: IInputFieldErrors = {};

  fieldKeys.forEach((key) => {
    fieldErrors[key] = false;
    if (!fields[key]) fieldErrors[key] = true;
  });

  return {
    fieldErrors,
    hasFieldErrors: Object.values(fieldErrors).find((error: boolean) => error)
  };
};
