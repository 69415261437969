import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import VehicleTable from 'components/pages/Vehicles/VehicleTable';
import MakesTable from 'components/pages/Makes/MakesTable';
import VehicleStats from 'components/pages/VehicleStats/VehicleStats';
import Login from 'components/pages/Auth/components/Login';
import ForgotPassword from 'components/pages/Auth/components/ForgotPassword';
import CreatePassword from 'components/pages/Auth/components/CreatePassword';
import ContactAdmin from 'components/pages/Auth/components/ContactAdmin';
import QRScannerComponent from 'components/pages/Dashboard/Reservations/ReservationDay/components/ReservationUserDetails/QRScanner/QRScanner';
import Dashboard from './components/pages/Dashboard/Dashboard';
import ReservationTable from './components/pages/Dashboard/Reservations/ReservationTable';
import Map from './components/pages/VehicleStats/components/VehicleMap';

export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const CONTACT_ADMIN_PATH = '/contact-admin';
export const VEHICLES_PATH = '/vehicles';
export const VEHICLES_STATS_PATH = '/vehicles-stats';
export const MAKES_PATH = '/makes';
export const CREATE_PASSWORD_PATH = '/create-password';
export const HELP_PAGE_PATH = '/help';
export const SETTINGS_PATH = '/settings';
export const QR_CODE_SCANNER_PATH = '/qr-code-scanner';

function AutobookerRoutes() {
  return (
    <Routes>
      <Route path={LOGIN_PATH} element={<Login />} />
      <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
      <Route path={CREATE_PASSWORD_PATH} element={<CreatePassword />} />
      <Route path={CONTACT_ADMIN_PATH} element={<ContactAdmin />} />
      {/* <Route path={CREATE_PASSWORD_PATH} element={<CreatePassword />} /> */}
      <Route path={ROOT_PATH} element={<Dashboard />}>
        {/* <Route index element={<ReservationList />} /> */}
        <Route index element={<ReservationTable />} />
        <Route path={VEHICLES_PATH} element={<VehicleTable />} />
        <Route path={VEHICLES_STATS_PATH} element={<VehicleStats />} />
        <Route path={MAKES_PATH} element={<MakesTable />} />
      </Route>
      <Route path={QR_CODE_SCANNER_PATH} element={<QRScannerComponent />} />
    </Routes>
  );
}

export default AutobookerRoutes;
