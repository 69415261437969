import { notification, message } from 'antd';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const openNotificationWithIcon = (type: NotificationType, text: string, description: string | object) => {
  notification[type]({
    message: text,
    description: typeof description === 'object' ? JSON.stringify(description) : description
  });
};

export const openMessageWithIcon = (type: NotificationType, text: string, description: string | object) => {
  switch (type) {
    case 'success':
      message.success(text);
      break;
    case 'info':
      message.info(text);
      break;
    case 'warning':
      message.warning(text);
      break;
    case 'error':
      message.error(text);
      break;
    default:
      message.info(text);
      break;
  }
};
