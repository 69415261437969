import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { Button } from 'antd';
import { LOGIN_PATH } from 'App';
import AuthWrapper from '../AuthWrapper';
import { Header, BigDivider } from '../Auth.style';

const ContactAdmin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = () => {
    Auth.signOut()
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AuthWrapper>
      <Header>{t('contactAdmin.pleaseContactAdministrator')}</Header>
      <BigDivider />
      <Button type="primary" onClick={handleLogout}>
        {t('contactAdmin.logout')}
      </Button>
    </AuthWrapper>
  );
};

export default ContactAdmin;
