import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { IInputFieldErrors, getEmptyFieldErrors } from 'hooks/EmptyFieldErrors';
import { setCredentials } from '../authSlice';
import AuthWrapper from '../AuthWrapper';
import {
  Header,
  InputContainer,
  AuthInput,
  Divider,
  BigDivider,
  Button,
  HeaderSmall,
  AuthInputWrapper,
  AuthInputLabel,
  SmallDivider,
  ErrorMessage
} from '../Auth.style';

const CreatePassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);

  const [oldPassword, setOldpassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [errors, setErrors] = useState<IInputFieldErrors>({
    oldPassword: false,
    newPassword: false,
    confirmationPassword: false
  });
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const resetPassword = () => {
    setServerErrorMessage('');
    const { fieldErrors, hasFieldErrors } = getEmptyFieldErrors({ oldPassword, newPassword, confirmationPassword });
    setErrors({ ...fieldErrors });
    if (hasFieldErrors) return;
    if (newPassword !== confirmationPassword) {
      setServerErrorMessage(`Passwords don't match`);
      return;
    }
    Auth.signIn(user!.email, oldPassword)
      .then((loginResult) => {
        if (loginResult.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(loginResult, newPassword)
            .then((userRes) => {
              dispatch(
                setCredentials({
                  user: userRes.challengeParam.userAttributes,
                  clientId: userRes.pool.clientId
                })
              );
              navigate('/');
            })
            .catch((e) => {
              setServerErrorMessage(e.message);
            });
        }
      })
      .catch((e) => {
        setServerErrorMessage(e.message);
      });
  };

  return (
    <AuthWrapper>
      <Header>{t('login.headerText')}</Header>
      <BigDivider />
      <HeaderSmall>{t('createPassword.title')}</HeaderSmall>
      <SmallDivider />
      {serverErrorMessage && <HeaderSmall hasError={!!serverErrorMessage}>{serverErrorMessage}</HeaderSmall>}
      <BigDivider />
      <InputContainer>
        <AuthInputLabel>{t('createPassword.oldPassword')}</AuthInputLabel>
        <AuthInputWrapper>
          <AuthInput type="password" hasError={errors.oldPassword} onChange={(e) => setOldpassword(e.target.value)} />
          {errors.oldPassword && <ErrorMessage>{t('createPassword.oldPasswordError')}</ErrorMessage>}
        </AuthInputWrapper>
        <Divider />
        <AuthInputLabel>{t('createPassword.newPassword')}</AuthInputLabel>
        <AuthInputWrapper>
          <AuthInput type="password" hasError={errors.newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          {errors.newPassword && <ErrorMessage>{t('createPassword.newPasswordError')}</ErrorMessage>}
        </AuthInputWrapper>
        <Divider />
        <AuthInputLabel>{t('createPassword.confirmPassword')}</AuthInputLabel>
        <AuthInputWrapper>
          <AuthInput
            type="password"
            hasError={errors.confirmationPassword}
            onChange={(e) => setConfirmationPassword(e.target.value)}
          />
          {errors.confirmationPassword && <ErrorMessage>{t('createPassword.confirmPasswordError')}</ErrorMessage>}
        </AuthInputWrapper>
      </InputContainer>
      <BigDivider />
      <Button onClick={resetPassword}>{t('createPassword.resetPassword')}</Button>
    </AuthWrapper>
  );
};

export default CreatePassword;
