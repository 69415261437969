import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
`;

export const Label = styled.div`
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
`;

export const Header = styled.div`
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
`;

export const ButtonContainer = styled.div`
  text-align: right;
`;
