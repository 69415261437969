import dayjs from 'dayjs';
import { SliderMarks } from 'antd/es/slider';
import {
  FuelTypeEnum,
  IDay,
  IVehicle,
  RegistrationPeriodTypeEnum,
  TransmissionTypeEnum,
  VehicleTypeEnum
} from '../../../../Models/Models';

export const openingTime = 900;
export const closingTime = 1900;
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_DATE_FORMAT = 'D/M';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
// export const COMPANY = 'ABCCompany';
export const REGISTRATION_WARNING_HIGH = 0;
export const REGISTRATION_WARNING_LOW = 30;

const getRangePresets = () => {
  return [
    { label: 'Previews 14 Days', value: [dayjs().startOf('week').add(-13, 'd'), dayjs().startOf('week')] },
    { label: 'Previews 7 Days', value: [dayjs().startOf('week').add(-6, 'd'), dayjs().startOf('week')] },
    {
      label: '7 Days',
      value: [dayjs().startOf('week').startOf('week').add(1, 'd'), dayjs().startOf('week').add(7, 'd')]
    },
    { label: '14 Days', value: [dayjs().startOf('week').add(1, 'd'), dayjs().startOf('week').add(14, 'd')] },
    { label: '30 Days', value: [dayjs().startOf('month').add(1, 'd'), dayjs().add(31, 'd')] }
  ];
};

export const rangePresets = getRangePresets();

export const getFirstAndLastDate = (data: Record<string, boolean>): [dayjs.Dayjs | null, dayjs.Dayjs | null] => {
  let firstDate: dayjs.Dayjs | null = null;
  let lastDate: dayjs.Dayjs | null = null;

  Object.keys(data).forEach((key) => {
    if (data[key]) {
      const [, dateString] = key.split(',');
      const date = dayjs(dateString);

      if (date.isValid()) {
        if (!firstDate || date.isBefore(firstDate)) {
          firstDate = date;
        }

        if (!lastDate || date.isAfter(lastDate)) {
          lastDate = date;
        }
      }
    }
  });

  return [firstDate, lastDate];
};

export const LogoRenderer = ({ svgString, width, height }: any) => {
  return (
    <div style={{ width: `${width}`, height: `${height}` }}>
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        dangerouslySetInnerHTML={{ __html: svgString }}
      />
    </div>
  );
};

export const SvgRenderer = ({ svgString, width, height }: any) => {
  return (
    <div style={{ width, height }}>
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        dangerouslySetInnerHTML={{ __html: svgString }}
      />
    </div>
  );
};

export const getFormattedDate = (date: string) => {
  return dayjs(date).format(SHORT_DATE_FORMAT);
};

export const getFormattedTime = (date: string) => {
  return dayjs(date).format(TIME_FORMAT);
};

export const getFormattedDateLong = (date: string) => {
  return dayjs(date).format(DATE_FORMAT);
};

export const findReservationsByDate = (date: string, days: IDay[]): IDay | undefined => {
  if (!days || days.length === 0) {
    return undefined;
  }

  return days.find((day) => day.date === date);
};

export const groups = [{ name: 'vehicleInfo', header: 'Vehicle' }];

export const marks: SliderMarks = {
  // 800: "8h",
  900: { label: <p>09h</p>, style: { color: 'black' } },
  1000: '10h',
  1100: '11h',
  1200: '12h',
  1300: '13h',
  1400: '14h',
  1500: '15h',
  1600: '16h',
  1700: '17h',
  1800: '18h',
  1900: { label: <p>19h</p>, style: { color: 'black' } }
  // 1800: "18h",
  // 1900: "19h",
  // 2000: "20h"
};

export const convertToTimeString = (input: number): string => {
  const firstTwoDigits = Math.floor(input / 100);
  const lastTwoDigits = input % 100;

  if (firstTwoDigits >= 0 && firstTwoDigits <= 23 && lastTwoDigits >= 0 && lastTwoDigits <= 59) {
    const minutes = lastTwoDigits === 50 ? 30 : lastTwoDigits;
    const formattedHours = firstTwoDigits.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:00`;
  }
  return '';
};

export const convertToSliderValue = (timeString: string | null): number => {
  if (!timeString) {
    return -1;
  }

  const timeParts = timeString.split(':');

  if (timeParts.length === 3) {
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);

    if (
      !Number.isNaN(hours) &&
      !Number.isNaN(minutes) &&
      !Number.isNaN(seconds) &&
      hours >= 0 &&
      hours <= 23 &&
      minutes >= 0 &&
      minutes <= 59 &&
      seconds >= 0 &&
      seconds <= 59
    ) {
      const minutesValue = minutes === 30 ? 50 : minutes;
      return hours * 100 + minutesValue;
    }
  }

  return -1;
};

export const findFirstAvailableTime = (reservations: any) => {
  // Sort the reservations by their start time
  const sortedReservations = [...reservations].sort(
    (a, b) => convertToSliderValue(a.startTime) - convertToSliderValue(b.startTime)
  );

  let currentEndTime = openingTime;

  // First check if the openingTime is free up until the first reservation
  if (sortedReservations.length > 0 && convertToSliderValue(sortedReservations[0].startTime) > openingTime) {
    return {
      startTime: openingTime,
      endTime: convertToSliderValue(sortedReservations[0].startTime)
    };
  }

  const result = sortedReservations.reduce((availableTime, reservation) => {
    // Check if there's a gap between the current end time and the next reservation's start time
    if (convertToSliderValue(reservation.startTime) > currentEndTime) {
      return {
        startTime: currentEndTime,
        endTime: convertToSliderValue(reservation.startTime)
      };
    }

    // Move the currentEndTime forward to the end of the current reservation
    currentEndTime = convertToSliderValue(reservation.endTime);
    return availableTime;
  }, null);

  // Finally, check if there is time available after the last reservation
  if (currentEndTime < closingTime) {
    return {
      startTime: currentEndTime,
      endTime: closingTime
    };
  }

  return result;
};

export const calculateDaysLeftForRegistrationRenewal = (selectedVehicle: IVehicle) => {
  const result = {
    daysLeftForRegistrationRenewal: 0,
    regPeriodInDays: 0
  };

  if (selectedVehicle) {
    const currentYear = dayjs().year();
    const startOfYear = dayjs().startOf('year');
    const endOfYear = dayjs().endOf('year');

    const date = dayjs(selectedVehicle.registered, DB_DATE_FORMAT);
    const now = dayjs();
    const diff = date.diff(now, 'days');

    const numberOfDaysInCurrentYear = endOfYear.diff(startOfYear, 'day') + 1;

    result.daysLeftForRegistrationRenewal = numberOfDaysInCurrentYear + diff;

    let numOfDays = 0;
    switch (selectedVehicle.registrationPeriodType) {
      case RegistrationPeriodTypeEnum.Year:
        numOfDays = 365;
        break;
      case RegistrationPeriodTypeEnum.Month:
        numOfDays = 30;
        break;
      default:
        numOfDays = 365;
        break;
    }

    result.regPeriodInDays = selectedVehicle.registrationPeriod * numOfDays;

    result.daysLeftForRegistrationRenewal = result.regPeriodInDays - result.daysLeftForRegistrationRenewal;
  }

  return result;
};

export const getDescription = (vehicleType: VehicleTypeEnum) => {
  switch (vehicleType) {
    case VehicleTypeEnum.Car:
      return 'Car';
    case VehicleTypeEnum.Van:
      return 'Van';
    case VehicleTypeEnum.Truck:
      return 'Truck';
    case VehicleTypeEnum.Bus:
      return 'Bus';
    case VehicleTypeEnum.Motorcycle:
      return 'Motorcycle';
    case VehicleTypeEnum.Other:
      return 'Other';
    default:
      return '';
  }
};

export const getColorForRegistration = (value: number) => {
  return `
    ${value < REGISTRATION_WARNING_HIGH ? 'red' : ''}
    ${value > REGISTRATION_WARNING_HIGH && value < REGISTRATION_WARNING_LOW ? 'orange' : ''}
    ${value >= REGISTRATION_WARNING_LOW ? '#00000000' : ''}
  `.trim();
};

export const enum TimelineItemTypeEnum {
  DATE,
  TIME
}

export interface TimelineSummeryItem {
  date?: string;
  startTime?: string;
  endTime?: string;
  description?: string;
  additionalDescription?: string;
  dot?: any;
  color?: string;
  type: TimelineItemTypeEnum;
}

export const getTransmissionType = (transmission: TransmissionTypeEnum) => {
  switch (transmission) {
    case TransmissionTypeEnum.Automatic:
      return 'Automatic';
    case TransmissionTypeEnum.Manual:
      return 'Manual';
    default:
      return '';
  }
};

export const getFuelType = (fuelType: FuelTypeEnum) => {
  switch (fuelType) {
    case FuelTypeEnum.Petrol:
      return 'Petrol';
    case FuelTypeEnum.Diesel:
      return 'Diesel';
    case FuelTypeEnum.Electric:
      return 'Electric';
    case FuelTypeEnum.Hybrid:
      return 'Hybrid';
    default:
      return '';
  }
};

export const getVehicleType = (vehicleType: VehicleTypeEnum) => {
  switch (vehicleType) {
    case VehicleTypeEnum.Car:
      return 'Car';
    case VehicleTypeEnum.Van:
      return 'Van';
    case VehicleTypeEnum.Truck:
      return 'Truck';
    case VehicleTypeEnum.Bus:
      return 'Bus';
    case VehicleTypeEnum.Motorcycle:
      return 'Motorcycle';
    case VehicleTypeEnum.Other:
      return 'Other';
    default:
      return '';
  }
};
