import { useAppSelector } from 'hooks';
import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

interface WebSocketContextProps {
  isReady: boolean;
  message: any;
  send: ((data: any) => void) | null;
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const [isReady, setIsReady] = useState(false);
  const [message, setMessage] = useState(null);
  const [ws, setWs] = useState<WebSocket | null>(null);

  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    const wsUrl = `${process.env.REACT_APP_WS_URL}?userId=${user?.id}&email=${user?.email}`;
    const socket = new WebSocket(wsUrl);
    socket.onopen = () => setIsReady(true);
    socket.onclose = () => setIsReady(false);
    socket.onmessage = (event) => {
      // Log the received message to the console
      console.log('Received message:', event.data);
      setMessage(event.data);
    };
    setWs(socket);
    return () => {
      socket.close();
    };
  }, [user]);

  const send = (data: any) => {
    if (ws) {
      ws.send(data);
    }
  };

  const contextValue = useMemo(() => ({ isReady, message, send }), [isReady, message, send]);

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
};
