import { useLocation, useNavigate } from 'react-router-dom';
import AutobookerRoutes, { QR_CODE_SCANNER_PATH } from 'AutobookerRoutes';
import { Amplify, Auth } from 'aws-amplify';
import { useTheme } from '@aws-amplify/ui-react';
import './App.css';
import { useAppDispatch, useAppSelector } from 'hooks';
import settings from 'settings';
import '@aws-amplify/ui-react/styles.css';
import { setCompany, setCredentials } from 'components/pages/Auth/authSlice';
import { useEffect, useState } from 'react';
import { useGetCompanyQuery } from 'services/autobookerApi';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { WebSocketProvider } from 'WebSocketContext';
import { Font } from '@react-pdf/renderer';

export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const VEHICLES_PATH = '/vehicles';
export const VEHICLES_STATS_PATH = '/vehicles-stats';
export const MAKES_PATH = '/makes';
export const CREATE_PASSWORD_PATH = '/create-password';
export const HELP_PAGE_PATH = '/help';
export const SETTINGS_PATH = '/settings';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf'
});

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
  fontWeight: 'bold'
});

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-underline-webfont.ttf',
  fontStyle: 'underline'
});

Amplify.configure({
  Auth: settings.Auth,
  API: {
    endpoints: [
      {
        name: 'DevApi',
        endpoint: settings.backend,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await Amplify.Auth.currentAuthenticatedUser({
              scopes: ['openid', 'email', 'profile']
            })
              .getAccessToken()
              .getJwtToken()}`
          };
        }
      }
    ]
  }
});

function App() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.auth.user);
  const [companyId, setCompanyId] = useState<string>('');
  const { data: company, isError: isErrorOnGetCompany, refetch } = useGetCompanyQuery(companyId, { skip: !companyId });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (company) {
      dispatch(
        setCompany({
          company
        })
      );
    }
  }, [company]);

  useEffect(() => {
    if (user) {
      setCompanyId(user.company_id);
    }
  }, [companyId]);

  useEffect(() => {
    if (user && isErrorOnGetCompany) {
      setShowModal(true);
    }
  }, [isErrorOnGetCompany]);

  useEffect(() => {
    if (companyId) {
      refetch();
    }
  }, [companyId]);

  const handleLoggedInUser = () => {
    if (
      location.pathname === LOGIN_PATH ||
      location.pathname === CREATE_PASSWORD_PATH ||
      location.pathname === FORGOT_PASSWORD_PATH
    ) {
      navigate(ROOT_PATH, { replace: true });
      return;
    }
    navigate(location.pathname, { replace: true });
  };

  const handleLoggedOutUser = () => {
    if (location.pathname === CREATE_PASSWORD_PATH && user) {
      navigate(CREATE_PASSWORD_PATH, { replace: true });
      return;
    }
    if (location.pathname === FORGOT_PASSWORD_PATH) {
      navigate(FORGOT_PASSWORD_PATH, { replace: true });
      return;
    }
    navigate(LOGIN_PATH, { replace: true });
  };

  const checkLogInState = () => {
    Amplify.Auth.currentAuthenticatedUser({
      scopes: ['openid', 'email', 'profile']
    })
      .then((result: any) => {
        dispatch(
          setCredentials({
            user: {
              ...result.attributes,
              id: result.attributes.sub,
              company_id: result.attributes['custom:company_id']
            },
            clientId: result.pool.clientId
          })
        );
        setCompanyId(result.attributes['custom:company_id']);
        handleLoggedInUser();
      })
      .catch(() => {
        handleLoggedOutUser();
      });
  };

  const logOut = () => {
    Auth.signOut()
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (location.pathname === QR_CODE_SCANNER_PATH && location.search) {
      navigate(QR_CODE_SCANNER_PATH, { replace: true });
      return;
    }
    checkLogInState();
  }, [location.pathname]);

  const modalStyle: any | undefined = {
    mask: {
      backdropFilter: 'blur(10px)'
    }
  };

  return (
    <WebSocketProvider>
      <AutobookerRoutes />
      <Modal
        title={t('login.noCopmanyFound')}
        centered
        open={showModal}
        className="custom-modal"
        style={modalStyle}
        closable={false}
        footer={[
          <Button key="customOk" type="primary" onClick={logOut}>
            {t('login.logout')}
          </Button>
        ]}
      >
        <p>{t('login.pleaseContactAdminForCompany')}</p>
      </Modal>
    </WebSocketProvider>
  );
}

export default App;
