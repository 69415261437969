import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { autobookerApi } from '../services/autobookerApi';
import authReducer from '../components/pages/Auth/authSlice';
import reservationListReducer from '../components/pages/Dashboard/Reservations/reservationsSlice';

const store = configureStore({
  reducer: {
    [autobookerApi.reducerPath]: autobookerApi.reducer,
    auth: authReducer,
    reservation: reservationListReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(autobookerApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

export default store;
