import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROOT_PATH } from 'AutobookerRoutes';
import Webcam from 'react-webcam';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Button, Progress } from 'antd';
import { useCheckQRIdentifierMutation, useGenerateS3PresignedUrlMutation } from 'services/autobookerApi';
import dayjs from 'dayjs';
import { openNotificationWithIcon } from 'components/Notification/Notification';
import { FileTypeEnum, GetPresignedS3UrlResponse, HttpVerbEnum } from 'Models/Models';
import { LoadingOutlined } from '@ant-design/icons';
import { Container, WebcamContainer } from './QRScannar.style';

const QRScannerComponent = () => {
  const { t } = useTranslation();
  const webcamRef = useRef<any>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const qrIdentifier = new URLSearchParams(location.search).get('qrIdentifier');
  const company = new URLSearchParams(location.search).get('companyId');
  const reservation = new URLSearchParams(location.search).get('reservationId');
  const [checkQRIdentifier, { isLoading }] = useCheckQRIdentifierMutation();
  const [generateS3Url, { isLoading: isLoadingS3Presign }] = useGenerateS3PresignedUrlMutation();
  const [isValidQRIdentifier, setIsValidQRIdentifier] = useState(true);
  const [qrIdentifierDB, setQrIdentifierDB] = useState(qrIdentifier);
  const [companyId, setCompanyId] = useState(company);
  const [reservationId, setReservationId] = useState(reservation);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const videoConstraints = {
    facingMode: isMobile ? 'environment' : 'user'
  };

  const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}`);

  useEffect(() => {
    if (socket) {
      socket.addEventListener('open', (event) => {
        console.log('WebSocket connection opened:', event);
      });
      socket.addEventListener('message', (event) => {
        console.log('WebSocket message received:', event.data);
      });
      socket.addEventListener('close', (event) => {
        console.log('WebSocket connection closed:', event);
      });
    }
    // return () => {
    //   if (socket) {
    //     socket.close();
    //   }
    // };
  }, []);

  useEffect(() => {
    const validateQRIdentifier = async () => {
      if (qrIdentifier) {
        const isValidResult = await checkQRIdentifier({ qrIdentifier });
        if ('data' in isValidResult) {
          const { data } = isValidResult;
          if (data.isValid) {
            setIsValidQRIdentifier(data.isValid);
            setQrIdentifierDB(qrIdentifier);
          }
        } else {
          setIsValidQRIdentifier(false);
        }
      }
    };
    validateQRIdentifier();
  }, [qrIdentifier]);

  useEffect(() => {
    if (!isValidQRIdentifier) {
      navigate(ROOT_PATH, { replace: true });
    }
  }, [isValidQRIdentifier, qrIdentifier]);

  useEffect(() => {
    if (company) {
      setCompanyId(company);
    }
  }, [company]);
  useEffect(() => {
    if (reservation) {
      setReservationId(reservation);
    }
  }, [reservation]);

  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    if (!companyId) {
      openNotificationWithIcon('error', 'Error', 'Company Id is missing');
      return;
    }

    let presignedUrlForUpload = '';
    let filePath = '';
    const customFileName = `Scan_${dayjs().format('YYYY-MM-DD')}_${dayjs().format('HH-mm-ss')}.jpeg`;
    try {
      const res = await generateS3Url({
        fileName: customFileName,
        companyId,
        vehicleId: 'Undefined',
        reservationId: reservationId || 'Undefined',
        date: dayjs().toDate(),
        fileType: FileTypeEnum.ReservationAttachment,
        httpVerb: HttpVerbEnum.PUT
      });
      if ('data' in res) {
        const signedS3Url = res.data as GetPresignedS3UrlResponse;
        presignedUrlForUpload = signedS3Url.fullUrl;
        filePath = signedS3Url.fields.Key;
      }
      if (!presignedUrlForUpload) {
        throw new Error('Presigned URL is null or undefined');
      }
    } catch (error) {
      console.error('Error while generating presigned url', error);
    }

    try {
      const cancelTokenSource = axios.CancelToken.source();

      const imageData = imageSrc.split(',')[1];
      const byteArray = new Uint8Array(
        atob(imageData)
          .split('')
          .map((char) => char.charCodeAt(0))
      );
      const response = await axios.put(presignedUrlForUpload, byteArray, {
        headers: {
          'Content-Type': 'image/jpeg'
        },
        onUploadProgress: (progressEvent) => {
          const completedUpload = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          setPercentCompleted(completedUpload);
        },
        cancelToken: cancelTokenSource.token
      });
      if (response.status !== 200) {
        throw new Error('Error while uploading image');
      }
      // onSuccess(response.data, file);
      socket.send(
        JSON.stringify({
          action: 'uploadedDocumentMessage',
          message: {
            qrIdentifier: qrIdentifierDB,
            responseUpload: response.data,
            attachment: { url: filePath, name: customFileName, status: 'done', percent: 100 }
          }
        })
      );
      console.log('Image uploaded successfully');
    } catch (error) {
      console.error('Error while uploading image', error);
    }
  };

  return (
    <Container>
      {/* <Button type="primary" onClick={handleQRScan}>Scan QR Code</Button> */}
      {(socket.CONNECTING !== WebSocket.CONNECTING || isLoading) && (
        <LoadingOutlined style={{ fontSize: '3em', textAlign: 'center' }} />
      )}
      {!isValidQRIdentifier && <p>QR not valid </p>}
      {isValidQRIdentifier && !isLoading && (
        <>
          <WebcamContainer>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              style={{ width: '100%', maxWidth: '500px' }}
              videoConstraints={videoConstraints}
            />
          </WebcamContainer>
          <Progress percent={percentCompleted} />
          {socket && socket.OPEN && (
            <Button type="primary" onClick={captureImage}>
              {t('documentation.captureImage')}
            </Button>
          )}
        </>
      )}
    </Container>
  );
};

export default QRScannerComponent;
