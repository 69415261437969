import React from 'react';
import { Tooltip, Timeline, Tag, Collapse, CollapseProps } from 'antd';
import { IDay, ReservationTypeEnum } from 'Models/Models';
import { useTranslation } from 'react-i18next';

interface ReservationHoverDetailsProps {
  day: IDay;
}

const ReservationHoverDetails: React.FC<ReservationHoverDetailsProps> = ({ day }) => {
  const { t } = useTranslation();
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);

  const getColorForReservationType = (reservationType: ReservationTypeEnum) => {
    switch (reservationType) {
      case ReservationTypeEnum.InternalUse:
        return 'blue';
      case ReservationTypeEnum.SubstituteVehicle:
        return 'purple';
      case ReservationTypeEnum.TestDrive:
        return 'green';
      case ReservationTypeEnum.Other:
        return 'magenta';
      case ReservationTypeEnum.Rental:
        return 'orange';
      default:
        return 'red';
    }
  };

  const getNameForReservationType = (reservationType: ReservationTypeEnum) => {
    switch (reservationType) {
      case ReservationTypeEnum.InternalUse:
        return t('reservation.InternalUse');
      case ReservationTypeEnum.SubstituteVehicle:
        return t('reservation.SubstituteVehicle');
      case ReservationTypeEnum.TestDrive:
        return t('reservation.TestDrive');
      case ReservationTypeEnum.Other:
        return t('reservation.Other');
      case ReservationTypeEnum.Rental:
        return t('reservation.Rental');
      default:
        return 'Unknown';
    }
  };

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((tg) => tg !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: t('reservation.moreInfo'),
      children: <p>More info</p>
    }
  ];

  return (
    <div style={{ padding: '10px', maxHeight: '400px', overflowY: 'auto' }}>
      <Timeline
        mode="left"
        items={[...day.reservations]
          .sort((a, b) => {
            if (!a.startTime || !b.startTime) return 0;
            const timeA = new Date(`1970-01-01T${a.startTime}`);
            const timeB = new Date(`1970-01-01T${b.startTime}`);
            return timeA.getTime() - timeB.getTime();
          })
          .map((reservation) => ({
            key: reservation.id,
            color: 'green',
            label: (
              <span style={{ fontWeight: 'bold', fontSize: '16px', color: '#333' }}>
                {reservation.startTime} - {reservation.endTime}
              </span>
            ),
            children: (
              <div style={{ padding: '10px 0' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                  <Tag color={getColorForReservationType(reservation.reservationType)} style={{ marginRight: '8px' }}>
                    {getNameForReservationType(reservation.reservationType)}
                  </Tag>
                </div>
                <span style={{ fontStyle: 'italic', color: '#777' }}>
                  {t('reservationForm.description')}: {reservation.reservedByDescription}
                </span>
                <p style={{ margin: 0, color: '#555' }}>{reservation.additionalDescription}</p>
              </div>
            )
          }))}
      />
    </div>
  );
};

export default ReservationHoverDetails;
