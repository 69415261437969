import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import { Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import uuid from 'react-uuid';
import { DocumentTypeEnum, IMake, IModel, IReservation, IVehicle } from 'Models/Models';
import { useTranslation } from 'react-i18next';
import { useGetAllModelsQuery, useGetConnectedDataQuery, useGetMakeQuery } from 'services/autobookerApi';
import { LoadingOutlined } from '@ant-design/icons';
import ConsentForTestDrive from './ConsentForTestDrive';
import ContractOfService from './ContractOfService';
import AttachmentToTheContract from './AttachmentToTheContract';
import Authorization from './Authorization';
import HandoverReport from './HandoverReport';
import ReturnReport from './ReturnReport';

interface IProps {
  reservation: IReservation;
  vehicle: IVehicle;
  date: Date;
  documentType: DocumentTypeEnum;
  reRender: boolean;
}

const DynamicPdfViewer = ({ reservation, vehicle, date, documentType, reRender }: IProps) => {
  const company = useAppSelector((state) => state.auth.company);
  const { data: makeListData, isLoading: makeListLoading } = useGetMakeQuery(`companyId=${company?.id}`);
  const { data: connectedData, isLoading: connectedDataLoading } = useGetConnectedDataQuery(
    `connectorId=${reservation.connectorId}&date=${`${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`}&licensePlate=${vehicle.licensePlate}`
  );
  const { t } = useTranslation();
  const [key, setKey] = useState(uuid());
  const {
    data: modelListData,
    isLoading: modelListLoading,
    refetch: refetchModels
  } = useGetAllModelsQuery(vehicle ? `seriesId=${vehicle.seriesId}` : '', {
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    setKey(uuid());
  }, [reRender]);

  const getNameOfDocument = (arg0: DocumentTypeEnum) => {
    return t(`documentation.${arg0}`);
  };

  const getDocumentTitle = () => {
    return `${reservation.reservedByDescription}_${getNameOfDocument(documentType)}`;
  };

  const getDocument = (type: DocumentTypeEnum, keyu: string) => {
    if (makeListLoading || connectedDataLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <LoadingOutlined style={{ fontSize: '5em', textAlign: 'center' }} />
        </div>
      );
    }
    switch (type) {
      case DocumentTypeEnum.VEHICLE_USE_AGREEMENT:
        return (
          connectedData && (
            <ContractOfService
              key={`${keyu}${documentType}`}
              title={getDocumentTitle()}
              reservation={reservation}
              vehicle={vehicle}
              make={makeListData?.find((m: IMake) => m.id === vehicle.makeId)}
              model={modelListData?.find((m: IModel) => m.id === vehicle.modelId)}
              connectedData={connectedData}
            />
          )
        );
      case DocumentTypeEnum.CONSENT_FOR_TEST_DRIVE:
        return (
          connectedData && (
            <ConsentForTestDrive
              key={`${keyu}${documentType}`}
              title={getDocumentTitle()}
              reservation={reservation}
              vehicle={vehicle}
              make={makeListData?.find((m: IMake) => m.id === vehicle.makeId)}
              model={modelListData?.find((m: IModel) => m.id === vehicle.modelId)}
              connectedData={connectedData}
            />
          )
        );
      case DocumentTypeEnum.ATTACHMENT_TO_THE_CONTRACT:
        return (
          <AttachmentToTheContract
            key={`${keyu}${documentType}`}
            title={getDocumentTitle()}
            reservation={reservation}
            vehicle={vehicle}
            make={makeListData?.find((m: IMake) => m.id === vehicle.makeId)}
            model={modelListData?.find((m: IModel) => m.id === vehicle.modelId)}
          />
        );
      case DocumentTypeEnum.AUTHORIZATION:
        return (
          connectedData && (
            <Authorization
              key={`${keyu}${documentType}`}
              title={getDocumentTitle()}
              reservation={reservation}
              vehicle={vehicle}
              make={makeListData?.find((m: IMake) => m.id === vehicle.makeId)}
              model={modelListData?.find((m: IModel) => m.id === vehicle.modelId)}
              connectedData={connectedData}
            />
          )
        );
      case DocumentTypeEnum.HANDOVER_PROTOCOL_TAKEOVER:
        return (
          connectedData && (
            <HandoverReport
              key={`${keyu}${documentType}`}
              title={getDocumentTitle()}
              reservation={reservation}
              vehicle={vehicle}
              make={makeListData?.find((m: IMake) => m.id === vehicle.makeId)}
              model={modelListData?.find((m: IModel) => m.id === vehicle.modelId)}
              connectedData={connectedData}
            />
          )
        );
      case DocumentTypeEnum.HANDOVER_PROTOCOL_RETURN:
        return (
          connectedData && (
            <ReturnReport
              key={`${keyu}${documentType}`}
              title={getDocumentTitle()}
              reservation={reservation}
              vehicle={vehicle}
              make={makeListData?.find((m: IMake) => m.id === vehicle.makeId)}
              model={modelListData?.find((m: IModel) => m.id === vehicle.modelId)}
              connectedData={connectedData}
            />
          )
        );
      default:
        return <Document />;
    }
  };

  return reRender ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      {makeListLoading && <LoadingOutlined style={{ fontSize: '5em', textAlign: 'center' }} />}
      {!makeListLoading && (
        <PDFViewer key={key} width="100%" height="800">
          {getDocument(documentType, key)}
        </PDFViewer>
      )}
    </div>
  ) : null;
};

export default DynamicPdfViewer;
