import { useEffect, useState } from 'react';
import { ColorPicker } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  FuelTypeEnum,
  IMake,
  IModel,
  ISeries,
  IVehicle,
  TransmissionTypeEnum,
  TyreTypeEnum
} from '../../../../../Models/Models';
import { DATE_FORMAT, SvgRenderer } from '../Tools';
import { CustomDescriptions, ScrollableContainer } from './VehicleDetailsHorizontal.style';
import { ReactComponent as Summer } from '../../../../../theme/icons/summer.svg';
import { ReactComponent as Winter } from '../../../../../theme/icons/winter.svg';
import { ReactComponent as AllSeason } from '../../../../../theme/icons/all-season.svg';

const labelToPropertyMap = {
  makeId: 'makeId',
  name: 'name',
  licensePlate: 'licensePlate',
  // color: 'color',
  vin: 'vin',
  // year: 'year',
  // month: 'month',
  // transmission: 'transmission',
  registered: 'registered',
  // registrationPeriod: 'registrationPeriod',
  doors: 'doors',
  fuelType: 'fuelType',
  tyreType: 'tyreType'
};

const labelToTitleMap: Record<string, string> = {
  makeId: 'Model',
  name: 'Name',
  licensePlate: 'License Plate',
  tyreType: 'Tyre Type',
  // color: 'Color',
  vin: 'VIN',
  // year: 'Year',
  // month: 'Month',
  // transmission: 'Transmission',
  registered: 'Registered',
  // registrationPeriod: 'Registration Period',
  doors: 'Doors',
  fuelType: 'Fuel Type'
};

interface IProps {
  vehicle?: IVehicle;
  make?: IMake;
}

type ItemType = {
  label: string;
  span: { xl: number; xxl: number };
  children: any;
};

const VehicleDetailsHorizontal = ({ vehicle, make }: IProps) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<ItemType[]>([]);

  useEffect(() => {
    if (!vehicle) {
      return;
    }
    if (!make) {
      return;
    }
    const newItems = Object.entries(labelToPropertyMap).map(([label, property]) => {
      let children;
      if (property === 'color') {
        children = <ColorPicker value={(vehicle as any)[property]} disabled />;
      } else if (property === 'transmission') {
        switch ((vehicle as any)[property]) {
          case TransmissionTypeEnum.Automatic:
            children = 'Automatic';
            break;
          case TransmissionTypeEnum.Manual:
            children = 'Manual';
            break;
          default:
            children = '';
            break;
        }
      } else if (property === 'fuelType') {
        switch ((vehicle as any)[property]) {
          case FuelTypeEnum.Petrol:
            children = 'Petrol';
            break;
          case FuelTypeEnum.Diesel:
            children = 'Diesel';
            break;
          case FuelTypeEnum.Electric:
            children = 'Electric';
            break;
          case FuelTypeEnum.Hybrid:
            children = 'Hybrid';
            break;
          default:
            children = '';
            break;
        }
      } else if (property === 'tyreType') {
        switch ((vehicle as any)[property]) {
          case TyreTypeEnum.Summer:
            children = <Summer style={{ width: 20, height: 20 }} />;
            break;
          case TyreTypeEnum.Winter:
            children = <Winter style={{ width: 20, height: 20 }} />;
            break;
          case TyreTypeEnum.AllSeason:
            children = <AllSeason style={{ width: 20, height: 20 }} />;
            break;
          default:
            children = '';
            break;
        }
      } else if (property === 'registered') {
        children = (vehicle as any)[property] ? dayjs((vehicle as any)[property]).format(DATE_FORMAT) : '';
      } else if (property === 'makeId') {
        children = <p>{(vehicle as IVehicle).modelName}</p>;
      } else {
        children = (vehicle as any)[property] || '';
      }
      return {
        label: labelToTitleMap[property],
        span: { xl: 1, xxl: 1 },
        children
        // labelStyle: { whiteSpace: 'nowrap' }
      };
    });

    setItems(newItems);
  }, [vehicle, make]);

  return (
    <ScrollableContainer>
      <SvgRenderer svgString={make?.logo} height="60px" width="60px" />
      <CustomDescriptions size="small" items={items} />
    </ScrollableContainer>
  );
};

export default VehicleDetailsHorizontal;
