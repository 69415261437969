import React, { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

type Props = {
  children?: React.ReactNode;
  handleClickOutside: () => void;
};

const ClickOutsideWrapper = ({ children, handleClickOutside }: Props) => {
  const ref = useRef(null);
  useOnClickOutside(ref, handleClickOutside);
  return <div ref={ref}>{children}</div>;
};

export default ClickOutsideWrapper;
