import { styled } from 'styled-components';
import { DeleteOutlined, FilePdfOutlined, FilePdfTwoTone } from '@ant-design/icons';
import { Form, Input, Slider } from 'antd';
import { theme } from '../../../../../../styles/theme';

const { TextArea } = Input;

export const ItemListContainer = styled.div`
  background-color: ${theme.main.colors.reservationContainer};
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 16px;
  margin: 16px 0 0 0;
  max-width: 100%;
  border-radius: 15px;
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ItemDate = styled.div`
  font-weight: bold;
  flex: 1;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  cursor: pointer;
  color: #f5222d;
  font-size: 26px;
`;

export const PrintDocumentationIcon = styled(FilePdfTwoTone)`
  cursor: pointer;
  font-size: 26px;
  margin-right: 16px;
`;

export const DescriptionInput = styled(Form.Item)`
  margin-bottom: 16px;
  margin-right: 16px;
  flex: 1;
`;

export const AdditionalDescriptionInput = styled(Form.Item)`
  margin-bottom: 16px;
  margin-right: 16px;
  flex: 2;
`;

export const EmployeeInput = styled(Form.Item)`
  margin-bottom: 16px;
  margin-right: 16px;
  flex: 1;
`;

export const ReservationTypeInput = styled(Form.Item)`
  margin-bottom: 16px;
  flex: 1;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SliderWrapper = styled.div`
  width: 100%;
`;
