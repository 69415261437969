import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Input, Modal, QRCode, Row, Tooltip, Upload, UploadProps } from 'antd';
import { FileTypeEnum, GetPresignedS3UrlResponse, HttpVerbEnum, IAttachment, IReservation } from 'Models/Models';
import { Label } from '@aws-amplify/ui-react';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import uuid from 'react-uuid';
import dayjs from 'dayjs';
import {
  useGenerateQRCodeUrlMutation,
  useGenerateS3PresignedUrlDownloadMutation,
  useGenerateS3PresignedUrlMutation
} from 'services/autobookerApi';
import { openMessageWithIcon, openNotificationWithIcon } from 'components/Notification/Notification';
import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import { QR_CODE_SCANNER_PATH } from 'AutobookerRoutes';
import { useWebSocket } from 'WebSocketContext';

interface IProps {
  reservation: IReservation;
  onChange(item: IReservation): void;
  companyId: string;
  date: Date;
}
const cancelTokenMap = new Map();

const ImageModal = ({ imageUrl, visible, onClose }: { imageUrl: string; visible: boolean; onClose: () => void }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
  };
  return (
    <Modal title="Preview" open={visible} onCancel={onClose} footer={null} width="60%">
      {loading && <LoadingOutlined style={{ fontSize: '3em', textAlign: 'center' }} />}
      <img
        src={imageUrl}
        alt="Preview"
        style={{ width: '100%', display: loading ? 'none' : 'block' }}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </Modal>
  );
};

const ReservationUserDetails = ({ reservation, onChange, companyId, date }: IProps) => {
  const { t } = useTranslation();
  const [generateS3Url, { isLoading: isLoadingS3Presign }] = useGenerateS3PresignedUrlMutation();
  const [generateS3UrlDownload, { isLoading: isLoadingS3PresignDownload }] =
    useGenerateS3PresignedUrlDownloadMutation();
  const [getQRCodeUrl, { isLoading: isLoadingQRCode }] = useGenerateQRCodeUrlMutation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const updatedItem = { ...reservation, [name]: value };
    onChange(updatedItem);
  };
  const [qrCodeStatus, setQrCodeStatus] = useState<'loading' | 'expired' | 'active' | undefined>('expired');
  const [qrCodeValue, setQrCodeValue] = useState<string>('');
  const [qrIdentifier, setQrIdentifier] = useState<string>('');
  const [uploadKey, setUploadKey] = useState(0);

  const { isReady, message, send } = useWebSocket();

  const generateS3UrlAndFilePath = async (file: any) => {
    const res = await generateS3Url({
      fileName: file.name,
      companyId,
      vehicleId: 'Undefined',
      reservationId: reservation.id,
      date,
      fileType: FileTypeEnum.ReservationAttachment,
      httpVerb: HttpVerbEnum.PUT
    });
    if ('data' in res) {
      const signedS3Url = res.data as GetPresignedS3UrlResponse;
      return { signedS3Url };
    }
    throw new Error('Presigned URL is null or undefined');
  };

  const customRequest = async ({ file, onSuccess, onError, onProgress, onRemove }: any) => {
    try {
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenMap.set(file.uid, cancelTokenSource);
      const { signedS3Url } = await generateS3UrlAndFilePath(file);
      const response = await axios.put(signedS3Url.fullUrl, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0;
          onProgress({ percent: percentCompleted, signedS3Url });
          file.url = signedS3Url.fields.Key;
        },
        cancelToken: cancelTokenSource.token
      });
      onSuccess(response.data, file);
    } catch (error) {
      onError(error);
    }
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const onPreview = async (file: any) => {
    const res = await generateS3UrlDownload({ filePath: file.url, fileType: FileTypeEnum.ReservationAttachment });
    if ('data' in res) {
      const signedS3Url = res.data as GetPresignedS3UrlResponse;
      if (!signedS3Url.fullUrl) {
        openNotificationWithIcon('error', t('common.downloadError'), t('reservation.downloadErrorDesc'));
      }
      if (
        signedS3Url.url.toLowerCase().endsWith('.jpg') ||
        signedS3Url.url.toLowerCase().endsWith('.jpeg') ||
        signedS3Url.url.toLowerCase().endsWith('.png')
      ) {
        setImageUrl(signedS3Url.fullUrl);
        setModalVisible(true);
      } else {
        window.open(signedS3Url.fullUrl, '_blank');
      }
    }
  };

  const props: UploadProps = {
    customRequest,
    onRemove(file) {
      const cancelTokenSource = cancelTokenMap.get(file.uid);
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Upload canceled by user');
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        openNotificationWithIcon('success', t('common.success'), t('reservation.uploadSuccess'));
      } else if (info.file.status === 'error') {
        openNotificationWithIcon('error', t('common.error'), t('reservation.uploadError'));
      }
      const updatedItem = {
        ...reservation,
        attachments: info.fileList.map((file) => {
          return {
            uid: file.uid,
            name: file.name,
            url: file.url || '',
            percent: file.percent,
            status: file.status
          };
        })
      };
      onChange(updatedItem);
    },
    onPreview,
    defaultFileList: reservation.attachments
      ? reservation.attachments.map((attachment) => ({
          uid: attachment.uid,
          name: attachment.name,
          url: attachment.url,
          status: attachment.status as UploadFileStatus,
          percent: attachment.percent
        }))
      : []
  };

  useEffect(() => {
    if (!message) {
      return;
    }
    const parsedMessage = JSON.parse(message);
    if (!parsedMessage.message.attachment) {
      return;
    }

    if (parsedMessage.message.attachment.status !== 'done') {
      return;
    }

    const newAttachment: IAttachment = {
      uid: uuid(),
      name: parsedMessage.message.attachment.name,
      status: parsedMessage.message.attachment.status,
      url: parsedMessage.message.attachment.url,
      percent: parsedMessage.message.attachment.percent
    };

    const updatedItem: IReservation = {
      ...reservation,
      attachments: [...(reservation.attachments || []), newAttachment]
    };
    onChange(updatedItem);
    setUploadKey((prevKey) => prevKey + 1);
  }, [message]);

  const getQRCodeValue = async () => {
    setQrCodeStatus('loading');
    if (!reservation.id) {
      openMessageWithIcon('error', t('common.error'), t('reservation.missingReservationId'));
    }
    const result = await getQRCodeUrl({ reservation });
    if ('data' in result) {
      const qrCodeUrlTemp = `${process.env.REACT_APP_REDIRECT_QR_CODE}${QR_CODE_SCANNER_PATH}?qrIdentifier=${result.data.qrIdentifier}&companyId=${companyId}&reservationId=${reservation.id}`;
      const qrIdentifierDB = result.data.qrIdentifier;
      setQrCodeValue(qrCodeUrlTemp);
      setQrIdentifier((prevState) => {
        console.log('Previous State:', prevState);
        return qrIdentifierDB;
      });
      setQrCodeStatus('active');
      console.log('qrCodeUrl:', qrCodeUrlTemp);
      console.log('getQRCodeValue qrIdentifierDB:', qrIdentifierDB);
    }
  };

  useEffect(() => {
    if (qrIdentifier) {
      console.log('useEffect qrIdentifier:', qrIdentifier);
    }
  }, [qrIdentifier]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setQrCodeStatus('expired');
    }, 10000);
    return () => clearInterval(interval);
  }, [qrCodeStatus]);

  return (
    <>
      <ImageModal imageUrl={imageUrl} visible={modalVisible} onClose={handleCloseModal} />
      <Row>
        <Col span={12}>
          <Label>{t('reservation.user')}</Label>
          <Tooltip title={t('reservation.firstName')}>
            <Input
              name="firstName"
              required
              placeholder={t('reservation.firstName')}
              onChange={handleChange}
              defaultValue={reservation.firstName}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.lastName')}>
            <Input
              style={{ marginTop: '5px' }}
              name="lastName"
              placeholder={t('reservation.lastName')}
              onChange={handleChange}
              defaultValue={reservation.lastName}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.address')}>
            <Input
              style={{ marginTop: '5px' }}
              name="address"
              placeholder={t('reservation.address')}
              onChange={handleChange}
              defaultValue={reservation.address}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.city')}>
            <Input
              style={{ marginTop: '5px' }}
              name="city"
              placeholder={t('reservation.city')}
              onChange={handleChange}
              defaultValue={reservation.city}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.documentNumber')}>
            <Input
              name="documentNumber"
              style={{ marginTop: '5px' }}
              placeholder={t('reservation.documentNumber')}
              onChange={handleChange}
              defaultValue={reservation.documentNumber}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.personalNumber')}>
            <Input
              name="personalNumber"
              style={{ marginTop: '5px' }}
              placeholder={t('reservation.personalNumber')}
              onChange={handleChange}
              defaultValue={reservation.personalNumber}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.phoneNumber')}>
            <Input
              name="phoneNumber"
              style={{ marginTop: '5px' }}
              placeholder={t('reservation.phoneNumber')}
              onChange={handleChange}
              defaultValue={reservation.phoneNumber}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.email')}>
            <Input
              name="email"
              style={{ marginTop: '5px' }}
              placeholder={t('reservation.email')}
              onChange={handleChange}
              defaultValue={reservation.email}
              allowClear
            />
          </Tooltip>
        </Col>
        <Divider type="vertical" />
        <Col span={10}>
          <Label>{t('reservation.company')}</Label>
          <Tooltip title={t('reservation.companyName')}>
            <Input
              name="companyName"
              placeholder={t('reservation.companyName')}
              onChange={handleChange}
              defaultValue={reservation.companyName}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.companyAddress')}>
            <Input
              style={{ marginTop: '5px' }}
              name="companyAddress"
              placeholder={t('reservation.companyAddress')}
              onChange={handleChange}
              defaultValue={reservation.companyAddress}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.companyCity')}>
            <Input
              style={{ marginTop: '5px' }}
              name="companyCity"
              placeholder={t('reservation.companyCity')}
              onChange={handleChange}
              defaultValue={reservation.companyCity}
              allowClear
            />
          </Tooltip>
          <Tooltip title={t('reservation.companyVat')}>
            <Input
              style={{ marginTop: '5px' }}
              name="companyVat"
              placeholder={t('reservation.companyVat')}
              onChange={handleChange}
              defaultValue={reservation.companyVat}
              allowClear
            />
          </Tooltip>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={10}>
          {qrCodeValue ? (
            <>
              <QRCode value={qrCodeValue} status={qrCodeStatus} onRefresh={() => getQRCodeValue()} />
              <Button
                type="link"
                onClick={() => {
                  window.open(qrCodeValue, '_blank');
                }}
              >
                {t('reservation.clickToOpenOnThisDevice')}
              </Button>
            </>
          ) : (
            <Tooltip title={t('reservation.scanQRCodeDesc')} placement="right">
              {isLoadingQRCode || isLoadingS3Presign ? (
                <LoadingOutlined />
              ) : (
                <Button type="link" onClick={() => getQRCodeValue()}>
                  {t('reservation.generateQRCode')}
                </Button>
              )}
            </Tooltip>
          )}
        </Col>
        {/* <Col span={12}>
          {qrCodeUrl && (
            <Button
              type="link"
              onClick={() => {
                window.location.href = qrCodeUrl;
              }}
            >
              {t('reservation.clickToOpenOnThisDevice')}
            </Button>
          )}
        </Col> */}
      </Row>
      <Row>
        <Col>
          <Upload key={uploadKey} {...props}>
            <Button icon={<UploadOutlined />}>{t('reservation.uploadAttachment')}</Button>
          </Upload>
        </Col>
      </Row>
    </>
  );
};

export default ReservationUserDetails;
