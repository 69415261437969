import { Avatar } from 'antd';
import styled from 'styled-components';

export const AvailableVehiclesWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  align-items: flex-start;

  .vehicle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const HoverableAvatar = styled(Avatar)`
  transition: transform 0.3s ease-in-out;
  margin: 3px;
  width: 100px;
  &:hover {
    transform: scale(1.2);
  }
`;

export const StyledVehicleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 11px;
`;
