import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContainer, AuthSegment, Footer, FooterItems, LogoContainer } from './Auth.style';

interface IProps {
  children?: React.ReactNode;
}

const AuthWrapper = ({ children }: IProps) => {
  const { t } = useTranslation();
  const handleEmailClick = () => {
    window.location.href = 'mailto:mrakic86@gmail.com';
  };

  return (
    <AuthContainer>
      <LogoContainer>{t('siteName')}</LogoContainer>
      <AuthSegment>{children}</AuthSegment>
      <Footer>
        <div>
          © {new Date().getFullYear()} {t('siteName')}
        </div>
        <FooterItems>
          <div>{t('common.aboutUs')}</div>
          <div onClick={handleEmailClick} style={{ cursor: 'pointer' }}>
            {t('common.contactUs')}
          </div>
        </FooterItems>
      </Footer>
    </AuthContainer>
  );
};

export default AuthWrapper;
