import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Form, Input, Modal, Row, Select, Skeleton, Space, Table, Upload } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CarTypeEnum, IMake, IModel, ISeries, VehicleTypeEnum } from 'Models/Models';
import {
  useDeleteMakeMutation,
  useGetMakeQuery,
  usePostMakeMutation,
  usePutMakeMutation
} from 'services/autobookerApi';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { openNotificationWithIcon } from 'components/Notification/Notification';
import { useAppSelector } from 'hooks';
import { SvgRenderer, getDescription } from '../Dashboard/Reservations/Tools';
import SeriesModels from './components/SeriesModels';

const { Option } = Select;

const MakesTable = () => {
  const { t } = useTranslation();
  const company = useAppSelector((state) => state.auth.company);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalAddModelsVisible, setIsModalAddModelsVisible] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const { data: makeListData, isLoading: makeListLoading } = useGetMakeQuery(`companyId=${company?.id}`);
  const [postMake, { data: makeData, isLoading: makeLoading, error: makeError }] = usePostMakeMutation();
  const [putMake, { data: putMakeData, isLoading: putMakeLoading }] = usePutMakeMutation();
  const [deleteMake, { data: deleteMakeData, isLoading: deleteMakeLoading }] = useDeleteMakeMutation();
  const [form] = Form.useForm();
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedMake, setSelectedMake] = useState<IMake | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [svgContent, setSvgContent] = useState<string | ArrayBuffer | null>(null);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<{ label: string; value: number }[]>([]);
  const [carTypeOptions, setCarTypeOptions] = useState<{ label: string; value: number }[]>([]);

  useEffect(() => {
    const options = Object.keys(VehicleTypeEnum)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => ({
        label: key,
        value: VehicleTypeEnum[key as keyof typeof VehicleTypeEnum] as number
      }));
    const optionsForCarType = Object.keys(CarTypeEnum)
      .filter((key) => Number.isNaN(Number(key)))
      .map((key) => ({
        label: key,
        value: CarTypeEnum[key as keyof typeof CarTypeEnum] as number
      }));
    setVehicleTypeOptions(options);
    setCarTypeOptions(optionsForCarType);
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleEdit = (record: IMake) => {
    setTimeout(() => {
      setSelectedMake(record);

      if (record) {
        setSvgContent(record.logo as string);
        form.setFieldsValue({ logo: record.logo });
      }

      setIsModalVisible(true);
    }, 100);
  };

  const handleAddModels = (record: IMake) => {
    setSelectedMake(record);
    if (!record) {
      return;
    }
    setIsModalAddModelsVisible(true);
  };

  const handleDelete = (record: IMake) => {
    const make = { ...record, companyId: company?.id } as IMake;
    deleteMake(make).then((result) => {
      if ('data' in result) {
        if (result.data) {
          openNotificationWithIcon('success', t('makes.makeUpdated'), t('makes.makeUpdatedDesc'));
        } else {
          openNotificationWithIcon('error', t('makes.makeDeletedError'), t('makes.makeDeletedErrorDesc'));
        }
      }
    });
  };

  useEffect(() => {
    if (!makeListData) {
      return;
    }
    const temp = makeListData.map((make: IMake) => ({
      text: make.name,
      value: make.name
    }));
    setFilterOptions(temp);
  }, [makeListData]);

  useEffect(() => {
    if (!isModalVisible) {
      setSvgContent(null);
      setSelectedMake(undefined);
    }
    form.resetFields();
  }, [isModalVisible]);

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // TODO: Add handle errors
  const onFinish = async (values: IMake) => {
    const make = { ...values, companyId: company?.id } as IMake;
    if (values.id && values.id.length > 0) {
      await putMake(make)
        .unwrap()
        .then((result) => {
          openNotificationWithIcon('success', t('makes.makeUpdated'), t('makes.makeUpdatedDesc'));
          setIsModalVisible(false);
        });
    } else {
      await postMake(make)
        .unwrap()
        .then((result) => {
          openNotificationWithIcon('success', t('makes.makeAdded'), t('makes.makeAddedDesc'));
          setIsModalVisible(false);
        });
    }
  };

  const columns: ColumnsType<IMake> = [
    {
      title: 'Logo',
      dataIndex: 'logo',
      width: 70,
      fixed: 'left',
      render: (value: string) => <SvgRenderer width={50} height={50} svgString={value} />
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Country',
      dataIndex: 'country'
    },
    {
      title: 'Series',
      dataIndex: 'series',
      render: (series: ISeries[]) => {
        return (
          <ul>
            {series?.map((item) => (
              <li key={item.id}>{`${item.name} (${getDescription(item.vehicleType)})`}</li>
            ))}
          </ul>
        );
      }
    },
    {
      title: 'Models',
      width: 160,
      render: (text, record) => (
        <Button type="primary" onClick={() => handleAddModels(record)}>
          {t('makes.models')}
        </Button>
      )
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 160,
      render: (text, record) => (
        <Space size="small">
          <Button type="primary" onClick={() => handleEdit(record)}>
            {t('common.edit')}
          </Button>
          <Button type="default" onClick={() => handleDelete(record)}>
            {t('common.delete')}
          </Button>
        </Space>
      )
    }
  ];

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type !== 'svg+xml';
    if (!isJpgOrPng) {
      openNotificationWithIcon('error', t('makes.invalidImage'), t('makes.invalidImageDesc'));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      openNotificationWithIcon('error', t('makes.imageTooLarge'), t('makes.imageTooLargeDesc'));
    }
    return isJpgOrPng && isLt2M;
  };

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      const file = info.file.originFileObj as File;
      if (file && file.type === 'image/svg+xml') {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            const content = e.target.result;
            if (typeof content === 'string') {
              setLoading(false);
              setSvgContent(content);
              form.setFieldsValue({ logo: content });
            } else {
              console.error('FileReader result is not a string.');
            }
          }
        };
        reader.readAsText(file);
      } else {
        console.error('Uploaded file is not an SVG.');
      }
    }
  };

  const AddSeriesModelsComponent = useMemo(
    () => <SeriesModels make={selectedMake} onClose={() => setIsModalAddModelsVisible(false)} />,
    [selectedMake]
  );

  return (
    <Row style={{ padding: 20 }}>
      <Col span={24}>
        <Card
          style={{ margin: '0 auto' }}
          extra={
            <Button style={{ margin: 10 }} type="primary" icon={<PlusOutlined />} onClick={showModal}>
              {t('makes.addNewMake')}
            </Button>
          }
        >
          <Table
            key="makes-table"
            columns={columns}
            dataSource={makeListData && makeListData.map((item: IMake) => ({ ...item, key: item.id }))}
            loading={{ spinning: makeListLoading, indicator: <LoadingOutlined /> }}
            bordered
          />
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            onFinish={onFinish}
            style={{ maxWidth: 1200 }}
            initialValues={
              selectedMake && selectedMake !== undefined
                ? {
                    ...selectedMake,
                    logo: svgContent as string
                  }
                : {}
            }
          >
            <Modal
              title={t('makes.addNewMake')}
              open={isModalVisible}
              confirmLoading={makeLoading || putMakeLoading}
              onOk={handleOk}
              onCancel={handleCancel}
              width={900}
            >
              <Form.Item label="ID" name="id">
                <Input disabled style={{ width: '60%' }} />
              </Form.Item>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input style={{ width: '60%' }} />
              </Form.Item>
              <Form.Item label="Country" name="country">
                <Input style={{ width: '60%' }} />
              </Form.Item>
              <Form.Item label="Logo" name="logo">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      openNotificationWithIcon('success', t('makes.imageUploaded'), t('makes.imageUploadedDesc'));
                    }, 0);
                  }}
                >
                  {svgContent ? (
                    <img
                      src={`data:image/svg+xml;base64,${btoa(svgContent as string)}`}
                      alt="SVG"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item label={t('makes.series')}>
                <Form.List
                  name="series"
                  rules={[
                    {
                      validator: async (_, series: ISeries[]) => {
                        if (!series || series.length < 1) {
                          return Promise.reject(new Error(t('makes.numberOfModelsError')));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field) => (
                        <Form.Item required={false} key={field.key}>
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: t('makes.seriesNameError')
                              }
                            ]}
                            name={[field.name, 'name']}
                            noStyle
                          >
                            <Input placeholder={t('makes.seriesName')} style={{ width: '20%' }} />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'vehicleType']}
                            rules={[
                              {
                                required: true,
                                message: t('makes.vehicleTypeError')
                              }
                            ]}
                            noStyle
                          >
                            <Select
                              style={{ width: '30%', marginLeft: 10 }}
                              onChange={(value) => {
                                form.setFieldsValue({
                                  [`series[${field.key}].carType`]: value === 0 ? undefined : null
                                });
                              }}
                            >
                              {vehicleTypeOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          {form.getFieldValue(['series', field.name, 'vehicleType']) === 0 && (
                            <Form.Item
                              name={[field.name, 'carType']}
                              rules={[
                                {
                                  required: true,
                                  message: t('makes.carTypeError')
                                }
                              ]}
                              noStyle
                            >
                              <Select style={{ width: '30%', marginLeft: 10 }}>
                                {carTypeOptions.map((option) => (
                                  <Option key={option.value} value={option.value}>
                                    {t(`makes.carTypeOption.${option.label}`)}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                          <CloseOutlined style={{ marginLeft: 10 }} onClick={() => remove(field.name)} />
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} style={{ width: '60%' }} icon={<PlusOutlined />}>
                          {t('makes.addSeries')}
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Modal>
          </Form>
          <Modal
            title={t('makes.seriesModels')}
            open={isModalAddModelsVisible}
            onCancel={() => setIsModalAddModelsVisible(false)}
            afterClose={() => setIsModalAddModelsVisible(false)}
            width={900}
            footer={null}
          >
            {isModalAddModelsVisible && AddSeriesModelsComponent}
          </Modal>
        </Card>
      </Col>
    </Row>
  );
};

export default MakesTable;
