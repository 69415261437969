import React, { useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font, Image } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';
import uuid from 'react-uuid';
import dayjs from 'dayjs';
import { IReservation, ReservationTypeEnum } from 'Models/Models';
import { IDocProps } from './Utils';
import { HandoverReportPages } from './HandoverReport';
import { ReturnReportPages } from './ReturnReport';
import { AuthorizationPages } from './Authorization';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontSize: 8,
    fontFamily: 'Roboto'
  },
  header: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 18,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  article: {
    textAlign: 'center',
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  logo: {
    width: '100%',
    height: '100%'
  },
  footer: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 12
  },
  content: {
    paddingLeft: 90,
    paddingRight: 30,
    paddingBottom: 20,
    paddingTop: 10,
    flexDirection: 'column',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  section: {
    flexDirection: 'column',
    marginBottom: 10
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center'
  },
  text: {
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  boldText: {
    fontWeight: 'bold'
  },
  underlinedText: {
    textDecoration: 'underline'
  },
  signature: {
    marginTop: 20
  },
  signatureBottom: {
    bottom: 50,
    width: 120,
    flexWrap: 'wrap',
    right: 15,
    fontSize: 8,
    position: 'absolute'
  },
  signatureUserBottom: {
    bottom: 50,
    width: 120,
    flexWrap: 'wrap',
    left: 100,
    fontSize: 8,
    position: 'absolute'
  }
});

const getNameOfUse = (reservation: IReservation) => {
  switch (reservation.reservationType) {
    case ReservationTypeEnum.InternalUse:
      return 'rentirano vozilo';
    case ReservationTypeEnum.SubstituteVehicle:
      return 'zamensko vozilo';
    case ReservationTypeEnum.TestDrive:
      return 'test vozilo';
    case ReservationTypeEnum.Rental:
      return 'rentirano vozilo';
    default:
      return '';
  }
};

const logoImage = require('theme/v2/memorandum.png');

const Signature = () => (
  <View style={styles.signatureBottom}>
    <Text style={styles.text}>Delta Motors d.o.o.</Text>
    <Text> </Text>
    <Text> </Text>
    <Text style={styles.text}>____________________</Text>
  </View>
);

const SignatureUser = () => (
  <View style={styles.signatureUserBottom}>
    <Text style={styles.text}>KORISNIK</Text>
    <Text> </Text>
    <Text> </Text>
    <Text style={styles.text}>____________________</Text>
  </View>
);

const ContractOfService = ({ reservation, vehicle, title, make, model, connectedData }: IDocProps) => (
  <Document key={uuid()} title={title}>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={logoImage} />
      </View>
      <View style={styles.content}>
        <View style={styles.section}>
          <Text style={styles.heading}>UGOVOR O KORIŠĆENJU VOZILA</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>Zaključen u Beogradu, dana {dayjs().format('DD.MM.YYYY')}, između:</Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            1. DELTA MOTORS d.o.o. Beograd, Ul. Radnička 8, matični broj 20204192, PIB 104646704, koje zastupa direktor
            Aleksandra Đurđević, račun br. 160-265682-79 Banca Intesa a.d. Beograd , dalje u tekstu DELTA MOTORS, i
          </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            Ime i prezime/Naziv preduzeća:
            {reservation.firstName || reservation.lastName ? (
              <Text style={styles.boldText}>
                {' '}
                {reservation.firstName} {reservation.lastName}
              </Text>
            ) : (
              '______________________'
            )}
          </Text>
          <Text style={styles.text}>
            JMBG/Matični broj:
            {reservation.personalNumber ? (
              <Text style={styles.boldText}> {reservation.personalNumber}</Text>
            ) : (
              '______________________'
            )}
          </Text>
          <Text style={styles.text}>
            Br.l.k.-pasoša/Broj registracije:
            {reservation.documentNumber ? (
              <Text style={styles.boldText}>{` ${reservation.documentNumber} `}</Text>
            ) : (
              ' ____________ '
            )}
          </Text>
          <Text style={styles.text}>
            PIB:
            {reservation.companyVat ? (
              <Text style={styles.boldText}>{` ${reservation.companyVat} `}</Text>
            ) : (
              ' ____________ '
            )}
          </Text>
          <Text style={styles.text}>
            Kontakt telefon:
            {reservation.phoneNumber ? (
              <Text style={styles.boldText}>{` ${reservation.phoneNumber} `}</Text>
            ) : (
              ' ____________ '
            )}
          </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>dalje u tekstu KORISNIK</Text>
          <Text style={styles.text}> </Text>
          <View style={styles.section}>
            <Text style={styles.article}>Član 1.</Text>
            <Text style={styles.boldText}> PODACI O VOZILU</Text>
            <Text style={styles.text}>
              Predmet ovog Ugovora je davanje od strane DELTA MOTORS motornog vozila KORISNIKU, i to vozila sa vozačem:
            </Text>
            <Text style={styles.text}>
              Marka i tip:
              {vehicle.makeId || vehicle.modelId ? (
                <Text style={styles.boldText}>
                  {make && make.name} {model && model.name} {vehicle.name}
                </Text>
              ) : (
                '______________________'
              )}
            </Text>
            <Text style={styles.text}>
              Registarskih oznaka:
              {vehicle.licensePlate ? (
                <Text style={styles.boldText}>{vehicle.licensePlate}</Text>
              ) : (
                '______________________'
              )}
            </Text>
            <Text style={styles.text}>
              Broj šasije: {vehicle.vin ? <Text style={styles.boldText}>{` ${vehicle.vin}`}</Text> : ' ____________ '},
            </Text>
            <Text style={styles.text}>na korišćenje tokom trajanja ovog Ugovora.</Text>
            <Text style={styles.text}>
              Motorno vozilo iz stava 1. ovog člana Ugovora se daje na korišćenje KORISNIKU u viđenom stanju kao:
            </Text>
            <Text style={styles.boldText}>{getNameOfUse(reservation)}</Text>
          </View>
          <View style={styles.text}> </View>
          <View style={styles.section}>
            <Text style={styles.article}>Član 2.</Text>
            <Text style={styles.boldText}> PODACI O VOZAČU</Text>
            <Text style={styles.text}>Tokom trajanja ovog Ugovora vozilom iz člana 1. ovog Ugovora će upravljati:</Text>
            <Text style={styles.text}>
              Ime i prezime:
              {reservation.firstName || reservation.lastName ? (
                <Text style={styles.boldText}>
                  {reservation.firstName} {reservation.lastName}
                </Text>
              ) : (
                '______________________'
              )}
            </Text>
            <Text style={styles.text}>
              Adresa prebivališta:
              {reservation.address ? (
                <Text style={styles.boldText}>{` ${reservation.address}`}</Text>
              ) : (
                '______________________'
              )}
            </Text>
            <Text style={styles.text}>
              Br.lk/pasoša:
              {reservation.documentNumber ? (
                <Text style={styles.boldText}>{` ${reservation.documentNumber}`}</Text>
              ) : (
                ' ____________ '
              )}
            </Text>
            <Text style={styles.text}>
              JMBG:
              {reservation.personalNumber ? (
                <Text style={styles.boldText}>{` ${reservation.personalNumber}`}</Text>
              ) : (
                ' ____________ '
              )}
            </Text>
            <Text style={styles.text}>Datum i mesto rođenja: ____________ godine, u ____________.</Text>
            <Text style={styles.text}>
              Broj vozačke dozvole: __________________ izdata MUP R SRBIJE dana ____________ godine,
            </Text>
            <Text style={styles.text}>Kategorija za upravljanjem motornim vozilom _________ kategorije.</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Predmetnim vozilom je ovlašćeno da upravlja isključivo lice iz stava 1. ovog člana Ugovora i to samo dok
              poseduje uredno izdatu i važeću vozačku dozvolu.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              U slučaju da lice iz stava 1. ovog člana Ugovora i KORISNIK nisu isto lice, KORISNIK je obavezan da
              najkasnije prilikom zaključenja ovog Ugovora izda tom licu uredno ovlašćenje za upravljanje motornim
              vozilom tokom trajanja ovog Ugovora, kao i da preda ugovornoj strani DELTA MOTORS jedan primerak ovog
              ovlašćenja zajedno sa kopijama dokumenata kojima se potvrđuje identitet KORISNIKA i lica koje je ovlašćeno
              za upravljanje motornim vozilom.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Ovlašćenje za upravljanje predmetnim motornim vozilom prestaje danom isteka perioda na koji je ovaj Ugovor
              zaključen, i po isteku tog perioda ima se smatrati da lice iz stava 1. ovog člana Ugovora neovlašćeno
              koristi tuđe motorno vozilo, te DELTA MOTORS ima pravo da iskoristi sve pozitivnim propisima predviđene
              mogućnosti da predmetno motorno vozilo vrati u svoj posed.
            </Text>
            <Text style={styles.text}> </Text>
          </View>
        </View>
      </View>
    </Page>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={logoImage} />
      </View>
      <View style={styles.content}>
        <View style={styles.section}>
          <Text style={styles.article}>Član 3.</Text>
          <Text style={styles.boldText}> TRAJANJE UGOVORA /PREUZIMANJE I VRAĆANJE VOZILA</Text>
          <Text style={styles.text}>Ovaj Ugovor se zaključuje na određeno vreme, i to na sledeći period:</Text>
          <Text style={styles.text}>
            Korišćenje vozila iz člana 1. ovog Ugovora počinje dana:{' '}
            {dayjs(connectedData.startDate).format('DD.MM.YYYY')} godine u{' '}
            {dayjs(connectedData.startDate).format('HH:mm')},
          </Text>
          <Text style={styles.text}>
            Korišćenje vozila iz člana 1. ovog Ugovora završava se dana:{' '}
            {dayjs(connectedData.endDate).format('DD.MM.YYYY')} godine u {dayjs(connectedData.endDate).format('HH:mm')}.
          </Text>
          <Text style={styles.text}>
            Najkraći period za koje se predmetno vozilo može uzeti na korišćenje iznosi 24h (1 dan).
          </Text>
          <Text style={styles.text}>
            KORISNIK je obavezan da preuzme, odnosno vrati predmetno vozilo u skladu sa odredbom iz stava 1. ovog člana
            Ugovora, kojom prilikom sa ovlašćenim predstavnikom DELTA MOTORS potpisuje Zapisnik o primopredaji prilikom
            preuzimanja vozila na korišćenje, odnosno Zapisnik o primopredaji prilikom vraćanja predmetnog vozila.
          </Text>
          <Text style={styles.text}>
            Preuzimanje vozila na korišćenje i vraćanje vozila obavlja se isključivo u servisu DELTA MOTORS, u okviru
            radnog vremena servisa. DELTA MOTORS predaje predmetno vozilo punog rezervoara i KORISNIK je dužan da vrati
            vozilo punog rezervoara.
          </Text>
          <Text style={styles.text}>
            Vremensko prekoračenje ugovorenog trajanja korišćenja predmetnog vozila prilikom povraćaja vozila za više od
            60 minuta obračunava se kao naredni puni dan korišćenja predmetnog vozila.
          </Text>
          <Text style={styles.text}>
            Ukoliko prilikom vraćanja vozila uzetog na korišćenje KORISNIK odbije i/ili ne potpiše Zapisnik o
            primopredaji prilikom vraćanja predmetnog vozila, predstavnik DELTA MOTORS je ovlašćen da samostalno utvrdi
            stanje vozila koje se vraća, unese potrebne podatke i potpiše ovaj Zapisnik, pri čemu KORISNIK potvrđuje da
            tako uneti podaci o stanju vozila prilikom vraćanja (ili preuzimanja na bilo koji drugi način i od bilo kog
            drugog lica) odgovaraju stvarnom stanju stvari, odnosno da KORISNIK nema primedbi na stanje utvrđeno
            Zapisnikom koji je sastavio i potpisao ovlašćeni predstavnik DELTA MOTORS.
          </Text>
          <Text style={styles.text}>
            U slučaju da KORISNIK predhodno nije produžio korišćenje predmetnog vozila, niti je vozilo vratio u
            ugovorenom roku, DELTA MOTORS ima pravo da sam dođe u posed svog vozila i prateće dokumentacije i svih
            pripadaka vozila, a KORISNIK se odriče prava na državinsku zaštitu, uz obavezu da DELTA MOTORS-u naknadi
            celokupnu prouzrokovanu štetu i troškove.
          </Text>
          <Text style={styles.text}> </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.article}>Član 4.</Text>
          <Text style={styles.boldText}> KORIŠĆENJE VOZILA</Text>
          <Text style={styles.text}>
            KORISNIK preuzima vozilo na korišćenje po osnovu ovog Ugovora zbog: {getNameOfUse(reservation)}.
          </Text>
          <Text style={styles.text}>
            Predmetno vozilo KORISNIK može koristiti samo na teritoriji Republike Srbije (bez teritorije AP Kosovo i
            Metohija), isključivo za potrebe navedene u stavu 1. ovog člana Ugovora.
          </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            Korišćenje predmetnog vozila van teritorije Republike Srbije, kako je definisanom prethodnim stavom ovog
            člana, je moguće isključivo uz predhodno dobijenu pismenu saglasnost ugovorne strane DELTA MOTORS, u
            protivnom se ima smatrati da KORISNIK neovlašćeno koristi tuđe motorno vozilo, te DELTA MOTORS ima pravo da
            iskoristi sve pozitivnim propisima predviđene mogućnosti da predmetno motorno vozilo vrati u svoj posed, a
            KORISNIK je odgovoran DELTA MOTORS-u za sve troškove koji nastanu za vraćanje vozila u posed DELTA MOTORS-a
            kao i troškova i prouzrokovane štete s tim u vezi. Radi izbegavanja svake sumnje, smatra se da KORISNIK
            neovlašćeno koristi tuđe vozilo i kada KORISNIK ponovo uđe u Republiku Srbiju sa prostora van Republike
            Srbije uključujući i sa prostora AP Kosova i Metohije.
          </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>KORISNIKU nije dozvoljeno korišćenje predmetnog vozila na sledeće načine:</Text>
          <Text style={styles.text}>
            - Da vozilo uzeto na korišćenje koristi pod uticajem alkohola ili droga ili psihoaktivnih supstanci,
          </Text>
          <Text style={styles.text}>
            - Da koristi vozilo suprotno ugovoru o kasko osiguranju vozila, opštim uslovima osiguravača kasko osiguranja
            vozila, kao i protivno uslovima osiguravača obaveznog osiguranja od autoogovornosti,
          </Text>
          <Text style={styles.text}>
            - Iznajmljivanje ili posuđivanje vozila trećim licima, kao i neovlašćeno davanje u posed predmetnog vozila
            trećim licima,
          </Text>
          <Text style={styles.text}>
            - Korišćenje i odvoženje predmetnog vozila van teritorije Republike Srbije bez predhodne pismene saglasnosti
            DELTA MOTORS,
          </Text>
          <Text style={styles.text}>
            - Prevoz lako zapaljivih, otrovnih ili drugih opasnih materija ili obavljanje prevoza osoba, te korišćenje
            predmetnog vozila za obuku,
          </Text>
          <Text style={styles.text}>- Učestvovanje u trkama, kao i vuča drugih vozila i sl.,</Text>
          <Text style={styles.text}>
            - Korišćenje otvorenog plamena ili pušenje u predmetnom vozilu, za kontrolu je zadužen Šef voznog parka.
          </Text>
          <Text style={styles.text}>
            - Druga upotreba predmetnog vozila protivno odredbama ovog Ugovora, uputstvu proizvođača vozila koje je
            KORISNIKU stavljeno na uvid i koje je pročitao, kao i bilo koje drugo korišćenje protivno nameni vozila.
          </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            Kada predmetno vozilo nije u upotrebi prozori i vrata moraju biti zatvoreni i zaključani.
          </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            Tokom trajanja ovog Ugovora i korišćenja predmetnog vozila, KORISNIK je obavezan da uredno vodi evidenciju o
            korišćenju vozila.
          </Text>
        </View>
      </View>
    </Page>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={logoImage} />
      </View>
      <View style={styles.content}>
        <View style={styles.section}>
          <Text style={styles.article}>Član 5.</Text>
          <Text style={styles.boldText}> NAKNADA</Text>
          <Text style={styles.text}>
            KORISNIK je obavezan da na ime korišćenja predmetnog vozila iz člana 1. ovog Ugovora plati u korist DELTA
            MOTORS dnevnu naknadu u iznosu: 0eura za ugovoreni period korišćenja vozila iz člana 3. ovog Ugovora (nije
            uračunat PDV) do pređenih 100 kilometara po danu, uvećano za iznos: 0,60 EUR (nije uračunat PDV) po svakom
            više pređenom kilometru.Naknada će se obračunati po prodajnom kursu NBS na dan izdavanja računa/fakture.
          </Text>
          <Text style={styles.text}>
            Plaćeni avans za korišćenje predmetnog vozila po profakturi br. _____ iznosi:0,00 dinara.
          </Text>
          <Text style={styles.text}>
            Ugovorena naknada za korišćenje predmetnog vozila se odnosi na korišćenje vozila, njegovo održavanje,
            osiguranje i registraciju, kao i sve pripadajuće obaveze. Troškovi u vezi upotrebe vozila kao što su
            benzin/dizel, ulje, antifriz i druga sredstva koje je tokom korišćenja vozila potrebno dopuniti, kao i
            naknada putarina, parkinga i garaža, nisu uključeni u naknadu i u celosti ih snosi KORISNIK. KORISNIK snosi
            i sve troškove koji nastanu usled neadekvatnog korišćenja vozila, oštećenja na vozilu, nepoštovanja
            saobraćajnih propisa i propisa o parkiranju vozila i sl..
          </Text>
          <Text style={styles.text}>
            Dnevna naknada za korišćenje predmetnog vozila se odnosi na period od 24 časa. KORISNIK je obavezan platiti
            ukupan iznos neizmirene naknade i sve ostale eventualne troškove najkasnije prilikom vraćanja predmetnog
            vozila prema cenama iz važećeg cenovnika DELTA MOTORS na dan plaćanja. KORISNIK je obavezan, na zahtev DELTA
            MOTORS, prilikom zaključenja ovog Ugovora izvršiti avansnu uplatu naknade za korišćenje predmetnog vozila po
            cenama važećim na dan plaćanja.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.article}>Član 6.</Text>
          <Text style={styles.boldText}> POPRAVKE VOZILA</Text>
          <Text style={styles.text}>
            Sve popravke predmetnog vozila vezane za održavanje urednog rada i saobraćajnu sigurnost tokom perioda
            korišćenja vozila od strane KORISNIKA kao i bilo koje druge popravke ili intervencije na vozilu vrše se
            isključivo u servisu DELTA MOTORS.
          </Text>
          <Text style={styles.text}>
            KORISNIK je obavezan da kada se ukaže potreba za popravkom predmetnog vozila bez odlaganja obavesti DELTA
            MOTORS u cilju dobijanja instrukcija za dalje postupanje, te da nakon toga postupi po datim instrukcijama i
            na taj način omogući blagovremenu i optimalnu popravku ili servis predmetnog vozila u servisu DELTA MOTORS.
            Radi otklanjanja svake nejasnoće, KORISNIK je svestan da je zabranjena defektaža i/ili drugi servisni
            pregled i/ili popravka vozila van servisa DELTA MOTORS, te da je u sluačaju povrede ove odredbe ugovora
            KORISNIK dužan da DELTA MOTORS-u nadoknadi celokupnu prouzrokovanu štetu i troškove.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.article}>Član 7.</Text>
          <Text style={styles.boldText}> POSTUPAK U SLUČAJU NEZGODE/ŠTETE</Text>
          <Text style={styles.text}>
            U slučaju saobraćajne nesreće/nezgode, krađe ili neovlašćenog korišćenja predmetnog vozila, nastanka štete
            na predmetnom vozilu usled požara ili elemantarnih nepogoda, naleta divljači i sl., KORISNIK je obavezan da
            odmah o tome telefonom obavesti DELTA MOTORS i policiju, a DELTA MOTORS dodatno obavesti i pisanim putem u
            najkraćem roku, te da preuzme sve što je u njegovoj moći da bi se sprečila dalja oštećenja i osiguralo mesto
            nesreće i predmetno vozilo u cilju adekvatnog utvrđivanja činjeničnog stanja.
          </Text>
          <Text style={styles.text}>
            KORISNIK je obavezan da ugovornoj strani DELTA MOTORS dostavi potpun izveštaj nadležnog upravnog organa o
            svim relevantnim okolnostima sa skicom nesreće/nezgode. Izveštaj o nesreći/nezgodi mora da sadrži naročito
            imena, adrese i ostale lične podatke lica koja su učestvovali u nesreći/nezgodi, podatke o eventualnim
            svedocima, registarske podatke o vozilima koja su učestvovala u nesreć/nezgodi, opis događaja i druge
            podatke potrebne da se konkretni događaj što bliže i tačnije utvrdi..
          </Text>
          <Text style={styles.text}>
            Propuštanje KORISNIKA da obezbedi izveštaj nadležnog organa, podoban za ostvarivanje prava kod osiguravača,
            povlači odgovornost KORISNIKA prema DELTA MOTORS-u za svu prouzrokovanu štetu na vozilu i druge troškove
            koji nastanu.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.article}>Član 8.</Text>
          <Text style={styles.boldText}> OSIGURANJE</Text>
          <Text style={styles.text}>
            Motorno vozilo koje se daje na korišćenje po osnovu ovog Ugovora je kasko osigurano. Učešće KORISNIKA u
            šteti je 10% iznosa ukupne prouzrokovane štete, a minimalno 200 eur, pod uslovom postojanja adekvatnog
            policijskog izveštaja/zapisnika i pod uslovom da nije ispunjen uslov iz narednog stava ovog člana Ugovora.
          </Text>
          <Text style={styles.text}>
            KORISNIK potvrđuje da je upoznat sa zaključenim ugovorom o kasko osiguranju za predmetno vozilo, kao i sa
            pratećim opštim uslovima osiguravača kasko osiguranja, te se obavezuje da DELTA MOTORS-u naknadi celokupan
            iznos štete i troškova u slučaju da su šteta i troškovi prouzrokovani rizicima koji nisu pokriveni kasko
            osiguranjem, odnosno ako su šteta ili troškovi prouzrokovani rizicima za koje je osiguravač kasko osiguranja
            isključio odgovornost, kao i u slučaju ako je usled propuštanja KORISNIKA da obezbedi adekvatan izveštaj
            nadležnog organa osiguravač odbio da isplati naknadu iz kasko osiguranja.
          </Text>
          <Text style={styles.text}>
            Ukoliko osiguravač po osnovu kasko osiguranja i/ili osiguravač po osnovu obaveznog osiguranja od
            autoodgovornosti odbije da isplati naknadu iz osiguranja (u najširem smislu) zbog toga što je KORISNIK ili
            drugo lice kome je KORISNIK omogućio upravljanje vozilom učinio radnju ili propuštanje kojim je prouzrokovan
            gubitak prava iz osiguranja ili nemogućnost ostvarivanja prava iz osiguranja, KORISNIK je dužan da DELTA
            MOTORS-u isplati celokupan iznos prouzrokovane štete i troškova nastalih u vezi sa konketniim oštećenjem ili
            popravkom vozila.
          </Text>
        </View>
      </View>
    </Page>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={logoImage} />
      </View>
      <View style={styles.content}>
        <View style={styles.section}>
          <Text style={styles.text}>
            Ukoliko osiguravač po osnovu kasko osiguranja i/ili osiguravač po osnovu obaveznog osiguranja od
            autoodgovornosti odbije da isplati pun iznos naknade iz osiguranja (delimično obeštećenje), KORISNIK je
            dužan da DELTA MOTORS-u isplati razliku do celokupnog iznosa prouzrokovane štete i troškova nastalih u vezi
            sa konketnim oštećenjem ili popravkom vozila, dok KORISNIK, po svom nahođenju, može ostvarivati regresna
            prava za plaćenu razliku prema osiguravaču ako za to postoji osnov.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.article}>Član 9.</Text>
          <Text style={styles.boldText}> ODGOVORNOST KORISNIKA</Text>
          <Text style={styles.text}>
            KORISNIK je neograničeno odgovoran za svu štetu, troškove, obaveze i potraživanja, kao i sve druge izdatke
            prema DELTA MOTORS i/ili trećim licima, koji nastanu tokom i u vezi sa korišćenjem predmetnog vozila iz ovog
            Ugovora, uključujući krivicu KORISNIKA, krivicu trećih lica, slučaj ili višu silu, te u tom smislu DELTA
            MOTORS nije odgovoran za štetu koja je ili bi mogla nastati KORISNIKU i/ili trećim licima usled korišćenja
            predmetnog vozila. KORISNIK snosi i sve troškove koji nastanu usled neadekvatnog korišćenja predmetnog
            vozila i korišćenja vozila koje ne pokriva kasko osiguranje ili obavezno osiguranje od autoodgovornosti.
            KORISNIK se obavezuje da DELTA MOTORS-u izvrši plaćanje svih iznosa koje DELTA MOTORS plati trećim licima u
            vezi sa korišćenjem vozila od strane KORISNIKA (ne ograničavajući se na dnevne parking karte, plaćene kazne
            po prekršajnim nalozima i presuda i drugo).
          </Text>
          <Text style={styles.text}>
            U slučaju pokretanja sudskih, izvršnih i/ili upravnih postupaka ili isticanja bilo kakvih zahteva,
            privremenih mera i ograničenja ili prigovora trećih lica, organa i/ili vršioca javnih ovlašćenja vezano za
            korišćenje i upotrebu predmetnog vozila od strane KORISNIKA, KORISNIK preuzima svu odgovornost i snosi sve
            troškove i štetu, nadoknade i druge izdatke koji su nastali vođenjem predmetnih postupaka i na osnovu odluka
            donetih u tim postupcima, odnosno da ih nadoknadi ugovornoj strani DELTA MOTORS.
          </Text>
          <Text style={styles.text}>
            KORISNIK je obavezan da predmetno vozilo vrati DELTA MOTORS u istom stanju higijene u kom ga je i preuzeo, u
            protivnom je dužan da nadoknadi sve troškove i štetu nastalu u ili na predmetnom vozilo.
          </Text>
          <Text style={styles.text}>
            Korisnik je odgovoran DELTA MOTORS-u za neprijavljivanje postojanja Zapisnika u slučaju saobraćajne
            nesreće/nezgode i drugih bitnih podataka o saobraćajnoj nesreći/nezgodi,a DELTA MOTORS je ovlašćen da
            KORISNIKU obračuna i naplati svu štetu i troškove koji su nastali u slučaju ovakvog postupanja KORISNIKA.
          </Text>
          <Text style={styles.text}>
            KORISNIK nema pravo da zadrži vozilo, niti pravo retencije (za taj slučaj se smatra da je KORISNIK stekao
            državinu na vozilu protiv volje DELTA MOTORS-a), bez obzira iz kog razloga i po kom osnovu može proizilaziti
            neko njegovo potraživanje prema DELTA MOTORS, kao ni da svoja eventualna potraživanja prema DELTA MOTORS
            prebija potraživanjim DELTA MOTORS po osnovu ovog Ugovora.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.article}>Član 10.</Text>
          <Text style={styles.boldText}> OSTALO</Text>
          <Text style={styles.text}>
            Sve izmene i dopune ovog Ugovora su moguće na osnovu saglasnosti ugovornih strana, zaključenjem od strane
            ovlašćenih lica ugovornih strana pismenog Aneksa ovog Ugovora.
          </Text>
          <Text style={styles.text}>
            Na pitanja koja nisu regulisana ovim Ugovorom primenjivaće se odredbe Zakona o obligacionim odnosima.
          </Text>
          <Text style={styles.text}>
            Sve obaveze KORISNIKA u ovom Ugovoru smatraju se bitnim obavezama, te ugovorne strane saglasno konstatuju da
            DELTA MOTORS može raskinuti ovaj Ugovor u slučaju da KORISNIK prekrši bilo koju od obaveza iz ovog Ugovora,
            uz obavezu da KORISNIKA o tome pisanim putem obavesti, pri čemu je dovoljno da KORISNIKU dostavi pisano
            obaveštenje ili na e-mail adresu ili na adresu iz rubruma ovog Ugovora ili elektronskom porukom na broj
            mobilnog telefona KORISNIKA koji je naveden u ovom Ugovoru.
          </Text>
          <Text style={styles.text}>
            Eventualne sporove ugovorne strane će rešavati sporazumno, a u slučaju da nije moguće sporazumno rešenje
            spora stvarno i mesno je nadležan sud u mestu sedišta ugovorne strane DELTA MOTORS.
          </Text>
          <Text style={styles.text}>
            Zapisnici o preuzimanju i vraćanju vozila su sastavni deo Ugovora o korišćenju vozila.
          </Text>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}>
            Ovaj Ugovor je sačinjen u 3 (tri) istovetna primerka, od kojih DELTA MOTORS zadržava 2 (dva) primerka, a
            KORISNIK 1 (jedan) primerak.
          </Text>
        </View>
      </View>
      <Signature />
      <SignatureUser />
    </Page>

    <HandoverReportPages
      reservation={reservation}
      vehicle={vehicle}
      title={title}
      make={make}
      model={model}
      connectedData={connectedData}
    />
    <ReturnReportPages
      reservation={reservation}
      vehicle={vehicle}
      title={title}
      make={make}
      model={model}
      connectedData={connectedData}
    />
    <AuthorizationPages
      reservation={reservation}
      vehicle={vehicle}
      title={title}
      make={make}
      model={model}
      connectedData={connectedData}
    />
  </Document>
);

export default ContractOfService;
