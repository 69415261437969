import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../store/store';

export type User = {
  id: string;
  email: string;
  given_name: string;
  family_name: string;
  birthdate: string;
  phone_number: string;
  picture: string;
  company_id: string;
};

export interface ICompany {
  id: string;
  displayName: string;
  address: string;
}

type AuthState = {
  user: User | undefined;
  clientId: string | undefined;
  company: ICompany | undefined;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: undefined, clientId: undefined, company: undefined } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, clientId } }: PayloadAction<{ user: User | undefined; clientId: string | undefined }>
    ) => {
      state.user = user;
      state.clientId = clientId;
    },
    setCompany: (state, { payload: { company } }: PayloadAction<{ company: ICompany }>) => {
      state.company = company;
    }
  }
});

export const { setCredentials, setCompany } = authSlice.actions;

export default authSlice.reducer;

export const currentUser = (state: RootState) => state.auth.user;
