import styled from 'styled-components';
import { theme } from '../../../../styles/theme';
import topBg from '../../../../theme/backgrounds/autobooker-background-top.png';

export const TopBarWrapper = styled.div`
  margin: 5px 20px 0 20px;
  position: sticky;
  background-color: ${theme.main.colors.snow};
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  z-index: 20;
  align-items: center;
  border-radius: 8px;
`;

export const MenuWrapper = styled.div`
  flex-grow: 1; /* Take up all available space */
  display: flex;
  justify-content: flex-start; /* Align Menu to the left */
  overflow: visible; /* Ensure all menu items are visible */
`;

export const TopBarContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: ${theme.main.colors.black};
`;

export const TopBarSection = styled.div`
  display: flex;
  align-items: center;
  font-size: ${theme.main.typography.sm};
  color: ${theme.main.colors.graphite.dark};
  cursor: pointer;
  position: relative;
`;

export const TopBarSectionLabel = styled.div`
  margin-right: 10px;
  font-weight: 500;
  font-size: ${theme.main.typography.sm};
  text-align: right;
`;

export const CompanyInfoLabel = styled.div`
  font-size: ${theme.main.typography.xs};
  margin-right: 10px;
  text-align: right;
`;

export const PersonalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
`;

export const Avatar = styled.div`
  border-radius: 100%;
  height: 50px;
  width: 50px;
  background-color: #eee;
  cursor: pointer;
`;

export const Separator = styled.div`
  height: 36px;
  width: 1px;
  background-color: #ddd;
  margin-left: 23px;
  margin-right: 23px;
`;

export const SearchWrapper = styled.div`
  position: relative;
`;

export const Search = styled.input`
  outline: none;
  border: 0;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 384px;
  padding: 10px 45px;
`;

export const IconContainer = styled.img`
  position: absolute;
  top: 7px;
  left: 16px;
`;

export const UserPopup = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  background-color: ${theme.main.colors.snow};
  overflow: hidden;
  width: 200px;
  border-radius: 6px;
  color: ${theme.main.colors.graphite.dark};
  border: 1px solid #ddd;
  user-select: none;
  font-size: ${theme.main.typography.sm};
`;

export const UserPopupItem = styled('div')<{ shouldHaveBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) => (props.shouldHaveBorder ? '1px solid #ddd' : 'none')};
  padding: 15px 18px;
  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
`;

export const LanguagePopup = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  background-color: ${theme.main.colors.snow};
  overflow: hidden;
  width: 200px;
  border-radius: 6px;
  color: ${theme.main.colors.graphite.dark};
  border: 1px solid #ddd;
  user-select: none;
  font-size: ${theme.main.typography.sm};
`;

export const LanguagePopupItem = styled('div')<{ shouldHaveBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) => (props.shouldHaveBorder ? '1px solid #ddd' : 'none')};
  padding: 15px 18px;
  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
`;

export const NotificationsPopup = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  overflow-y: scroll;
  max-height: 600px;
  background-color: ${theme.main.colors.snow};
  width: 400px;
  color: ${theme.main.colors.graphite.dark};
  border: 1px solid #ddd;
  user-select: none;
  font-size: ${theme.main.typography.sm};
  border-radius: 8px;
  animation: fadeIn 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  scrollbar-width: none; /* for Firefox */
  -ms-overflow-style: none; /* for Internet Explorer and Edge */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const NotificationContainer = styled.div`
  position: relative;
  display: inline-block;
`;
