import { Outlet } from 'react-router-dom';
import Layout, { Content, Footer } from 'antd/es/layout/layout';
import TopBar from './TopBar/TopBar';
import { Main } from './Dashboard.style';

const Dashboard = () => {
  return (
    <Layout>
      {/* <Navigation /> */}
      <Layout style={{ flexDirection: 'column', height: '100vh' }}>
        <TopBar />
        <Content style={{ flex: 1 }}>
          <Main>
            <Outlet />
          </Main>
        </Content>
        <Footer style={{ textAlign: 'center' }}>©2024 R-Tech</Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
