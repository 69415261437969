import { Descriptions } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
`;

export const ScrollableContainer = styled.div`
  height: 105px;
  overflow: auto;
  display: flex;
`;

export const CustomDescriptions = styled(Descriptions)`
  display: flex;
  margin-left: 10px;

  .ant-descriptions-item-label {
    order: 1;
    font-size: 11px;
  }

  .ant-descriptions-item-content {
    order: 2;
    font-weight: bold;
    font-size: 16px;
  }
`;
