import styled from 'styled-components';
// import bgImage from '../../../theme/v2/authbg.svg';
import bgImage from '../../../theme/v2/background.jpg';

// background-image: url(${bgImage});
export const AuthContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background-image: url(${bgImage});
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
`;

export const SmallDivider = styled.div`
  margin-top: 5px;
`;

export const Divider = styled.div`
  margin-top: 15px;
`;

export const BigDivider = styled.div`
  margin-top: 30px;
`;

export const DividerRight = styled.div`
  margin-right: 30px;
`;

export const Header = styled('h1')<{ center?: boolean }>`
  color: #545964;
  font-size: 40px;
  font-weight: 700;
  line-height: 45px;
  margin: 0;
  width: 100%;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const HeaderSmall = styled('h4')<{ center?: boolean; hasError?: boolean }>`
  color: ${(props) => (props.hasError ? '#d73833' : '#545964')};
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  width: 100%;
`;

export const AuthSegment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  background-color: #fff;
  box-shadow: -6px 15px 17px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 40px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const AuthInput = styled('input')<{ hasError?: boolean }>`
  outline: none;
  width: 100%;
  padding: 10px 15px;
  border: ${(props) => (props.hasError ? '1px solid #d73833' : '1px solid #ddd')};
  border-radius: 8px;
  font-size: 16px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const AuthInputLabel = styled.div`
  color: #545964;
  margin-bottom: 5px;
  width: 100%;
  text-align: left;
  font-size: 12px;
`;

export const InputIcon = styled.img`
  position: absolute;
  right: 14px;
  top: 9px;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  display: flex;
`;

export const Checkbox = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const CheckboxLabel = styled.span`
  margin-left: 10px;
  color: #494949;
`;

export const Dropdown = styled.div`
  width: 350px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  cursor: pointer;
`;

export const LinkButton = styled.div`
  color: #277bb9;
  cursor: pointer;
`;

export const Button = styled.div`
  width: 200px;
  height: 50px;
  color: #fff;
  background-color: #277bb9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`;

export const LoginOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const Footer = styled.div`
  width: 100%;
  height: 36px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  font-size: 14px;
  color: #000;
`;

export const FooterItems = styled.div`
  display: flex;
  justify-content: space-between;
  width: 147px;
`;

export const ErrorMessage = styled.div`
  color: #d73833;
  font-size: 12px;
  margin-top: 5px;
`;
