import { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Svg, Image, Font } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';
import { useGetMakeQuery } from 'services/autobookerApi';
import { IMake, IModel } from 'Models/Models';
import { IDocProps } from './Utils';
import { SideFooter } from './SideFooter';
// import logoImage from '../../theme/v2/memorandum.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const logoImage = require('theme/v2/memorandum.png');

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontSize: 8,
    fontFamily: 'Roboto'
  },
  header: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 18,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  article: {
    textAlign: 'center',
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  logo: {
    width: '100%',
    height: '100%'
  },
  footer: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 12
  },
  content: {
    paddingLeft: 90,
    paddingRight: 30,
    paddingBottom: 20,
    paddingTop: 10,
    flexDirection: 'column',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  section: {
    flexDirection: 'column',
    marginBottom: 10
  },
  heading: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center'
  },
  text: {
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'row'
  },
  boldText: {
    fontWeight: 'bold'
  },
  underlinedText: {
    textDecoration: 'underline'
  },
  signature: {
    marginTop: 20
  }
});

const AttachmentToTheContract = ({ reservation, vehicle, title, make, model }: IDocProps) => {
  return (
    <Document title={title}>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={logoImage} />
        </View>
        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.boldText}>I UVODNE ODREDBE</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              DELTA MOTORS DOO BEOGRAD (ČUKARICA), sa sedištem na adresi: Radnička 8, 11000 Beograd (u daljem tekstu:
              „Prodavac“ ili „Rukovalac“), kao Prodavac i Rukovalac podataka o ličnosti, postupajući u skladu sa
              odredbama Zakona o zaštiti podataka o ličnosti (`&quot;`Sl. glasnik RS`&quot;`, br. 87/2018) (u daljem
              tekstu: „Zakon“), dostavlja Vama, kao korisniku motornog vozila, ovo Obaveštenje o prikupljanju, obradi i
              prenosu ličnih podataka kako je definisano u nastavku.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Podatak o ličnosti je svaki podatak koji se odnosi na fizičko lice čiji je identitet određen ili odrediv,
              neposredno ili posredno, posebno na osnovu oznake identiteta, kao što je ime i identifikacioni broj,
              podataka o lokaciji, identifikatora u elektronskim komunikacionim mrežama ili jednog, odnosno više
              obeležja njegovog fizičkog, fiziološkog, genetskog, mentalnog, ekonomskog, kulturnog i društvenog
              identiteta. Primera radi, podatak o ličnosti može biti ime i prezime, JMBG, adresa, broj telefona, ali i
              svi drugi podaci koji se odnose na Vas kao fizičko lice.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Obrada podataka o ličnosti je svaka radnja ili skup radnji koje se vrše automatizovano ili
              neautomatizovano sa podacima o ličnosti ili njihovim skupovima, kao što su prikupljanje, beleženje,
              razvrstavanje, grupisanje, odnosno strukturisanje, pohranjivanje, upodobljavanje ili menjanje, otkrivanje,
              uvid, upotreba, otkrivanje prenosom, odnosno dostavljanjem, umnožavanje, širenje ili na drugi način
              činjenje dostupnim, upoređivanje, ograničavanje, brisanje ili uništavanje.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Podaci o ličnosti se shodno odredbama Zakona mogu obrađivati u sledećim slučajevima: 1) ako postoji
              pristanak konkretnog lica čiji se podaci obrađuju, 2) obrada je neophodna za izvršenje ugovora zaključenog
              između tog lica i rukovaoca, 3) obrada je neophodna u cilju poštovanja pravnih obaveza rukovaoca, 4) u
              cilju zaštite životno važnih interesa lica na koje se podaci odnose ili drugog fizičkog lica itd.
            </Text>
            <Text style={styles.text}>
              Imajući u vidu sve napred navedeno, u nastavku možete pronaći Obaveštenje koje se odnosi na obradu
              podataka o ličnosti koji se obrađuju jer je obrada neophodna za izvršenje ugovora zaključenog između Vas,
              kao korisnika motornog vozila i Prodavca i/ili je obrada neophodna kako bi Prodavac postupio u skladu sa
              svojim zakonskim obavezama (u skladu sa Zakonom o računovodstvu i Zakonom o porezu na dodatu vrednost)
              i/ili radi zaštite legitimnih interesa.
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.boldText}>
              II PRIKUPLJANJE I OBRADA PODATAKA O KORISNIKU MOTORNOG VOZILA RADI IZVRŠENJA UGOVORA O KORIŠĆENJU MOTORNOG
              VOZILA (TEST VOŽNJA/ZAMENSKO VOZILO)
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              U cilju zaključenja i izvršenja ugovora o korišćenju motornog vozila (test vožnja/zamensko vozilo) koji je
              zaključen između Prodavca i Vas kao korisnika motornog vozila, Prodavac je u obavezi da od Vas prikupi
              sledeće lične podatke: ime i prezime, adresa prebivališta, JMBG, broj lične karte/broj pasoša, datum i
              mesto rođenja, broj mobilnog telefona i broj vozačke dozvole.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Svrha prikupljanja gore navedenih ličnih podataka jeste zaključenje i izvršenje ugovora o korišćenju
              motornog vozila.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              U slučaju da Prodavcu ne pružite navedene lične podatke, ugovor o korišćenju motornog vozila neće moći da
              se zaključi, odnosno izvrši.
            </Text>
            <Text style={styles.text}>Prodavac spomenute lične podataka obrađuje i čuva trajno u svojoj bazi.</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.boldText}>
              III PRIKUPLJANJE I OBRADA PODATAKA O KORISNIKU MOTORNOG VOZILA U CILJU POŠTOVANJA PRAVNIH OBAVEZA
              RUKOVAOCA
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Shodno odredbama Zakona o porezu na dodatu vrednost (&quot;Sl. glasnik RS&quot;, br. 84/2004, 86/2004 -
              ispr., 61/2005, 61/2007, 93/2012, 108/2013, 6/2014 - usklađeni din. izn., 68/2014 - dr. zakon, 142/2014,
              5/2015 - usklađeni din. izn., 83/2015, 5/2016 - usklađeni din. izn., 108/2016, 7/2017 - usklađeni din.
              izn., 113/2017, 13/2018 - usklađeni din.), Prodavac obrađuje podatke o ličnosti korisnika motornog vozila
              kako bi postupao u skladu sa pravnim obavezama koje proizilaze iz Zakona.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              izn., 30/2018, 4/2019 - usklađeni din. izn., 72/2019 i 8/2020 - usklađeni din. izn.), Prodavac je u
              obavezi da Vama kao korisniku motornog vozila izda račun, te od Vas kao korisnika motornog vozila
              prikuplja sledeće lične podatke: ime i prezime, adresu prebivališta (ulicu i broj, broj pošte i mesto),
              JMBG, broj šasije motornog vozila. Svrha prikupljanja gore navedenih ličnih podataka jeste ispunjenje
              pravnih obaveze Rukovaoca, odnosno izdavanje računa u skladu sa zakonskim odredbama. Prodavac spomenute
              lične podatke obrađuje i čuva deset godina od dana izdavanja računa u svojoj bazi.
            </Text>
          </View>
        </View>
        <SideFooter />
      </Page>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={logoImage} />
        </View>
        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.boldText}>
              IV PRIKUPLJANJE I OBRADA PODATAKA O KORISNIKU MOTORNOG VOZILA RADI ZAŠTITE LEGITIMNIH INTERESA RUKOVAOCA
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              U cilju unapređenja naših usluga i proizvoda i jačanja poverenja između nas, kao Prodavca i Vas, kao
              Korisnika naših usluga, Prodavac je u obavezi da nakon pružene usluge (test vožnja/zamensko vozilo), a u
              roku od mesec dana od izvršene usluge, Korisniku pošalje anketu radi ispitivanja Korisnika da li je
              zadovoljan pruženom uslugom. U tu svrhu, Prodavac od Vas prikuplja sledeće lične podatke: ime i prezime,
              email adresa, broj mobilnog telefona.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Svrha prikupljanja gore navedenih ličnih podataka jeste utvrđivanje da li ste Vi zadovoljni pruženom
              uslugom odnosno motornim vozilom koje ste koristili, a sve kako bismo mogli da unapredimo naše proizvode i
              poboljšamo našu uslugu.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>Prodavac spomenute lične podatke obrađuje i čuva trajno.</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.boldText}>V OSTALA PITANJA</Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Lični podaci iz odeljka II, III i IV mogu biti otkriveni određenim primaocima, odnosno trećim licima, i
              to: povezanim licima Prodavca na teritoriji Republike Srbije, zaposlenima kod Prodavca, proizvođaču vozila
              i/ili delova koji se nalaze u prodajnom asortimanu Prodavca, marketinškim kompanijama, IT serviserima,
              servisima za komunikaciju ili kompanijama za istraživanja tržišta ili zadovoljstava klijenata, odnosno
              osiguravajućim i lizing kućama, bankarskim institucijama koje angažuje/sa kojima sarađuje Prodavac na Vaš
              zahtev, kompanijama koje pružaju uslugu: šlepa motornih vozila/pomoć na putu/registracije motornih
              vozila/sprovode postupak ocarinjenja motornog vozila, spoljnim revizorima.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Lični podaci iz odeljka II,III i IV mogu biti preneti u drugu državu ili međunarodnu organizaciju, bez
              prethodnog odobrenja, ako je utvrđeno da ta druga država/međunarodna organizacija obezbeđuje primereni
              nivo zaštite podataka o ličnosti. S tim u vezi, Odlukom o listi država, delova njihovih teritorija ili
              jednog ili više sektora određenih delatnosti u tim državama i međunarodnih organizacija u kojima se smatra
              da je obezbeđen primereni nivo zaštite podataka o ličnosti (&quot;Sl. glasnik RS&quot;, br. 55/2019) (u
              daljem tekstu: „Odluka“) utvrđeno je gde se smatra da je obezbeđen primereni nivo zaštite podataka o
              ličnosti.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Privredna društva kojima Prodavac vrši prenos ličnih podataka nalaze se sa sedištem na teritoriji Savezne
              Republike Nemačke, Republike Grčke, Velike Britanije, Irske, Mađarske, Švajcarske Konfederacije, Republike
              Francuske, Republike Slovenije i Sjedinjenih Američkih Država (ograničeno na &quot;Privacy Shield
              framework&quot;), za koje je u skladu sa Odlukom utvrđeno da obezbeđuju primereni nivo zaštite podataka o
              ličnosti.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Prodavac će lične podatke Vas kao korisnika motornog vozila iz odeljka II i odeljka IV čuvati trajno u
              svojoj bazi, dok će lične podatke iz odeljka III čuvati u roku od deset godina od izdavanja računa.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Vi imate pravo da od Prodavca zahtevate: 1) pristup ličnim podacima, 2) ispravku ličnih podataka, 3)
              brisanje ličnih podataka. Pored toga, možete ostvariti sledeća prava: 1) pravo na ograničenje obrade
              podataka, 2) pravo na prigovor na obradu, 3) pravo na prenosivost podataka, i 4) pravo na podnošenje
              pritužbe Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.
            </Text>
            <Text style={styles.text}> </Text>
            <Text style={styles.text}>
              Svoja prava možete ostvariti slanjem zahteva na email: zastitapodataka@bmw.rs
            </Text>
          </View>
        </View>
        <SideFooter />
      </Page>
    </Document>
  );
};

export default AttachmentToTheContract;
