import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { Label } from '@aws-amplify/ui-react';
import { DocumentTypeEnum, IReservation, IVehicle } from 'Models/Models';
import { Button, Card, Divider, Input, Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/theme';
import { PrinterOutlined, RedoOutlined } from '@ant-design/icons';
import { pdfjs } from 'react-pdf';
import DynamicPdfViewer from './Document/DynamicPdfViewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  reservation: IReservation;
  vehicle: IVehicle;
  date: Date;
  onChange(item: IReservation): void;
}

const Documentation = ({ reservation, vehicle, date, onChange }: IProps) => {
  const { t } = useTranslation();
  const [documentType, setDocumentType] = useState<DocumentTypeEnum | undefined>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const updatedItem = { ...reservation, [name]: value };
    onChange(updatedItem);
  };

  const [showPdf, setShowPdf] = useState(false);
  const [modalKey, setModalKey] = useState(uuid());

  const handleModalOpen = (type: DocumentTypeEnum) => {
    if (!type) {
      return;
    }
    setDocumentType(type);
    setShowPdf(!showPdf);
  };

  useEffect(() => {
    setModalKey(uuid());
  }, [showPdf]);

  return (
    <>
      {/* {showPdf && documentType && (
        <DynamicPdfViewer
          newKey={newKey}
          reRender={showPdf}
          reservation={reservation}
          date={date}
          documentType={documentType}
        />
      )} */}
      <Space direction="horizontal">
        <Label htmlFor="contractNumber" title={t('documentation.contractNumber')}>
          {t('documentation.contractNumber')}
        </Label>
        <Input
          name="contractNumber"
          placeholder={t('documentation.contractNumber')}
          defaultValue={reservation.contractNumber}
          onChange={handleChange}
          allowClear
        />
        <Button icon={<RedoOutlined />}>{t('documentation.generateContractNumber')}</Button>
      </Space>
      <Divider />
      <Card
        title={t('documentation.consentForTestDrive')}
        hoverable
        style={{ background: theme.main.colors.lightOrange, margin: 10 }}
        extra={<PrinterOutlined style={{ fontSize: 24 }} />}
        onClick={() => handleModalOpen(DocumentTypeEnum.CONSENT_FOR_TEST_DRIVE)}
      >
        <p>{t('documentation.consentForTestDriveDesc')}</p>
      </Card>
      <Card
        title={t('documentation.termsOfService')}
        hoverable
        style={{ background: theme.main.colors.lightBlue, margin: 10 }}
        extra={<PrinterOutlined style={{ fontSize: 24 }} />}
        onClick={() => handleModalOpen(DocumentTypeEnum.VEHICLE_USE_AGREEMENT)}
      >
        <p>{t('documentation.termsOfServiceDesc')}</p>
      </Card>
      <Card
        title={t('documentation.handoverProtocolTakeover')}
        hoverable
        style={{ background: theme.main.colors.lightGreen, margin: 10 }}
        extra={<PrinterOutlined style={{ fontSize: 24 }} />}
        onClick={() => handleModalOpen(DocumentTypeEnum.HANDOVER_PROTOCOL_TAKEOVER)}
      >
        <p>{t('documentation.handoverProtocolTakeoverDesc')}</p>
      </Card>
      <Card
        title={t('documentation.handoverProtocolReturn')}
        hoverable
        style={{ background: theme.main.colors.lightGreen, margin: 10 }}
        extra={<PrinterOutlined style={{ fontSize: 24 }} />}
        onClick={() => handleModalOpen(DocumentTypeEnum.HANDOVER_PROTOCOL_RETURN)}
      >
        <p>{t('documentation.handoverProtocolReturnDesc')}</p>
      </Card>
      <Card
        title={t('documentation.authorization')}
        hoverable
        style={{ background: theme.main.colors.lightGreen, margin: 10 }}
        extra={<PrinterOutlined style={{ fontSize: 24 }} />}
        onClick={() => handleModalOpen(DocumentTypeEnum.AUTHORIZATION)}
      >
        <p>{t('documentation.authorizationDesc')}</p>
      </Card>
      <Card
        title={t('documentation.attachmentToTheContract')}
        hoverable
        style={{ background: theme.main.colors.lightPink, margin: 10 }}
        extra={<PrinterOutlined style={{ fontSize: 24 }} />}
        onClick={() => handleModalOpen(DocumentTypeEnum.ATTACHMENT_TO_THE_CONTRACT)}
      >
        <p>{t('documentation.attachmentToTheContractDesc')}</p>
      </Card>
      <Modal
        key={modalKey}
        open={showPdf}
        width={1000}
        onOk={() => setShowPdf(!showPdf)}
        onCancel={() => setShowPdf(!showPdf)}
        footer={null}
      >
        {showPdf && documentType && (
          <div key={uuid()}>
            <DynamicPdfViewer
              reRender={showPdf}
              key={showPdf ? `pdf-viewer-visible-${documentType}` : `pdf-viewer-hidden-${documentType}`}
              reservation={reservation}
              vehicle={vehicle}
              date={date}
              documentType={documentType}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default Documentation;
